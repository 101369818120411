<template>
  <WsUpdate
    label="通用設定"
    :fields="_fields"
    :primary="_primary"
    getUrl="/system_setting/general"
    :completeRedirect="false"
    :postEncode="false"
  >
  </WsUpdate>
</template>

<script>
export default {
  computed: {
    _fields() {
      return {
        web_url: {
          type: "link",
          label: "前台網站網址",
        },
        ...this.$o_o.$h.module.extendModuleHelperAction(
          "system_setting",
          "system_setting.js",
          "getSystemSettingGenrealFields",
          {}
        ),
      };
    },
    _primary() {
      return [
        {
          type: "stateCard",
          floors: [
            {
              fields: [
                "web_url",
                ...this.$o_o.$h.module.extendModuleHelperAction(
                  "system_setting",
                  "system_setting.js",
                  "getSystemSettingGenrealShowFields",
                  {}
                ),
              ],
            },
          ],
        },
      ];
    },
  },
};
</script>

<style>
</style>