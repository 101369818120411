<template>
  <div>
    <WsAppLoading />
    <div class="layout-print">
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>