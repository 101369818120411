export default {
  wsx_center: {
    icon: 'icon-ws-outline-help-outline',
    title: 'WSX 管理中心',
    menu: [
      {
        title: 'WSX 運作項目',
        link: '/wsx_horcrux'
      },
      {
        title: 'WSX Error Log',
        link: '/wsx_error_log'
      },
    ],
    scopes: ['wsx_center-admin'],
  },
}