import config from '@/__config'

const _menu = {
  file_system: {
    icon: 'icon-ll-nav-filemanage-open',
    title: '系統檔案管理',
    scopes: ['file_system-admin'],
    menu: [
      {
        title: '檔案',
        link: '/file',
      },
      {
        title: '圖片',
        link: '/image',
      },
      {
        title: '影片',
        link: '/video',
      },
      {
        title: '音樂',
        link: '/audio',
      },
    ],
  },
}

if (config.wsmodule.file_system.drive) {
  _menu.drive = {
    icon: 'icon-md-cloud',
    title: 'Drive',
    link: '/drive',
    scopes: ['file_system-admin'],
  }
}

export default _menu