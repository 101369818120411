<template>
  <div>
    <WsLoading v-if="showLoading && loading"/>
    <slot v-else :modeldata="modeldata" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      modeldata: null,
      loading: false,
    };
  },
  methods: {
    async $_fetchModel() {
      this.loading = true
      const res = await this.$axios.get(this._fetchUrl);
      this.modeldata = res.data.data;
      this.$emit("fetched", res.data.data);
      this.loading = false
    },
    updateModelData(modeldata) {
      this.modeldata = {
        ...this.modeldata,
        ...modeldata,
      };
      this.$emit("change", this.modeldata);
    },
  },
  mounted() {
    this.$_fetchModel();
  },
  computed: {
    _fetchUrl() {
      if (this.fetchUrl) {
        return this.fetchUrl;
      } else {
        return `/${this.modelName}/${this._id}`;
      }
    },
    _id() {
      if (this.id) {
        return this.id;
      } else {
        return this.$route.params.id;
      }
    },
  },
  watch: {
    id() {
      this.$_fetchModel();
    },
  },
  props: {
    modelName: {},
    fetchUrl: {},
    id: {},
    showLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>