export default {
  xc_employee: {
    icon: 'icon-md-people',
    title: '人力資源',
    menu: [
      {
        title: '人員',
        link: '/xc_employee',
        scopes: [
          "xc_employee-admin",
          "xc_employee_under-admin",
        ],
      },
      {
        title: '職位管理',
        link: '/xc_employee_position',
        scopes: ['xc_employee-admin'],
      },
      {
        title: '工資管理',
        link: '/xc_employee_salary',
        scopes: ['xc_employee-admin'],
      },
      {
        title: '聘用作業',
        link: '/xc_employee_hire',
        scopes: [
          "xc_employee-admin",
          "xc_employee_hire_under-admin",
        ],
      },
      {
        title: '部門',
        link: '/xc_employee_department',
        scopes: ['xc_employee-admin'],
      },
      {
        title: '雇用別',
        link: '/xc_employee_type',
        scopes: ['xc_employee-admin'],
      },
      {
        title: '工作時間設定',
        link: '/xc_employee_working_schedule_setting',
        scopes: ['xc_employee-admin'],
      },
      {
        title: '工資異動紀錄',
        link: '/xc_employee_salary_update_record',
        scopes: ['xc_employee-admin'],
      },
      {
        title: '健保投保查詢',
        link: '/xc_employee_insurance',
        scopes: ['xc_employee-admin', 'tw_health_insurance-admin'],
      },
    ],
  },
  my_xc_employee: {
    icon: 'icon-md-people',
    title: '我的 人員',
    link: '/xc_employee'
  },
  my_salary_slip: {
    icon: 'icon-md-attach-money',
    title: '我的 薪資條',
    link: '/mysalary_slip'
  },
  // xc_employee_hire_my: {
  //   icon: 'icon-md-people',
  //   title: '我的 聘用作業',
  //   link: '/xc_employee_hire'
  // },
  xc_employee_my: {
    icon: 'icon-md-people',
    title: '我的 人事資料',
    link: '/myemployee'
  },
  xc_employee_salary: {
    icon: 'icon-md-attach-money',
    title: '財務相關',
    menu: [
      {
        title: '職位別+薪資對照',
        link: '/xc_employee_position_type'
      },
      {
        title: '工資異動紀錄',
        link: '/xc_employee_salary_update_record'
      },
      {
        title: '調薪作業',
        link: '/xc_employee_salary_adjustjob'
      },
      // {
      //   title: '調薪作業覆核',
      //   link: '/xc_employee_salary_adjustjob_my_review'
      // },
      {
        title: '薪資計算作業',
        link: '/xc_employee_salary_payjob'
      },
      {
        title: '查詢薪資單',
        link: '/xc_employee_salary_slip'
      },
      {
        title: '薪資計算作業覆核',
        link: '/xc_employee_salary_payjob_my_review'
      },
      {
        title: '薪轉帳戶',
        link: '/xc_employee_salary_transfer_account'
      },
      {
        title: '績效獎金',
        link: '/xc_employee_performance_bonus',
        scopes: ['xc_employee_performance_bonus-admin'],
      },
      {
        title: '計畫人事成本表',
        link: '/xc_project_personnel_cost_search'
      },
    ],
    scopes: ['xc_employee-admin'],
  },
  // my_xc_employee_new: {
  //   icon: 'icon-md-people',
  //   title: '我的新聘作業',
  //   link: '/xc_employee_hire_new_my_manage'
  // },
  // my_xc_employee_hire_renew: {
  //   icon: 'icon-md-people',
  //   title: '我的聘用作業',
  //   link: '/xc_employee_hire_change_my_manage',
  //   scopes: ['xc_employee-admin'],
  // }
}