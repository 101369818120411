
<template>
  <WsMain>
    <WsBreadcrumbs :menu="_breadcrumbsItems"></WsBreadcrumbs>
    <slot name="beforeContent"></slot>
    <WsFlex
      class="ws-content-section pb-80"
      flexDirection="column"
    >
      <WsTabs
        v-if="_tabsDisplay"
        v-model="tabValue"
        :items="tabs"
        class="mb-10"
        scrollToAnchor
        autoInit
      />
      <ValidationObserver
        ref="form"
        class="ws-content-section-observer"
      >
        <form
          ref="createForm"
          @submit.prevent
        >
          <WsSectionPrimarySecondary>
            <template v-slot:primary>
              <WsStateContentBlock
                v-for="(item, itemKey) in primary"
                :key="itemKey"
                :type="item.type"
                :label="item.label"
                :title="item.title"
                :remark="item.remark"
                :floors="item.floors"
                :modedFields="_updateFields"
                :fields="item.fields"
                :field="item.field"
                :modelName="item.modelName"
                :modeVersions="item.modeVersions"
                :modeParent="item.modeParent"
                :customButtons="item.customButtons"
                :fieldsRead="item.fieldsRead"
                :errorMessages="errorMessages"
                :value="_value"
                :params="params"
                @input="$_onStateContentBlockInput($event)"
                @form-input="$_formInput"
                :parentModelName="$_getParentModelName(item)"
                @error-popup-active="$_onErrorPopupActive($event)"
              ></WsStateContentBlock>
            </template>
            <template v-slot:secondary>
              <WsStateContentBlock
                v-for="(item, itemKey) in _secondary"
                :key="itemKey"
                :unifyCol="12"
                :type="item.type"
                :label="item.label"
                :title="item.title"
                :remark="item.remark"
                :floors="item.floors"
                :modedFields="_updateFields"
                :fields="item.fields"
                :field="item.field"
                :modelName="item.modelName"
                :modeVersions="item.modeVersions"
                :modeParent="item.modeParent"
                :customButtons="item.customButtons"
                :fieldsRead="item.fieldsRead"
                :errorMessages="errorMessages"
                :value="_value"
                :params="params"
                @input="$_onStateContentBlockInput($event)"
                :parentModelName="$_getParentModelName(item)"
                @error-popup-active="$_onErrorPopupActive($event)"
              ></WsStateContentBlock>
            </template>
          </WsSectionPrimarySecondary>
        </form>
      </ValidationObserver>
    </WsFlex>
    <slot></slot>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          v-for="(btn, btnKey) in customButtomBtns"
          class="mr-8"
          :key="btnKey"
          v-bind="btn"
          @click="$_onCustomBtnClick(btn)"
        >{{ btn.text }}</WsBtn>
        <WsBtn
          @click="$router.go(-1)"
          outlined
        >{{ $t('cancel') }}</WsBtn>
        <WsBtn
          class="ml-8"
          :loading="loading.submit"
          @click="$_onSubmit()"
        >{{ $t('submit') }}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
import S_APP_General from "@/__stone/service/app/general";
export default {
  data: () => ({
    isMounted: false,
    parentModelData: null,
    state: {},
    loading: {
      submit: false,
    },
    errorMessages: {},
    tabValue: null,
  }),
  methods: {
    $_onCustomBtnClick(btn) {
      if (btn.onClick) {
        btn.onClick(this);
      }
    },
    $_onStateContentBlockInput($event) {
      this.$store.dispatch("app/startNeedSave");
      // if (this.emitInput) {
      //   const _value = {
      //     ...this.value,
      //     ...$event,
      //   };
      // } else {
      const _value = {
        ...this.state,
        ...$event,
      };
      this.state = _value;
      this.$emit("input", _value);
      // }
    },
    loadingStart() {
      this.loading.submit = true;
    },
    loadingStop() {
      this.loading.submit = false;
    },
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      if (this.emitSubmit) {
        if (this.emitInput) {
          this.$emit("submit", this.value);
        } else {
          this.$emit("submit", this.state);
        }
        return;
      }
      this.loadingStart();
      try {
        let _value;
        if (this.emitInput) {
          _value = this.value;
        } else {
          _value = this.state;
        }
        let postUrl;
        if (this.postUrl) {
          postUrl = this.postUrl;
        } else if (this.parentModelName && this.parentId) {
          postUrl = `/${this.parentModelName}/${this.parentId}/${this.modelName}`;
        } else {
          postUrl = `/${this.modelName}`;
        }
        let postData = this.$o_o.$h.model.getFormatedStates(
          this.fields,
          _value,
          this.postEncode
        );
        if (this.extendPostData) {
          postData = {
            ...postData,
            ...this.extendPostData,
          };
        }
        if (this.beforeSubmitAction) {
          const isPassed = await this.beforeSubmitAction(this, postData)
          if (!isPassed) {
            this.loadingStop()
            return
          }
        }
        if (this.sumbitFormData) {
          const _formData = new FormData();
          for (const key in postData) {
            _formData.append(key, postData[key]);
          }
          postData = _formData;
        }
        const res = await this.$axios.post(postUrl, postData);
        for (const key in this.fields) {
          if (this.fields[key].sortApiName) {
            await this.patchSortData(key, _value, res.data.data?.id);
          }
        }
        if (this.afterSubmitAction) {
          this.afterSubmitAction(res.data.data);
        }
        this.$emit("afterSubmit", res.data.data);
        this.$store.dispatch("app/stopNeedSave");
        this.$_onComplete(res);
      } catch (error) {
        this.$emit("error", error);
        alert(this.$t(this.$o_o.$h.http.getErrorMessageFromRes(error)));
        this.loadingStop();
      }
    },
    $_onComplete(res) {
      if (this.emitComplete) {
        this.$emit("complete", res);
      } else if (this.getCompleteRedirectUrl) {
        this.$router.push(this.getCompleteRedirectUrl(res));
      } else if (this.completeRedirectUrl) {
        this.$router.push(this.completeRedirectUrl);
      } else if (this.$route.query && this.$route.query.redirect) {
        this.$router.push(this.$route.query.redirect);
      } else if (this.returnUrl) {
        this.$router.push(this.returnUrl);
      } else {
        this.$router.push(`/${this._urlModelName}`);
      }
    },
    async patchSortData(key, value, id) {
      try {
        const _apiName = this.fields[key].sortApiName
          ? this.fields[key].sortApiName
          : key;
        let orderPostData = {};
        orderPostData.order = value[key].map((item) => {
          return { id: item.id };
        });
        const _orderPatchUrl = `/${this.modelName}/${id}/${_apiName}/order`;
        await this.$axios.patch(_orderPatchUrl, orderPostData);
      } catch (error) {
        console.error(error);
      }
    },
    $_getParentModelName(primaryItem) {
      if (primaryItem.modeVersions) {
        return `${this.modelName}_version`;
      } else {
        return this.modelName;
      }
    },
    async $_parentFetchData() {
      this.$store.dispatch("app/startPageLoading");
      let getUrl = `/${this.parentModelName}/${this.parentId}`;
      try {
        const res = await this.$axios.get(getUrl);
        this.parentModelData = res.data.data;
      } catch (error) {
        alert(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onErrorPopupActive($event) {
      if ($event === false) {
        this.errorMessages = "";
      }
    },
    $_formInput($event) {
      if (this.emitFormInput) {
        this.$emit("form-input", $event);
      } else {
        this.state = {
          ...this.state,
          ...$event,
        };
      }
    },
  },
  computed: {
    _secondary() {
      const _secondary = this.secondary ? [...this.secondary] : [];
      if (
        this.$o_o.$h.auth.hasScope(["site_region_cross-admin"]) &&
        this.fields.site_region
      ) {
        if (!_secondary.length || _secondary[0].type != "stateCard") {
          _secondary.unshift({
            type: "stateCard",
            floors: [],
          });
          if (!_secondary[0].floors.length) {
            _secondary[0].floors.push({
              fields: [],
            });
          }
          if (!_secondary[0].floors[0].fields) {
            _secondary[0].floors[0].fields = [];
          }
        }
        _secondary[0].floors[0].fields.push("site_region");
      }
      return _secondary;
    },
    _value() {
      if (this.emitInput) {
        return this.value;
      } else {
        return this.state;
      }
    },
    _parentTitle() {
      if (!this.parentModelName) {
        return null;
      } else if (!this.parentModelData) {
        return this.parentLabel;
      } else {
        return S_APP_General.getValueByFieldKey(
          this.parentTitleKey,
          this.parentModelData
        );
      }
    },
    _breadcrumbsItems() {
      const _breadcrumbsItems = [];
      if (this.breadcrumbsItems) {
        return this.breadcrumbsItems;
      }
      _breadcrumbsItems.push({
        text: this.$t(this.label),
        to: `/${this._urlModelName}`,
        disabled: false,
      });
      _breadcrumbsItems.push({
        text: `${this.$t("new")} ${this.$t(this.label)}`,
        disabled: true,
      });
      return _breadcrumbsItems;
    },
    _versionModelName() {
      return `${this.modelName}_version`;
    },
    _updateFields() {
      return S_APP_General.getUpdateFields(this.fields);
    },
    _urlModelName() {
      return this.urlModelName ? this.urlModelName : this.modelName;
    },
    _tabsDisplay() {
      return this.tabs?.length > 0 ? true : false;
    },
  },
  props: {
    customButtomBtns: {
      type: Array,
      default() {
        return [];
      },
    },
    sumbitFormData: {},
    postUrl: {
      type: String,
    },
    postEncode: {
      type: Boolean,
      default: true,
    },
    emitSubmit: {
      type: Boolean,
      default: false,
    },
    emitInput: {
      type: Boolean,
      default: false,
    },
    afterSubmitAction: {
      type: Function,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
    parentTitleKey: {
      type: String,
      default: null,
    },
    parentLabel: {
      type: String,
      default: null,
    },
    parentId: {
      type: [String, Number],
      default: null,
    },
    parentModelName: {
      type: String,
      default: null,
    },
    primary: {
      type: Array,
    },
    secondary: {
      type: Array,
    },
    modelName: String,
    label: String,
    fields: Object,
    extendPostData: {
      type: Object,
    },
    returnUrl: {
      type: String,
      default: "",
    },
    urlModelName: String,
    params: {
      type: Object,
    },
    completeRedirectUrl: {},
    initStateFunction: Function,
    emitFormInput: {
      type: Boolean,
      default: false,
    },
    emitComplete: {},
    getCompleteRedirectUrl: {},
    breadcrumbsItems: Array,
    tabs: {
      type: Array,
      default() {
        return [];
      },
    },
    beforeSubmitAction: Function,
  },
  mounted() {
    if (this.parentModelName && this.parentId) {
      this.$_parentFetchData();
    }
    if (this.initStateFunction) {
      this.state = this.initStateFunction();
    }
  },
};
</script>