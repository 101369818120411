export default {
  getXcProjectMenu(data, res) {
    const ctx = data.ctx
    if (ctx.$o_o.$h.auth.hasScope(['wsx_center-admin'])) {
      res.splice(1, 0, {
        title: "WSX 模組",
        link: `/xc_project/${data.id}/wsx_modules`,
      });
    }
    return res
  },
  getXcProjectExtendRoutes(data, res) {
    let _routes = []
    if (res) {
      _routes = res
    }
    _routes.push(...[
      {
        path: 'wsx_modules',
        name: 'wsx_modules',
        component: () => import('@/__modules/wsx_center/extend_modules/xc_project/views/XcProject/WsxModules.vue'),
        meta: {
          middleware: 'auth',
          requireScopes: ['wsx_center-admin']
        },
      },
    ])
    return _routes
  }
}