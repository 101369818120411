<template>
  <div class="ws-state-editor-insert-icon">
    <WsIconBtn
      tooltip="插入 Icon"
      @click="$refs.WsPopup.open()"
      name="icon-sm-instagram"
      :noBorder="noBorder"
      text
    >Icon</WsIconBtn>
    <WsPopup
      title="選擇 Icon"
      ref="WsPopup"
    >
      <template v-slot:content>
        <ValidationObserver ref="form">
          <form @submit.prevent>
            <WsStateForm
              v-model="state"
              :fields="fields"
            />
          </form>
        </ValidationObserver>
      </template>
      <template v-slot:rightActions>
        <WsBtn
          @click="$_onCancel()"
          outlined
        >{{ $t('cancel') }}</WsBtn>
        <WsBtn
          @click="$_onSubmit()"
          color="primary"
        >{{ $t('submit') }}</WsBtn>
      </template>
    </WsPopup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: {},
      fields: {
        icon: {
          type: "icon-picker",
          label: "Icon",
          rules: "required",
        },
        color: {
          type: "color",
          label: "顏色",
        },
      },
    }
  },
  methods: {
    $_onCancel() {
      this.state = null
      this.$refs.WsPopup.close()
    },
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      let color = this.state.color || this._defaultColor
      this.$emit('insertIcon', `<i class="ws-icon ${this.state.icon}" style="color: ${color}"></i>`)
      this.$_onCancel()
    },
  },
  computed: {
    _defaultColor() {
      return this.$store.state.app.theme === 'dark' ? this.$color.white5d : this.$color.black5l
    }
  },
  props: {
    noBorder: {},
  },
}
</script>
