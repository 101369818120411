export default {
  getXcMilestoneReadLeftFields(data, res) {
    let _res
    if (res) {
      _res = res
    } else {
      _res = data.leftFields
    }
    _res = [
      ..._res,
      'xc_project_specs',
    ]
    return _res
  }
}