const model = {
  fields: {
    xc_project_specs: {
      type: 'belongsToMany',
      label: '專案規格',
      modelName: 'xc_project_spec',
      getExtendParamsFromValue: (value) => {
        if (value.xc_project) {
          return {
            xc_project: value.xc_project.id
          }
        } else {
          return {
            xc_project: 0
          }
        }
      }
    },
  },
  crudSetting: {
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'xc_project_specs',
              ],
            },
          ],
        },
      ],
    },
    update: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'xc_project_specs',
              ],
            },
          ],
        },
      ],
    }
  }
}
export default model