const routes = [
  {
    path: '/system_setting/web/other',
    name: 'system_setting_web/other',
    component: () =>
      import('@/__modules/system_setting/views/SystemSetting/Web/Other.vue'),
  },
  {
    path: '/system_setting/web/config',
    name: 'system_setting_web/config',
    component: () =>
      import('@/__modules/system_setting/views/SystemSetting/Web/Config.vue'),
  },
  {
    path: '/system_setting/web/header',
    name: 'system_setting_web_header',
    component: () =>
      import('@/__modules/system_setting/views/SystemSetting/Web/Header.vue'),
  },
  {
    path: '/system_setting/web/footer',
    name: 'system_setting_web_footer',
    component: () =>
      import('@/__modules/system_setting/views/SystemSetting/Web/Footer.vue'),
  },
  {
    path: '/system_setting/cms',
    name: 'system_setting_cms',
    component: () =>
      import('@/__modules/system_setting/views/SystemSetting/Cms/Index.vue'),
  },
  {
    path: '/system_setting/cms_notification',
    name: 'system_setting_cms_notification',
    component: () =>
      import('@/__modules/system_setting/views/SystemSetting/Cms/Notification.vue'),
  },
  {
    path: '/system_setting/smtp',
    name: 'system_setting_smtp',
    component: () =>
      import('@/__modules/system_setting/views/SystemSetting/Smtp/Index.vue'),
  },
  {
    path: '/system_setting/smtp_mailtest',
    name: 'system_setting_smtp_mailtest',
    component: () =>
      import('@/__modules/system_setting/views/SystemSetting/Smtp/MailTest.vue'),
  },
  {
    path: '/system_setting/resource',
    name: 'system_setting_resource',
    component: () =>
      import('@/__modules/system_setting/views/SystemSetting/Resource.vue'),
  },
  {
    path: '/system_setting/general',
    name: 'system_setting_general',
    component: () =>
      import('@/__modules/system_setting/views/SystemSetting/General/Index.vue'),
  },
  {
    path: '/system_setting/app',
    name: 'system_setting_app',
    component: () =>
      import('@/__modules/system_setting/views/SystemSetting/App/Index.vue'),
  },
]
export default routes