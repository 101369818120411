import config from '@/__config';

const model = {
  options: {
    timestamp: true,
  },
  modelName: "xc_project_member",
  label: "成員",
  fields: {
  },
  crudSetting: {
    index: {
      showFields: []
    }
  }
}

if (config.wsmodule.xc_project.xc_project_member.fields.admin) {
  model.fields.admin = {
    type: 'belongsTo',
    label: '後台使用者',
    textKey: 'name',
    modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
    extendParams: {
      is_active: 1
    }
  }
  model.crudSetting.index.showFields.push('admin')
}

if (config.wsmodule.xc_project.xc_project_member.fields.scopes) {
  model.fields.scopes = {
    type: 'radio',
    label: '權限',
    defaultValue: 'read',
    items: [
      {
        label: '瀏覽者',
        value: 'read'
      },
      {
        label: '編輯者',
        value: 'edit'
      },
      {
        label: '管理者',
        value: 'admin'
      },
    ]
  }
  model.crudSetting.index.showFields.push('scopes')
}

if (config.wsmodule.xc_project.xc_project_member.fields.xc_employee) {
  model.fields.xc_employee = {
    type: 'belongsTo',
    label: '成員',
    textKey: 'name',
    modelName: 'xc_employee',
    getText: (modeldata) => {
      if (modeldata.xc_employee_department) {
        return `${modeldata.name} (${modeldata.xc_employee_department.name})`
      } else {
        return modeldata.name
      }
    }
  }
  model.fields.xc_employee_no = {
    type: 'custom',
    label: '員工編號',
    getValue: function (data) {
      return data?.xc_employee?.no || '-'
    },
  }
  model.crudSetting.index.showFields.push('xc_employee')
  model.crudSetting.index.showFields.push('xc_employee_no')
}

if (config.wsmodule.xc_project.xc_project_member.fields.role_name) {
  model.fields.role_name = {
    type: 'select',
    label: '角色',
    items: [
      {
        text: "總督導",
        value: "總督導"
      },
      {
        text: "計畫主持人",
        value: "計畫主持人"
      },
      {
        text: "協同主持人",
        value: "協同主持人"
      },
      {
        text: "研究員",
        value: "研究員"
      },
      {
        text: "副研究員",
        value: "副研究員"
      },
      {
        text: "助理研究員",
        value: "助理研究員"
      },
      {
        text: "研究助理",
        value: "研究助理"
      },
    ]
  }
  model.crudSetting.index.showFields.push('role_name')
}

if (config.wsmodule.xc_project.xc_project_member.fields.start_at) {
  model.fields.start_at = {
    type: 'date',
    label: '開始日期'
  }
  model.crudSetting.index.showFields.push('start_at')
}

if (config.wsmodule.xc_project.xc_project_member.fields.end_at) {
  model.fields.end_at = {
    type: 'date',
    label: '結束日期'
  }
  model.crudSetting.index.showFields.push('end_at')
}

export default model;