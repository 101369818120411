import config from '@/__config'
import H_Router from '@/__stone/helpers/router'

const _routes = [
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    modelName: 'file',
    middleware: 'auth',
    meta: {
      requiredScopes: ['file_system-admin'],
    },
  }),
  ...H_Router.getResourceRoutes({
    routes: ['create'],
    modelName: 'file',
    viewsPath: '__modules/file_system/views',
    meta: {
      middleware: 'auth',
      requiredScopes: [
        'file_system-admin',
      ],
    },
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    modelName: 'image',
    middleware: 'auth',
    meta: {
      requiredScopes: ['file_system-admin'],
    },
  }),
  ...H_Router.getResourceRoutes({
    routes: ['create'],
    modelName: 'image',
    viewsPath: '__modules/file_system/views',
    meta: {
      middleware: 'auth',
      requiredScopes: [
        'file_system-admin',
      ],
    },
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    modelName: 'video',
    middleware: 'auth',
    meta: {
      requiredScopes: ['file_system-admin'],
    },
  }),
  ...H_Router.getResourceRoutes({
    routes: ['create'],
    modelName: 'video',
    viewsPath: '__modules/file_system/views',
    meta: {
      middleware: 'auth',
      requiredScopes: [
        'file_system-admin',
      ],
    },
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    modelName: 'audio',
    middleware: 'auth',
    meta: {
      requiredScopes: ['file_system-admin'],
    },
  }),
  ...H_Router.getResourceRoutes({
    routes: ['create'],
    modelName: 'audio',
    viewsPath: '__modules/file_system/views',
    meta: {
      middleware: 'auth',
      requiredScopes: [
        'file_system-admin',
      ],
    },
  }),
]

if (config.wsmodule.file_system.drive) {
  _routes.push(...[
    {
      path: '/drive',
      name: 'drive',
      component: () =>
        import('@/__modules/file_system/views/Drive/Index.vue'),
    },
    {
      path: '/drive/search',
      name: 'drive_search',
      component: () =>
        import('@/__modules/file_system/views/Drive/Search.vue'),
    },
    {
      path: '/drive/folder/:folder_id',
      name: 'drive_folder',
      component: () =>
        import('@/__modules/file_system/views/Drive/Folder/Index.vue'),
    },
  ])
}

export default _routes