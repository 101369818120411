<template>
  <WsFlex class="flex-full">
    <div>
      <CalendarControllPanel
        @prev="$refs.WsCmsCalendar.prev()"
        @next="$refs.WsCmsCalendar.next()"
        @goDate="$refs.WsCmsCalendar.goDate($event)"
        @changeView="$refs.WsCmsCalendar.changeView($event)"
      ></CalendarControllPanel>
      <WsStateForm
        :fields="filter.fields"
        v-model="filter.state"
      ></WsStateForm>
    </div>
    <WsCmsCalendar
      v-if="filter.state.taker"
      ref="WsCmsCalendar"
      class="mr-12"
      :hasToolbar="false"
      height="100%"
      :droppable="true"
      :editable="true"
      @drop="$_onDrop($event)"
      @eventDragStop="$_onEventDragStop($event)"
      @eventRemove="$_onEventRemove($event)"
      :extendModelsdatas="extendModelsdatas"
      :params="_calendarParams"
      @onEventDropExtendModelsdatas="$_onEventDropExtendModelsdatas($event)"
    >
    </WsCmsCalendar>
    <XcTaskCardList
      v-if="filter.state.taker"
      ref="XcTaskCardList"
      style="height: calc(100vh - 140px);width: 300px;"
      :params="_taskParams"
      :updatable="true"
    ></XcTaskCardList>
  </WsFlex>
</template>

<script>
import { Draggable } from "@fullcalendar/interaction";
export default {
  data() {
    return {
      arranged: {
        xc_task: [],
      },
      extendModelsdatas: {
        xc_task: [],
      },
      calendarEvents: [],
      state: {
        filter: {
          // xc_project: this.xc_project,
        },
      },
      filter: {
        fields: {
          taker: {
            type: "belongsTo",
            label: "執行人",
            textKey: "name",
            modelName: this.$o_o.$c.wsmodule.admin.admin_blur
              ? "cmser"
              : "admin",
            extendParams: {
              is_active: 1,
              index_resource: 2,
            },
          },
        },
        state: {},
      },
    };
  },
  methods: {
    $_updateModeldataInExtendModelsdatas(modeldata) {
      const modelName = modeldata.modelName;
      const _index = this.extendModelsdatas[modelName].findIndex((e) => {
        return e.id == modeldata.id;
      });
      const _extendModelsdatas = {
        ...this.extendModelsdatas,
      };
      _extendModelsdatas[modelName][_index] = {
        ..._extendModelsdatas[modelName][_index],
        ...modeldata,
      };
      this.extendModelsdatas = _extendModelsdatas;
    },
    $_onEventDropExtendModelsdatas($event) {
      const xc_task = $event.event.extendedProps;
      if (!$event.event.allDay) {
        if (!xc_task.hour) {
          this.$store.dispatch(
            "app/addSnack",
            `排入時刻之任務需有預計執行時間`
          );
          return;
        } else {
          this.$o_o.$s._m.xc_task.start_at(xc_task.id, $event.event.start);
          let _status = xc_task.status == 3 ? 4 : xc_task.status;
          this.$_updateModeldataInExtendModelsdatas({
            ...$event.event.extendedProps,
            start_at: $event.event.start,
            due_date: this.$moment($event.event.start).format("YYYY-MM-DD"),
            status: _status,
          });
        }
      } else if ($event.event.allDay) {
        this.$o_o.$s._m.xc_task.due_date(xc_task.id, $event.event.start);
        let _status = xc_task.status == 3 ? 4 : xc_task.status;
        this.$_updateModeldataInExtendModelsdatas({
          ...$event.event.extendedProps,
          due_date: this.$moment($event.event.start).format("YYYY-MM-DD"),
          status: _status,
        });
      }
    },
    $_onEventRemove($event) {
      this.$_removeData($event.event.extendedProps);
    },
    $_onEventClick($event) {
      this.$refs.WsDialogCRUDRead.open($event.event.extendedProps.id);
    },
    $_removeData(modeldata) {
      if (this.extendModelsdatas[modeldata.modelName]) {
        const _index = this.extendModelsdatas[modeldata.modelName].findIndex(
          (e) => {
            return e.id == modeldata.id;
          }
        );
        if (_index >= 0) {
          this.extendModelsdatas[modeldata.modelName].splice(_index, 1);
        }
      }
    },
    $_onEventDragStop($event) {
      if (
        !this.$_isDragStopOnTaskList(
          $event.jsEvent.clientX,
          $event.jsEvent.clientY
        )
      ) {
        return;
      }
      this.$_taskDateReset($event);
    },
    $_taskDateReset($event) {
      const extendedProps = $event.event.extendedProps;
      if (
        !extendedProps ||
        !extendedProps.id ||
        extendedProps.modelName != "xc_task"
      ) {
        return;
      }
      $event.event.remove();
      this.$refs.XcTaskCardList.addTask(extendedProps);
      this.$o_o.$s._m.xc_task.date_reset(extendedProps.id);
    },
    $_isDragStopOnTaskList(x, y) {
      const xcTaskCardListEl = this.$refs.XcTaskCardList.$el;
      var offset = xcTaskCardListEl.getBoundingClientRect();
      if (
        x >= offset.left &&
        y >= offset.top &&
        x <= offset.right &&
        y <= offset.bottom
      ) {
        return true;
      }
      return false;
    },
    async $_dropTaskToCalendarHandler($event) {
      const allDay = $event.allDay;
      const taskId = $event.draggedEl.getAttribute("data-task-id");
      const _task = this.$refs.XcTaskCardList.getTask(taskId);
      let _status = _task.status == 3 ? 4 : _task.status;
      if (allDay) {
        try {
          await this.$o_o.$s._m.xc_task.due_date(taskId, $event.date);
        } catch (error) {
          alert(123123);
          console.error(error);
        }
        this.extendModelsdatas.xc_task.push({
          ..._task,
          due_date: this.$moment($event.date).format("YYYY-MM-DD"),
          status: _status,
        });
        this.$refs.XcTaskCardList.removeTask(taskId);
      } else {
        if (!_task.hour) {
          this.$store.dispatch(
            "app/addSnack",
            `排入時刻之任務需有預計執行時間`
          );
          return;
        }
        this.$o_o.$s._m.xc_task.start_at(taskId, $event.date);
        this.extendModelsdatas.xc_task.push({
          ..._task,
          start_at: $event.date,
          due_date: this.$moment($event.date).format("YYYY-MM-DD"),
          status: _status,
        });
        this.$refs.XcTaskCardList.removeTask(taskId);
      }
    },
    $_onDrop($event) {
      if ($event.draggedEl.getAttribute("data-task-id")) {
        this.$_dropTaskToCalendarHandler($event);
      }
    },
    $_getEventData(dragEvent) {
      const task_id = JSON.parse(dragEvent.getAttribute("data-task-id"));
      const task_hour = JSON.parse(dragEvent.getAttribute("data-task-hour"));
      const _eventData = {
        create: false,
        id: task_id,
        hour: task_hour,
      };
      return _eventData;
    },
  },
  computed: {
    _filterParams() {
      return {
        ...this.$o_o.$h.model.getFormatedStates(
          this.filter.fields,
          this.filter.state
        ),
        xc_employee: this.filter.state.taker?.xc_employee?.id,
      };
    },
    _calendarParams() {
      return {
        ...this.params,
        ...this._filterParams,
      };
    },
    _taskParams() {
      return {
        status: "2,3,4",
        start_at: "null",
        due_date: "null",
        ...this.params,
        ...this._filterParams,
      };
    },
  },
  mounted() {
    const self = this;
    new Draggable(this.$refs.XcTaskCardList.$el, {
      itemSelector: ".xc-task-card",
      eventData: function ($event) {
        return self.$_getEventData($event);
      },
    });
  },
  props: {
    params: {},
  },
};
</script>

<style></style>