<template>
  <div>
    <WsActionApi
      class="width-full"
      :fetchUrl="_fetchUrl"
      v-slot="{ res, isFetching }"
    >
      <template v-if="res && res.data">
        <WsLoading v-if="isFetching"></WsLoading>
        <WsExcel
          v-else
          ref="WsExcel"
          :fields="fields"
          :value="$_getFormatedData(res.data)"
          :readonly="true"
          :exportFilename="_exportFilename"
        ></WsExcel>
      </template>
    </WsActionApi>
    <WsFixedIconBtns :btns="btns"></WsFixedIconBtns>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btns: [
        {
          icon: "icon-md-print",
          text: "匯出",
          size: 60,
          onClick: () => {
            this.$_print();
          },
        },
      ],
      fields: {
        xc_employee_no: {
          label: "員編",
        },
        xc_employee_department: {
          label: "部門",
        },
        create_xc_employee_name: {
          label: "填表人",
        },
        xc_employee_name: {
          label: "申請人",
        },
        type: {
          label: "假別",
        },
        created_at: {
          label: "申請日期",
        },
        start_at: {
          label: "請假開始時間",
        },
        end_at: {
          label: "請假結束時間",
        },
        days: {
          label: "請假天數",
        },
        hours: {
          label: "請假時數",
        },
        note: {
          label: "事由",
        },
        is_aboard: {
          label: "是否出國",
        },
        status: {
          label: "表單狀態",
        },
        pay_at: {
          label: "發薪年月",
        },
      },
    };
  },
  methods: {
    $_print() {
      this.$refs.WsExcel.exportExcel();
    },
    $_getSlectValueText(value, items) {
      const item = items.find((item) => item.value === value);
      return item ? item.text : "";
    },
    $_getFormatedData(data) {
      return data.map((item) => {
        return {
          ...item,
          xc_employee_no: item.xc_employee.no,
          xc_employee_department: item.xc_employee.xc_employee_department?.name,
          create_xc_employee_name: item.create_xc_employee?.name,
          xc_employee_name: item.xc_employee?.name,
          type: this.$_getSlectValueText(
            item.type,
            this.$store.state.stone_model.xc_leave_day.fields.type.items
          ),
          created_at: this.$moment(item.created_at).format("YYYY-MM-DD"),
          start_at: this.$moment(item.start_at).format("YYYY-MM-DD HH:mm"),
          end_at: this.$moment(item.end_at).format("YYYY-MM-DD HH:mm"),
          // start_at: this.$moment(item.start_at).format("YYYY-MM-DD HH:mm"),
          // end_at: this.$moment(item.end_at).format("YYYY-MM-DD HH:mm"),
          days: item.days,
          hours: item.hours,
          note: item.note,
          is_aboard: item.is_aboard ? "是" : "否",
          status: this.$_getSlectValueText(
            item.status,
            this.$store.state.stone_model.xc_leave_day.fields.status.items
          ),
          pay_at: item.pay_at
            ? this.$moment(item.pay_at).format("YYYY-MM")
            : null,
        };
      });
    },
  },
  computed: {
    _exportFilename() {
      return `請假單`;
    },
    _fetchUrl() {
      let _paramString = "";
      for (const key in this.$route.query) {
        if (_paramString === "") {
          _paramString += `?${key}=${this.$route.query[key]}`;
        } else {
          _paramString += `&${key}=${this.$route.query[key]}`;
        }
      }
      if (this.$route.query.start && this.$route.query.end) {
        return `/xc_leave_day/index/date${_paramString}`;
      } else {
        return `/xc_leave_day${_paramString}`;
      }
    },
  },
};
</script>

<style>
</style>