<template>
  <WsMain class="my-board-page">
    <WsFlex gap="16px" class="my-board-main-wrap">
      <WsFlex flexDirection="column" gap="16px" class="my-board-left-wrap">
        <component
          v-for="(component, index) in leftComponents"
          :key="index"
          :is="component.componentName"
        />
      </WsFlex>
      <WsFlex flexDirection="column" gap="16px" class="my-board-right-wrap">
        <component
          v-for="(component, index) in rightComponents"
          :key="index"
          :is="component.componentName"
        />
      </WsFlex>
    </WsFlex>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      leftComponents: [],
      rightComponents: [],
    }
  },
  mounted() {
    this.$_getComponent()
  },
  methods: {
    $_getComponent() {
      let { _leftComponents, _rightComponents } = this.$o_o.$h._m.my_board.$_getMyBoardComponentFromModules(this)
      this.leftComponents = _leftComponents || []
      this.rightComponents = _rightComponents || []
    }
  }
}
</script>

<style lang="scss">
.my-board-page {
  .my-board-main-wrap {
    flex-direction: row;
    @media (max-width: 600px) {
      flex-direction: column;
    }
    .my-board-left-wrap {
      flex: 2;
    }
    .my-board-right-wrap {
      flex: 5;
    }
  }
}
</style>