const model = {
  fields: {
    xc_project: {
      type: 'belongsTo',
      label: '計畫',
      textKey: "name",
      modelName: "xc_project",
      rules: 'required',
      getText(modeldata) {
        if (modeldata.no) {
          return modeldata.no + '-' + modeldata.name
        } else {
          return modeldata.name
        }
      },
      displayCheckOnStateForm(value) {
        return value && value.xc_employee
      },
      getExtendParamsFromValue(value) {
        if (value.xc_employee) {
          return {
            member: value.xc_employee?.id,
            index_resource: 1,
          }
        } else {
          return {}
        }
      },
    }
  },
  crudSetting: {
    index: {
      showFields: [
        'xc_project'
      ]
    },
    index_my: {
      showFields: [
        'xc_project'
      ]
    },
    create_my: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'xc_project'
              ],
            },
          ],
        },
      ],
    },
  }
}
export default model