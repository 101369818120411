<template>
  <div class="ws-state-file-video">
    <WsVideo
      v-if="value"
      :src="value"
      class="mb-10"
    />
    <FileSystemFilePickerVideo
      :signed="signed"
      :storageTarget="storageTarget"
      ref="FileSystemFilePickerVideo"
      @submit="$emit('input',$event)"
      multiple
    />
    <WsBtn  @click="$_onPickVideoDialogOpen()">{{ $t('選擇影片') }}</WsBtn>
    <WsIconBtn
      @click="$_onClear()"
      v-if="value"
      class="remove-btn"
      backgroundColor="#000"
      name="icon-md-delete"
    />
  </div>
</template>
<script>
export default {
  name: "WsStateFileVideo",
  methods: {
    $_onClear() {
      this.$emit("input", null);
    },
    $_onPickVideoDialogOpen() {
      this.$refs.FileSystemFilePickerVideo.open();
    },
  },

  computed: {
    _previewMaxWidth() {
      if (typeof this.previewMaxWidth === "number") {
        return `${this.previewMaxWidth}px`;
      } else {
        return this.previewMaxWidth;
      }
    },
    _previewMaxHeight() {
      if (typeof this.previewMaxHeight === "number") {
        return `${this.previewMaxHeight}px`;
      } else {
        return this.previewMaxHeight;
      }
    },
  },

  props: {
    value: {
      type: String,
      default: null,
    },
    signed: {
      type: Boolean,
      default: false,
    },
    previewMaxWidth: {
      type: [String, Number],
      default: "100%",
    },
    previewMaxHeight: {
      type: [String, Number],
      default: "100%",
    },
    storageTarget: {},
    maxSize: {
      default: 5 * 1024 * 1024,
    },
  },
};
</script>