<template>
  <div>
    <LayoutModelCreate
      v-bind="$store.state.stone_model.xc_leave_day"
      :extendPostData="_extendPostData"
      label="我的請假單"
      urlModelName="my/leave_day"
      bindingKey="create_my"
      completeRedirectUrl="/my/leave_day"
      :fields="_fields"
      postUrl="/my/xc_leave_day"
    >
      <template #beforeContent>
        <WsCard class="mt-20">
          <WsFlex
            class="ws-content-section"
            flexDirection="column"
          >
            <WsStateLabel label="填表人"></WsStateLabel>
            <WsText class="mt-8">{{ _createEmployeeName }}</WsText>
          </WsFlex>
        </WsCard>
      </template>
    </LayoutModelCreate>
  </div>
</template>

<script>
export default {
  computed: {
    _extendPostData() {
      return this.$route.meta.params ? this.$route.meta.params : {};
    },
    _createEmployeeName() {
      if (this.$store.state.auth.currentUser.xc_employee) {
        console.log(
          "xc_employee",
          this.$store.state.auth.currentUser.xc_employee
        );
        return this.$store.state.auth.currentUser.xc_employee.name;
      } else {
        return this.$store.state.auth.currentUser.name;
      }
    },
     _fields() {
      const _fields = this.$o_o.$h.model.getFieldsByKeys(
        this.$store.state.stone_model.xc_leave_day.fields,
        [
          "xc_employee",
          "agent_xc_employee",
          "type",
          "created_at",
          "start_at",
          "end_at",
          "status",
          "days",
          "hours",
          "is_aboard",
          "note",
          "relate_files",
        ]
      );

      if (this.$store.state.auth.currentUser.xc_employee) {
        _fields.xc_employee.defaultValue =
          this.$store.state.auth.currentUser.xc_employee;
      }

      return _fields;
    },
  },
  mounted() {},
};
</script>