<template>
  <div>
    <WsCreate
      ref="WsCreate"
      :modelName="$store.state.stone_model.xc_employee_hire.modelName"
      urlModelName="xc_employee_hire_new"
      label="新聘作業"
      :fields="_fields"
      :extendPostData="extendPostData"
      completeRedirectUrl="/xc_employee_hire"
      :primary="primary"
      :value="value"
      emitInput
      @input="$_input"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: null,
      extendPostData: {
        type: "new",
        xc_employee_status: "working_new",
      },
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: [
                "name",
                "name_eng",
                "no",
                "email",
                "xc_employee_position",
                "xc_employee_position_type",
                "xc_employee_department",
                "xc_employee_type",
                "xc_employee_working_schedule_setting",
                "id_card_no",
                "gender",
                "nationality",
                "expect_employee_start_at",
                "need_trial",
                "trial_period_calculation",
                "mobile",
                "contact_tel",
                "native_place",
                "contact_address",
                "contact_address_detail",
                "household_address",
                "household_address_detail",
                "expertise",
                "research_range",
                "research_field",
                "emergency_contacts",
                "family_members",
                "educations",
                "work_experiences",
                "exam_qualifications",
                "education_trainings",
                "languages",
                "language_exams",
                "next_action_days_after_new_hire",
                "hire_note",
                "suggest_salary",
              ],
            },
          ],
        },
      ],
    };
  },
  computed: {
    _fields() {
      const _fields = {
        ...this.$store.state.stone_model.xc_employee_hire.fields,
      };
      delete _fields.employee_end_at;
      delete _fields.xc_employee;
      delete _fields.xc_employee_status;
      return _fields;
    },
  },
  methods: {
    $_input ($event) {
      let _value = { ...$event }
      if (_value.need_trial) {
        _value.trial_period_calculation = this.$moment(_value.expect_employee_start_at).add(3, 'months').format('YYYY-MM-DD')
      } else {
        _value.trial_period_calculation = null
      }
      this.value = _value
    },
  },
};
</script>