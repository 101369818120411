const routes = [
  {
    path: '/mycalendar',
    name: 'MyCalendar',
    component: () => import('@/__modules/my/views/MyCalendar.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  {
    path: '/mycalc',
    name: 'MyCalc',
    component: () => import('@/__modules/my/views/My/Calc.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  {
    path: '/my_board',
    name: 'MyBoard',
    component: () => import('@/__modules/my/views/MyBoard.vue'),
    meta: {
      middleware: 'auth'
    }
  },
]
export default routes