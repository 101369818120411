<template>
  <div class="width-full">
    <WsActionApi
      :fetchUrl="`xc_project_member_month_day/${_member_month_day_id}`"
      @fetch="$_onFetch($event)"
    >
    </WsActionApi>
    <WsFlex :gap="12">
      <WsBtn @click="$_onExport()">匯出</WsBtn>
      <WsBtn v-if="_hasScope" :to="`/xc_project/${xc_project.id}/member_month_day/create`">建立新版本</WsBtn>
      <WsBtn
        v-if="_hasScope"
        :to="`/xc_project/${xc_project.id}/member_month_day/${_member_month_day_id}/update`"
        outlined
      >編輯此版本</WsBtn>
      <WsBtn
        v-if="_hasScope"
        alert
        @click="$_onClickDeleteCheck()"
      >刪除此版本</WsBtn>
    </WsFlex>
    <XcProjectMemberMonthDayReadTable
      class="mt-20"
      v-if="modeldata"
      ref="XcProjectMemberMonthDayReadTable"
      :items="modeldata.member_month_days"
      :start_at="xc_project.start_at"
      :end_at="xc_project.end_at"
      :exportFilename="`計畫人月-${$moment(modeldata.set_at).format('YYYY-MM-DD')}`"
      :readonly="true"
    />
    <WsInfoForm
      class="mt-20"
      v-if="modeldata"
      :value="modeldata"
      :fields="$store.state.stone_model.xc_project_member_month_day.fields"
    ></WsInfoForm>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modeldata: null,
    };
  },
  methods: {
    $_onClickDeleteCheck() {
      this.$store.dispatch("app/setAlert", {
        title: "確定刪除此版本？",
        description: "",
        action: this.$_onSubmitDelete,
      });
    },
    async $_onSubmitDelete() {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$axios.delete(
          `/xc_project_member_month_day/${this._member_month_day_id}`
        );
        this.$router.push(`/xc_project/${this.xc_project.id}/member_month_day`);
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onExport() {
      this.$refs.XcProjectMemberMonthDayReadTable.exportExcel();
    },
    $_onFetch($event) {
      this.modeldata = $event.data;
    },
  },
  props: {
    xc_project: {},
  },
  computed: {
    _member_month_day_id() {
      return this.$route.params.member_month_day_id;
    },
    _hasScope() {
      if ( this.$o_o.$h.auth.hasScope(['xc_project-edit', 'xc_project-admin']) ) {
        return true
      } else {
        return this.$o_o.$h._m.xc_project.hasPermissions(['edit', 'admin'], this.xc_project.admin_scope)
      }
    },
  },
};
</script>

<style>
</style>