<template>
  <div class="ws-crud-table">
    <WsNoDataMessage v-if="(!_items || !_items.length) && !loading">{{$t(noDataMessage)}}</WsNoDataMessage>
    <!-- <WsNoDataMessage v-if="(!_items || !_items.length) && !loading">{{$t('搜尋或篩選無結果，請嘗試重新輸入條件')}}</WsNoDataMessage> -->
    <template v-else>
      <WsDataTablePagenateTypeB
        v-if="paginate && showTopPaginate"
        class="ws-data-table-pagenate-top"
        @pageto="$emit('pageto', $event)"
        :currentPage="currentPage"
        :dataTotalCount="dataTotalCount"
        :itemsPerPage="itemsPerPage"
        :lastPage="lastPage"
      ></WsDataTablePagenateTypeB>
      <WsText
        v-if="!paginate && showDataTotalCount"
        class="mr-10 pl-16 mb-20"
      >
        共 {{ dataTotalCount }} 筆</WsText>
      <WsFlex
        class="mb-20"
        align-items="center"
        v-if="value && value.length"
      >
        <WsText class="mr-10"> 已選取 {{ value.length }} 個項目</WsText>
        <WsBtn @click="$_cancelAllSelected()">{{$t('取消選取')}}</WsBtn>
      </WsFlex>
      <div class="ws-crud-table__table-container xs-px-6">
        <SlickList
          tag="table"
          cellspacing="0"
          cellpadding="0"
          helperClass="helper"
          :useDragHandle="true"
          :value="items"
          @input="$_onSlickInput($event)"
        >
          <tr :style="[{ 'top': _scrollTop + 'px' }]">
            <th v-if="sortOption"></th>
            <th
              v-if="selectable"
              class="check"
            >
              <WsState
                type="checkbox"
                :value="_tableCheckValue"
                @input="$_onTableCheckInput($event)"
              ></WsState>
            </th>
            <th
              v-if="expandable"
              class="expand"
            ></th>
            <th
              @click="$_orderSet(headersItem)"
              v-for="(headersItem, headersIndex) in _headers"
              :key="headersIndex"
              :class="[{ orderable: $_orderableCheck(headersItem) }, C_orderWay]"
              :style="[{ width: $_getThWidth(headersItem.width) }, { 'max-width': $_getThWidth(headersItem.width) }, { 'min-width': $_getThWidth(headersItem.width) }]"
            >
              <WsText size="12">{{ headersItem.text }}</WsText>
              <WsIcon
                v-if="$_orderableCheck(headersItem) && C_orderBy == headersItem.value"
                name="icon-md-arrow-drop-up"
              />
            </th>
            <th
              v-if="inRowBtnRead || inRowBtnUpdate || inRowBtnDelete || inRowBtnComplete || inRowBtnVersion ||(customTableActions && customTableActions.length)"
              class="actions d-xs-none"
              :style="[{ width: `${_actionWidth}px` }, { 'min-width': `${_actionWidth}px` }, { 'max-width': `${_actionWidth}px` },]"
            >
            </th>
            <th class="actions-xs d-none d-xs-table-cell"></th>
          </tr>
          <SlickItem
            class="ws-crud-table-item"
            tag="tbody"
            v-for="(item, itemIndex) in _items"
            :key="itemIndex"
            :index="itemIndex"
          >
            <tr
              :class="[
                { expandable: expandable },
                { selectedBackground: $_getRowCheckValue(item) },
                { 'row-click-read': rowClickRead }
              ]"
              @click="$_onRowClick(item, itemIndex)"
            >
              <td v-if="sortOption">
                <WsIcon
                  size="20"
                  name="icon-md-drag-handle"
                  v-handle
                />
              </td>
              <td
                v-if="selectable"
                class="check"
              >
                <WsState
                  type="checkbox"
                  :value="$_getRowCheckValue(item)"
                  @input="$_onRowCheckInput($event, item)"
                ></WsState>
              </td>
              <td
                v-if="expandable"
                class="expand"
              >
                <WsIcon
                  size="20"
                  name="icon-md-chevron-down"
                />
              </td>
              <td
                v-for="(headersItem, headersIndex) in _headers"
                :key="headersIndex"
              >
                <WsState
                  v-if="fields[headersItem.value] && fields[headersItem.value].updatable"
                  :value="$_getValue(item, headersItem.value, fields[headersItem.value])"
                  v-bind="fields[headersItem.value]"
                  :label="undefined"
                  :modelData="item"
                  @input="$_onUpdateAlert($event, headersItem.value, itemIndex, fields[headersItem.value], item)
                    "
                  @submit="$_onUpdateAlert($event, headersItem.value, itemIndex, fields[headersItem.value], item); $_updateAlertOpen(item)"
                >
                </WsState>
                <RouterLink
                  v-else-if="fields[headersItem.value].crudClickRead"
                  :to="$_getReadUrl(item)"
                >
                  <WsInfo
                    :value="$_getValue(item, headersItem.value, fields[headersItem.value])"
                    v-bind="fields[headersItem.value]"
                    :label="undefined"
                    :hasControls="false"
                    :modelData="item"
                  ></WsInfo>
                </RouterLink>
                <div v-else>
                  <WsInfo
                    :value="$_getValue(item, headersItem.value, fields[headersItem.value])"
                    v-bind="fields[headersItem.value]"
                    :label="undefined"
                    :hasControls="false"
                    :modelData="item"
                  ></WsInfo>
                </div>
              </td>
              <td
                class="actions d-xs-none"
                :class="[{ selectedBackground: $_getRowCheckValue(item) }]"
              >
                <template v-if="customTableActions">
                  <div
                    v-for="(customTableAction, customTableActionIndex) in customTableActions"
                    :key="customTableActionIndex"
                  >
                    <div v-if="$_customActionDisplayChecek(customTableAction, item)">
                      <WsIconBtn
                        v-if="customTableAction.text"
                        @click.stop="$_onClickCustomTableAction(customTableAction,item,itemIndex)"
                        :to="$_getCustomActionUrl(customTableAction, item)"
                        :tooltip="$_getCustomActionTooltip(customTableAction, item)"
                        :disabled="$_getCustomActionDisabled(customTableAction, item)"
                        text
                      >{{ customTableAction.text }}</WsIconBtn>
                      <WsIconBtn
                        v-else
                        @click.stop="$_onClickCustomTableAction(customTableAction,item,itemIndex)"
                        :name="$_getCustomActionIcon(customTableAction, item)"
                        :to="$_getCustomActionUrl(customTableAction, item)"
                        :tooltip="$_getCustomActionTooltip(customTableAction, item)"
                        :disabled="$_getCustomActionDisabled(customTableAction, item)"
                      />
                    </div>
                  </div>
                </template>
                <template v-if="inRowBtnRead">
                  <WsIconBtn
                    tooltip="Preview"
                    :to="$_getReadUrl(item)"
                    v-if="pageMode && !dialogRead"
                    name="icon-ws-outline-eye-open"
                  />
                  <WsIconBtn
                    tooltip="Preview"
                    v-else
                    @click.stop="$emit('read', { item, itemIndex })"
                    name="icon-ws-outline-eye-open"
                  />
                </template>
                <template v-if="inRowBtnVersion">
                  <WsIconBtn
                    tooltip="Version"
                    :to="$_getVersionUrl(item)"
                    v-if="pageMode && !dialogUpdate"
                    name="icon-md-history"
                  />
                  <WsIconBtn
                    tooltip="Version"
                    v-else
                    @click.stop="$emit('version', { item, itemIndex })"
                    name="icon-md-history"
                  />
                </template>
                <template v-if="$_inRowBtnUpdate(item)">
                  <WsIconBtn
                    tooltip="Edit"
                    :to="$_getUpdateUrl(item)"
                    v-if="pageMode && !dialogUpdate"
                    name="icon-md-edit"
                  />
                  <WsIconBtn
                    tooltip="Edit"
                    v-else
                    @click.stop="$emit('update', { item, itemIndex })"
                    name="icon-md-edit"
                  />
                </template>
                <template v-if="duplicatable">
                  <WsIconBtn
                    tooltip="Duplicate"
                    @click.stop="$emit('duplicate', { item, itemIndex })"
                    name="icon-md-content-copy"
                  />
                </template>
                <template v-if="$_inRowBtnDelete(item)">
                  <WsIconBtn
                    tooltip="Delete"
                    @click.stop="$emit('delete', { item, itemIndex })"
                    name="icon-md-delete"
                    class="action-delete"
                  />
                </template>
              </td>
              <th class="actions-xs d-none d-xs-table-cell">
                <WsCrudTableMoreBtn
                  :item="item"
                  :readable="inRowBtnRead"
                  :updatable="$_inRowBtnUpdate(item)"
                  :deletable="$_inRowBtnDelete(item)"
                  :versionable="inRowBtnVersion"
                  :duplicatable="duplicatable"
                  @read="$_moreBtnOnRead(item,itemIndex)"
                  @update="$_moreBtnOnUpdate(item,itemIndex)"
                  @version="$_moreBtnOnVersion(item,itemIndex)"
                  @delete="$emit('delete', { item, itemIndex })"
                  @duplicate="$emit('duplicate', { item, itemIndex })"
                  @custom-table-action="$emit('custom-table-action', { emit: $event.emit, data: { item, itemIndex } })"
                  :customTableActions="customTableActions"
                />
              </th>
            </tr>
            <tr
              v-if="$_expandedCheck(itemIndex)"
              :key="`${itemIndex}-expand`"
              class="expand-content"
            >
              <td
                v-if="expandable"
                class="expand"
              >
              </td>
              <td :colspan="_expandColspan">
                <WsEasyTable
                  :fields="displayFields"
                  :modelData="items[itemIndex]"
                />
              </td>
            </tr>
          </SlickItem>
        </SlickList>
      </div>
      <WsDataTablePagenateTypeB
        v-if="paginate"
        @pageto="$emit('pageto', $event)"
        :currentPage="currentPage"
        :dataTotalCount="dataTotalCount"
        :itemsPerPage="itemsPerPage"
        :lastPage="lastPage"
      ></WsDataTablePagenateTypeB>
    </template>
    <WsPopup
      ref="updateAlert"
      @enter="$_updateAlertSubmit($refs.updateAlert.data)"
    >
      <template
        #title
        v-if="updateAlert.title"
      >
        <WsText>{{ updateAlert.title }}</WsText>
      </template>
      <template #content>
        {{ updateAlert.content }}
      </template>
      <template #rightActions>
        <WsBtn @click="$_updateAlertClose()">{{ $t('取消') }}</WsBtn>
        <WsBtn @click="$_updateAlertSubmit($refs.updateAlert.data)">{{ $t('確認') }}
        </WsBtn>
      </template>
    </WsPopup>
  </div>
</template>

<script>
import H_State from "@/__stone/helpers/state";

export default {
  data: () => ({
    C_orderBy: null,
    C_orderWay: "desc",
    expandedRows: [],
    updateAlert: {
      title: "",
      content: "",
      data: null,
    },
  }),
  methods: {
    updateModelData(item) {
      this.$emit("update:modeldata", item);
    },
    async $_customTableActionPostAction(data) {
      // console.log("$_customTableActionPostAction", data);
      try {
        this.$store.dispatch("app/startPageLoading");
        const _url = data.customTableAction.getPostUrl(data.item);
        await this.$axios.post(_url);
        if (data.customTableAction.getPostCompleteData) {
          const _item = data.customTableAction.getPostCompleteData(data.item);
          this.$emit("post-complete", _item);
        }
      } catch (err) {
        this.$store.dispatch("app/setAlert", {
          title: "發生錯誤",
          description: "請稍後再試，或聯絡相關人員",
        });
        throw err;
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onClickCustomTableAction(customTableAction, item, itemIndex) {
      if (customTableAction.onClick) {
        customTableAction.onClick(this, item, item.id, this.updateModelData);
      } else if (customTableAction.getPostUrl) {
        if (customTableAction.postCheckText) {
          this.$store.dispatch("app/setAlert", {
            title: customTableAction.postCheckText,
            description: "",
            action: this.$_customTableActionPostAction,
            data: {
              customTableAction,
              item,
            },
          });
        }
      } else if (customTableAction.emit) {
        this.$emit("custom-table-action", {
          emit: customTableAction.emit,
          data: { item, itemIndex },
        });
      }
    },
    $_moreBtnOnRead(item, itemIndex) {
      if (this.pageMode && !this.dialogRead) {
        this.$router.push(this.$_getReadUrl(item));
      } else {
        this.$emit("read", { item, itemIndex });
      }
    },
    $_moreBtnOnUpdate(item, itemIndex) {
      if (this.pageMode && !this.dialogUpdate) {
        this.$router.push(this.$_getUpdateUrl(item));
      } else {
        this.$emit("update", { item, itemIndex });
      }
    },
    $_moreBtnOnVersion(item, itemIndex) {
      if (this.pageMode && !this.dialogUpdate) {
        this.$router.push(this.$_getVersionUrl(item));
      } else {
        this.$emit("version", { item, itemIndex });
      }
    },
    $_onSlickInput($event) {
      this.$emit("update:order", $event);
    },
    $_getValue(item, key, field) {
      if (field.type == "date-range-or-not") {
        return H_State.getValueFromFieldAndFormValue(field, item, key);
      } else {
        return this.$o_o.$h.state.getValueByFieldKey(key, item);
      }
    },
    $_onTableCheckInput($event) {
      let _value;
      if ($event) {
        _value = [...this.items];
      } else {
        _value = [];
      }
      this.$emit("input", _value);
    },
    $_onRowCheckInput($event, item) {
      let _value = this.value ? [...this.value] : [];
      const exist = _value.find((e) => {
        return e[this.modelDataKey] == item[this.modelDataKey];
      });
      if ($event) {
        if (!exist) {
          const _item = this.items.find((e) => {
            return e[this.modelDataKey] == item[this.modelDataKey];
          });
          _value.push(_item);
        }
      } else {
        if (exist) {
          const _valueIndex = this.value.findIndex((e) => {
            return e[this.modelDataKey] == item[this.modelDataKey];
          });
          _value.splice(_valueIndex, 1);
        }
      }
      this.$emit("input", _value);
    },
    $_getRowCheckValue(item) {
      if (!this.value) {
        return false;
      }
      const _item = this.value.find((e) => {
        return e[this.modelDataKey] == item[this.modelDataKey];
      });
      if (_item) {
        return true;
      } else {
        return false;
      }
    },
    $_getCustomActionDisabled(customTableAction, item) {
      if (customTableAction.getDisabled) {
        return customTableAction.getDisabled(item);
      } else {
        return customTableAction.disabled;
      }
    },
    $_customActionDisplayChecek(customTableAction, item) {
      if (customTableAction.displayCheck) {
        return customTableAction.displayCheck(item, this);
      } else {
        return true;
      }
    },
    $_getCustomActionTooltip(customTableAction, item) {
      if (customTableAction.getTooltip) {
        return customTableAction.getTooltip(item);
      } else {
        return customTableAction.tooltip;
      }
    },
    $_getCustomActionUrl(customTableAction, item) {
      if (customTableAction.getUrl) {
        return customTableAction.getUrl(item);
      } else {
        return null;
      }
    },
    $_getCustomActionIcon(customTableAction, item) {
      if (customTableAction.getIcon) {
        return customTableAction.getIcon(item);
      } else {
        return customTableAction.icon;
      }
    },
    $_getThWidth(width) {
      if (width) {
        return width;
      } else {
        return "100px";
      }
    },
    $_getReadUrl(item) {
      if (this.getReadUrl) {
        return this.getReadUrl(item);
      } else {
        return `/${this._urlModelName}/${item.id}`;
      }
    },
    $_getUpdateUrl(item) {
      if (this.getUpdateUrl) {
        return this.getUpdateUrl(item);
      } else {
        return `/${this._urlModelName}/${item.id}/update`;
      }
    },
    $_getVersionUrl(item) {
      if (this.getVersionUrl) {
        return this.getVersionUrl(item);
      } else {
        return `/${this._urlModelName}/${item.id}/version`;
      }
    },
    $_expandedCheck(index) {
      return this.expandedRows.includes(index);
    },
    $_onRowClick(item, itemIndex) {
      // if (!this.inRowBtnRead && this.rowClickRead) {
      if (this.rowClickRead) {
        if (this.dialogRead) {
          this.$emit("read", { item, itemIndex });
        } else {
          this.$router.push(this.$_getReadUrl(item));
        }
      }
      if (!this.expandable) {
        return;
      }
      const tarIndex = this.expandedRows.findIndex((e) => {
        return e == itemIndex;
      });
      if (tarIndex >= 0) {
        this.expandedRows.splice(tarIndex, 1);
      } else {
        this.expandedRows.push(itemIndex);
      }
    },
    $_orderSet(item) {
      if (!this.$_orderableCheck(item)) {
        return;
      }
      const value = item.value;
      if (this.C_orderBy != value) {
        this.C_orderBy = value;
        this.C_orderWay = this.orderWayDefault;
      } else {
        if (this.C_orderWay == this.orderWayDefault) {
          this.C_orderWay = this.orderWayDefault == "desc" ? "asc" : "desc";
        } else {
          this.C_orderWay = this.orderWayDefault;
          this.C_orderBy = this.orderByDefault;
        }
      }
    },
    $_orderableCheck(headersItem) {
      if (!headersItem.sortable) {
        return false;
      } else if (headersItem.value == "actions") {
        return false;
      } else {
        return true;
      }
    },
    expandReset() {
      this.expandedRows = [];
    },
    $_cancelAllSelected() {
      this.$emit("input", []);
    },
    $_onUpdateAlert($event, fieldKey, fieldIndex, field, state) {
      this.updateAlert.data = {};
      this.updateAlert.data[fieldKey] = $event;
      if (this.fields[fieldKey].updatable.getAlertTitle) {
        this.updateAlert.title =
          this.fields[fieldKey].updatable.getAlertTitle(state);
      }
      if (this.fields[fieldKey].updatable.getAlertContent) {
        this.updateAlert.content =
          this.fields[fieldKey].updatable.getAlertContent(state);
      }
      this.updateAlert.index = fieldIndex;
      if (field.type === "switch") {
        this.$_updateAlertOpen(state);
      }
    },
    $_updateAlertClose() {
      this.$refs.updateAlert.close();
    },
    $_updateAlertOpen(data) {
      this.$refs.updateAlert.open(data);
    },
    async $_updateAlertSubmit($event) {
      this.$_updateAlertClose();
      try {
        await this.$axios.patch(
          `/${this.modelName}/${$event.id}`,
          this.updateAlert.data
        );
        let _items = [...this._items];
        _items[this.updateAlert.index] = this.updateAlert.data;
        this.$emit("updateAlert", _items);
      } catch (err) {
        alert(err);
      }
    },
    $_formatItemOrderProduct(item, itemPre, orderProductFields, keyPre = "") {
      for (let orderProductFieldKey in orderProductFields) {
        const shopOrderShopProductField =
          orderProductFields[orderProductFieldKey];
        this.$_formatItem(
          item,
          itemPre.shop_order_shop_product,
          shopOrderShopProductField,
          orderProductFieldKey,
          `${keyPre}shop_order_shop_product__`
        );
      }
    },
    $_formatItemOther(item, itemPre, fieldKey, keyPre) {
      if (!itemPre || !itemPre[fieldKey]) {
        return;
      }
      item[`${keyPre}${fieldKey}`] = itemPre ? itemPre[fieldKey] : null;
    },
    $_formatItem(item, itemPre, field, fieldKey, keyPre = "") {
      if (field.type == "shop_order_shop_product") {
        this.$_formatItemOrderProduct(item, itemPre, field.fields, keyPre);
      } else {
        this.$_formatItemOther(item, itemPre, fieldKey, keyPre);
      }
    },
    $_inRowBtnUpdate(item) {
      if (this.updatable !== undefined) {
        return this.updatable;
      }
      if (this.checkUpdatable) {
        return this.checkUpdatable(item);
      } else {
        return this.inRowBtnUpdate;
      }
    },
    $_inRowBtnDelete(item) {
      if (this.deletable !== undefined) {
        return this.deletable;
      }
      if (this.checkDeletable) {
        return this.checkDeletable(item);
      } else {
        return this.inRowBtnDelete;
      }
    },
  },
  computed: {
    _displayFields() {
      if (this.displayFields) {
        return this.displayFields;
      } else {
        return this.fields;
      }
    },
    _tableCheckValue() {
      if (this.value && this.value.length) {
        if (this.value.length == this.items.length) {
          return true;
        } else {
          return "-";
        }
      } else {
        return false;
      }
    },
    _tdBorderColor() {
      if (this.tdBorderColor) {
        return this.tdBorderColor;
      } else {
        return this.$color.gray;
      }
    },
    _expandColspan() {
      return this._headers.length + 1;
    },
    _items() {
      if (!this.items) {
        return [];
      }
      const _items = [...this.items];
      _items.forEach((item) => {
        for (let fieldKey in this.fields) {
          // 判斷顯示/隱藏
          const field = this.fields[fieldKey];
          this.$_formatItem(item, item, field, fieldKey);
        }
      });
      return _items;
    },
    _actionWidth() {
      if (this.actionWidth) {
        return this.actionWidth;
      }
      let _actionWidth = 10;
      let count = 0;

      if (this.inRowBtnRead && this.readable) {
        _actionWidth += 40.1;
        count++;
      }
      if (this.inRowBtnUpdate && this.updatable) {
        _actionWidth += 40.1;
        count++;
      }
      if (this.inRowBtnDelete && this.deletable) {
        _actionWidth += 40.1;
        count++;
      }
      if (this.inRowBtnVersion) {
        _actionWidth += 40.1;
        count++;
      }
      if (this.duplicatable) {
        _actionWidth += 40.1;
        count++;
      }
      if (this.inRowBtnComplete) {
        _actionWidth += 40.1;
        count++;
      }
      if (this.customTableActions) {
        this.customTableActions.forEach(() => {
          _actionWidth += 40.1;
          count++;
        });
      }
      _actionWidth += count * 8;
      return _actionWidth;
    },
    _headers() {
      if (this.headers) {
        return this.headers;
      } else if (this.showFields) {
        const _headers = [];
        this.showFields.forEach((showFieldKey) => {
          if (showFieldKey in this._displayFields) {
            const field = this._displayFields[showFieldKey];
            const _label = field.labelInLocale
              ? this.$t(field.label)
              : field.label;
            if (field.type == "list" || field.type == "evaluationStage") {
              return;
            }
            if (
              field.type == "image" ||
              field.type == "tags" ||
              field.type == "password" ||
              field.type == "link" ||
              field.type == "editor"
            ) {
              _headers.push({
                value: showFieldKey,
                text: _label,
                width: field.width,
                sortable: false,
              });
              return;
            }
            _headers.push({
              value: showFieldKey,
              text: _label,
              width: field.width,
              sortable: field.sortable,
            });
          }
        });
        return _headers;
      } else {
        return [];
      }
    },
    _urlModelName() {
      return this.urlModelName ? this.urlModelName : this.modelName;
    },
    _scrollTop() {
      return 0;
      // if (!this.headerSticky) return 0;
      // return this.scrollTop;
    },
  },

  props: {
    checkUpdatable: {
      type: Function,
    },
    checkDeletable: {
      type: Function,
    },
    showDataTotalCount: {
      type: Boolean,
      default: true,
    },
    modelDataKey: {
      type: String,
      default: "id",
    },
    value: {
      type: Array,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    tdBorderColor: {
      type: String,
    },
    customTableActions: {
      type: Array,
      default: null,
    },
    showFields: {
      type: Array,
      default: null,
    },
    dialogRead: {
      type: Boolean,
      default: false,
    },
    dialogUpdate: {
      type: Boolean,
      default: false,
    },
    dialogDelete: {
      type: Boolean,
      default: false,
    },
    getUpdateUrl: {
      type: Function,
      default: null,
    },
    getVersionUrl: {
      type: Function,
      default: null,
    },
    getReadUrl: {
      type: Function,
      default: null,
    },
    paginate: {
      type: Boolean,
      default: true,
    },
    headers: {
      type: Array,
      default: null,
    },
    lastPage: Number,
    itemsPerPage: Number,
    currentPage: [Number, String],
    dataTotalCount: {
      type: Number,
      // default: 0,
    },
    orderBy: {
      type: [String, Array],
      default: "updated_at",
    },
    orderWay: {
      type: [String, Array],
      default: "desc",
    },
    orderByDefault: {
      type: [String, Array],
      default: "updated_at",
    },
    orderWayDefault: {
      type: [String, Array],
      default: "desc",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rowClickRead: {
      type: Boolean,
      default: false,
    },
    inRowBtnRead: {
      type: Boolean,
      default: true,
    },
    inRowBtnUpdate: {
      type: Boolean,
      default: true,
    },
    inRowBtnDelete: {
      type: Boolean,
      default: true,
    },
    inRowBtnComplete: {
      type: Boolean,
      default: false,
    },
    inRowBtnVersion: {
      type: Boolean,
      default: false,
    },
    expandable: {
      type: Boolean,
      default: true,
    },
    modelName: String,
    pageMode: Boolean,
    displayFields: Object,
    fields: Object,
    items: {
      type: Array,
      default: null,
    },
    showTopPaginate: {
      type: Boolean,
      default: false,
    },
    urlModelName: String,
    scrollTop: {
      type: Number,
      default: 0,
    },
    headerSticky: {
      type: Boolean,
    },
    sortOption: {
      type: Object,
    },
    readable: {},
    updatable: {},
    deletable: {},
    actionWidth: {},
    duplicatable: {},
    noDataMessage: {
      default: "尚無資料",
    },
  },

  watch: {
    C_orderBy: {
      handler() {
        this.$emit("update:orderBy", this.C_orderBy);
      },
    },
    C_orderWay: {
      handler() {
        this.$emit("update:orderWay", this.C_orderWay);
      },
    },
    orderBy: {
      handler() {
        this.C_orderBy = this.orderBy;
      },
    },
    orderWay: {
      handler() {
        this.C_orderWay = this.orderWay;
      },
    },
  },
};
</script>