<template>
  <WsMain>
    <ValidationObserver
      ref="form"
      class="ws-content-section-observer"
    >
      <form
        ref="form"
        @submit.prevent
      >
        <WsStateForm
          v-model="state"
          :fields="fields"
        />
      </form>
    </ValidationObserver>
    <WsBtn
      class="mt-24"
      @click="$_onSubmit()"
    >{{ $t('submit') }}</WsBtn>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      state: {},
      fields: {
        date: {
          type: "date",
          label: "統計日期",
          rules: "required",
        },
        xc_employee_department: {
          type: "belongsTo",
          label: "部門",
          modelName: "xc_employee_department",
          rules: "required",
        },
      },
    };
  },
  methods: {
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      this.$router.push(this._to);
    },
  },
  computed: {
    _to() {
      return`/xc_leave_day_print_overview/sheetprint?date=${this.state.date}&xc_employee_department=${this.state.xc_employee_department.id}`
    },
  },
}
</script>
