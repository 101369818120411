<template>
  <WsDialogUpdate
    ref="WsDialogUpdate"
    @close="$_onCancel()"
    @submit="$_onSubmit()"
    :title="`${$t('edit')} ${$t(label)}`"
  >
    <template v-slot:content>
      <WsLoading v-if="loading.fetch"></WsLoading>
      <div v-else-if="isMounted">
        <WsErrorMessage
          v-if="pageErrorMessage"
          class="mb-20"
        >{{ pageErrorMessage }}</WsErrorMessage>
        <ValidationObserver ref="form">
          <form
            ref="createForm"
            @submit.prevent
          >
            <WsStateForm
              v-model="state"
              :fields="_fields"
              :modelName="modelName"
              :errorMessages="errorMessages"
            ></WsStateForm>
          </form>
        </ValidationObserver>
      </div>
    </template>
  </WsDialogUpdate>
</template>

<script>
export default {
  data: () => ({
    pageErrorMessage: null,
    modelData: null,
    state: {},
    dialog: false,
    id: null,
    loading: {
      fetch: false,
    },
    isMounted: false,
  }),
  computed: {
    _fields() {
      let _fields = this.updateFields
        ? { ...this.updateFields }
        : { ...this.fields };
      if (this.getFields) {
        _fields = this.getFields(this.state, _fields);
      }
      for (let fieldKey in _fields) {
        const _field = _fields[fieldKey];
        if (_field.updateRules !== undefined) {
          _field.rules = _field.updateRules;
        }
      }
      return _fields;
    },
  },
  methods: {
    $_init() {
      this.$_stateInit();
      this.isMounted = true;
    },
    $_stateInit() {
      if (this.extendPostData) {
        this.state = {
          ...this.extendPostData,
        };
      }
    },
    $_onDialogClose() {
      this.close();
    },
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      const postData = this.$o_o.$h.model.getFormatedStates(
        this._fields,
        this.state,
        this.postEncode
      );
      this.$emit("submit", {
        ...postData,
        id: this.id,
      });
    },
    $_onCancel() {
      this.reset();
    },
    close() {
      this.$refs.WsDialogUpdate.close();
      this.reset();
    },
    reset() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.state = {};
      setTimeout(() => {
        this.pageErrorMessage = null;
      }, 0);
    },
    async open(item) {
      this.$refs.WsDialogUpdate.open();
      if (this.fetchOnOpen) {
        let _fetchUrl;
        if (this.getReadFetchUrl) {
          _fetchUrl = this.getReadFetchUrl(item);
        } else {
          _fetchUrl = `${this.modelName}/${item.id}`;
        }
        try {
          this.loading.fetch = true;
          const res = await this.$axios.get(_fetchUrl);
          this.modelData = res.data.data;
        } catch (error) {
          alert("讀取資料錯誤");
        } finally {
          this.loading.fetch = false;
        }
      } else {
        this.modelData = item;
      }
      this.state = {
        ...this.state,
        ...this.$o_o.$h.state.getValueFromFields(this._fields, this.modelData),
      };
      this.id = item.id ? item.id : null;
    },
  },
  mounted() {
    this.$_init();
  },
  props: {
    extendPostData: {},
    getFields: {},
    fetchOnOpen: {
      type: Boolean,
    },
    postEncode: {
      type: Boolean,
      default: true,
    },
    errorMessages: {
      type: Object,
      default: null,
    },
    modelName: String,
    label: {
      type: String,
      default: null,
    },
    fields: Object,
    updateFields: {
      type: Object,
    },
    getReadFetchUrl: {},
  },
};
</script>