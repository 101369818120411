<template>
  <WsUpdate
    label="系統設定 - 其他"
    :fields="_fields"
    :primary="_primary"
    getUrl="/system_setting/web"
    :completeRedirect="false"
    :postEncode="false"
  >
  </WsUpdate>
</template>

<script>
export default {
  computed: {
    _fields() {
      return {
        ...this.fields,
        ...this.$o_o.$h.module.extendModuleHelperAction(
          "system_setting",
          "system_setting.js",
          "getSystemSettingWebFields",
          {}
        ) || {},
      };
    },
    _primary() {
      return [
        {
          type: "stateCard",
          floors: [
            {
              fields: [
                ...this.primary[0].floors[0].fields,
                ...this.$o_o.$h.module.extendModuleHelperAction(
                  "system_setting",
                  "system_setting.js",
                  "getSystemSettingWebShowFields",
                  {}
                ) || [],
              ],
            },
          ],
        },
      ];
    },
  },
  data() {
    return {
      fields: {
        title: {
          type: "text",
          label: this.$t("網站標題"),
        },
        author: {
          type: "text",
          label: this.$t("網站作者"),
        },
        copyright: {
          type: "text",
          label: this.$t("網站版權"),
        },
        keywords: {
          type: "text",
          label: this.$t("網站關鍵字"),
          tooltip: this.$t("以逗號 ',' 隔開關鍵字"),
        },
        schema_org_json: {
          type: "json",
          label: "Schema.org JSON",
        },
        favicon: {
          type: "file_image",
          label: this.$t("Favicon (網站頁籤 icon) (32 * 32)"),
        },
        "og:image": {
          type: "file_image",
          label: this.$t("網站圖片 (社群分享顯示) (1200 * 630)"),
          previewMaxWidth: 1200,
          previewMaxHeight: 630,
        },
        ga_ids: {
          type: "tags",
          label: this.$t("GA 追蹤代碼ID (可設定多組)"),
          placeholder: this.$t("Enter 新增追蹤代碼ID (可用空白區隔)"),
        },
        lang: {
          type: 'text',
          label: '語系標籤',
        },
        config: {
          type: "form",
          label: "",
          fields: {
            general: {
              type: "form",
              label: "",
              fields: {
                isRightClickDisabled: {
                 type: "switch",
                 label: this.$t("禁止右鍵點擊"),
                 defaultValue: false,
               },
                coming_soon: {
                  type: "form",
                  label: "",
                  fields: {
                    image: {
                      type: "image",
                      label: this.$t("Coming Soon 圖片"),
                    },
                  },
                },
                error404: {
                  type: "form",
                  label: "",
                  fields: {
                    image: {
                      type: "image",
                      label: this.$t("404 圖片"),
                    },
                  },
                },
                facebook_chat_plugin: {
                  type: "form",
                  label: "",
                  fields: {
                    page_id: {
                      type: "text",
                      label: this.$t("FB 洽談外掛程式 page_id"),
                    },
                  },
                },
                facebook_pixel: {
                  type: "text",
                  label: "FB Pixel Key",
                },
                modules_config: {
                  type: "json",
                  label: "WsModule Config",
                },
                gtm_ids: {
                  type: "tags",
                  label: this.$t("GTM 代碼 (可設定多組)"),
                  placeholder: this.$t("Enter 新增代碼 (可用空白區隔)"),
                },
              },
            },
          },
        },
      },
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: [
                "favicon",
                "title",
                "author",
                "copyright",
                "keywords",
                "schema_org_json",
                "og:image",
                "lang",
                "config",
                "ga_ids",
              ],
            },
          ],
        },
      ],
    };
  },
};
</script>

<style></style>
