const model = {
  options: {
    // id: true,
  },
  modelName: 'xc_leave_day',
  label: '請假單',
  fields: {
    xc_employee: {
      type: 'belongsTo',
      label: '申請人',
      textKey: 'name',
      modelName: 'xc_employee',
      extendParams: {
        index_resource: 3,
      },
      getText: (modeldata) => {
        if (modeldata.xc_employee_department) {
          return `${modeldata.name} (${modeldata.xc_employee_department.name})`;
        } else {
          return modeldata.name;
        }
      },
    },
    agent_xc_employee: {
      type: 'belongsTo',
      label: '職務代理人',
      textKey: 'name',
      modelName: 'xc_employee',
      extendParams: {
        index_resource: 3,
      },
      getText: (modeldata) => {
        if (modeldata.xc_employee_department) {
          return `${modeldata.name} (${modeldata.xc_employee_department.name})`;
        } else {
          return modeldata.name;
        }
      },
    },
    create_xc_employee: {
      type: 'belongsTo',
      label: '填表人',
      textKey: 'name',
      modelName: 'xc_employee',
    },
    status: {
      type: 'select',
      label: '狀態',
      readonly: true,
      defauleValue: 1,
      items: [
        {
          text: '簽核中',
          value: 'processing',
        },
        {
          text: '已核准',
          value: 'approved',
        },
        {
          text: '不同意',
          value: 'disapproved',
        },
        {
          text: '收回',
          value: 'withdrawn',
        },
        {
          text: '取消',
          value: 'canceled',
        },
        {
          text: '結算中',
          value: 'settling',
        },
        {
          text: '已支付',
          value: 'payed',
        },
      ],
    },
    name: {
      type: 'text',
      label: '名稱',
    },
    // start_date: {
    //   type: 'date',
    //   label: '請假開始日期',
    // },
    days: {
      type: 'number',
      label: '請假天數',
      readonly: true,
    },
    start_at: {
      type: 'datetime',
      label: '請假開始時間',
      rules: 'required',
      minuteStep: 30,
      format: 'YYYY-MM-DD HH:mm',
      editable: false,
      col: 3,
      getDefaultValue: async (controller) => {
        const _res = await controller.$axios.get('/my/xc_employee');
        if (!_res) {
          return;
        }
        const _my_employee = _res.data.data;
        if (
          !_my_employee?.xc_employee_working_schedule_setting
            ?.scheduled_working_hours
        ) {
          return;
        }

        const _firstTimeRange =
          _my_employee?.xc_employee_working_schedule_setting
            ?.scheduled_working_hours[0];
        const _firstTime = _firstTimeRange[0];

        const _targetTime = controller.$moment(_firstTime);
        const _datetime = controller.$moment().set({
          hour: _targetTime.hour(),
          minute: _targetTime.minute(),
          second: 0,
        });
        return _datetime.format('YYYY-MM-DD HH:mm');
      },
    },
    end_at: {
      type: 'datetime',
      label: '請假結束時間',
      rules: 'required',
      minuteStep: 30,
      format: 'YYYY-MM-DD HH:mm',
      editable: false,
      col: 3,
      getDefaultValue: async (controller) => {
        const _res = await controller.$axios.get('/my/xc_employee');
        if (!_res) {
          return;
        }
        const _my_employee = _res.data.data;
        if (
          !_my_employee?.xc_employee_working_schedule_setting
            ?.scheduled_working_hours
        ) {
          return;
        }

        const _lastTimeRange =
          _my_employee?.xc_employee_working_schedule_setting
            ?.scheduled_working_hours[
            _my_employee?.xc_employee_working_schedule_setting
              ?.scheduled_working_hours.length - 1
          ];
        const _lastTime = _lastTimeRange[_lastTimeRange.length - 1];

        const _targetTime = controller.$moment(_lastTime);
        const _datetime = controller.$moment().set({
          hour: _targetTime.hour(),
          minute: _targetTime.minute(),
          second: 0,
        });
        return _datetime.format('YYYY-MM-DD HH:mm');
      },
    },
    hours: {
      type: 'number',
      label: '時數',
      readonly: true,
    },
    is_aboard: {
      type: 'switch',
      label: '是否出國',
      defaultValue: false,
      col: 2,
    },
    note: {
      type: 'textarea',
      label: '請假事由',
    },
    type: {
      type: 'select',
      label: '假別',
      defaultValue: 'annual_leave',
      col: 3,
      items: [
        {
          text: '特休假',
          value: 'annual_leave',
          des: '',
        },
        {
          text: '去年特休延休假',
          value: 'annual_leave_deferred',
          des: '',
        },
        {
          text: '病假',
          value: 'sick_leave',
          des: '1、申請最小單位為0.5小時。\n2、每月2號工時鎖定前，可補上個月之表單。\n3、未住院者，1年內合計不得超過30日。\n4、住院者，2年內合計不得超過1年。\n5、未住院傷病假與住院傷病假2年內合計不得超過1年。\n超過上開規定之期限，經以事假或特別休假抵充後仍未痊癒者，得予留職停薪，但以1年為限。',
        },
        {
          text: '事假',
          value: 'personal_leave',
          des: '全年累計事假（含家庭照顧假）不得超過14天。\n1、申請最小單位為0.5小時。\n2、每月2號工時鎖定前，可補上個月之表單。\n3、因有事故必須親自處理者。\n4、須以親自辦理請假程序，並經核准後始得離開。\n5、緊急事故得由同事或家屬親友代辦請假手續。',
        },
        {
          text: '加班補休',
          value: 'annual_leave_from_work_overtime',
          des: '',
        },
        {
          text: '生理假',
          value: 'menstrual_leave',
          des: '女性員工因生理日致工作有困難者，每月得請生理假1日。\n1、申請最小單位為0.5小時。\n2、每月2號工時鎖定前，可補上個月之表單。',
          displayCheck: (modeldata) => {
            if (!modeldata.xc_employee) {
              return false;
            } else if (modeldata.xc_employee.gender == 'female') {
              return true;
            } else {
              return false;
            }
          },
        },
        {
          text: '家庭照顧假',
          value: 'family_care_leave',
          des: '1、家庭成員預防接種、發生嚴重之疾病或其他重大事故須親自照顧時，得請家庭照顧假，其請假日數併入事假計算。\n2、最小請假單位0.5小時。',
        },
        {
          text: '公假(員旅)',
          value: 'official_leave_employee_trip',
          des: '適用人員:員工旅遊不符參加資格同仁(尚在試用期中同仁)或經院長核准留守辦公室同仁。\n2、請上傳公司公告。',
        },
        {
          text: '公假(健檢)',
          value: 'official_leave_health_check_up',
          des: '1、員工健檢依院規定應給公假。\n2、申請最小單位為0.5小時。\n3、應於事前提出申請。',
        },
        {
          text: '公假(教召,其它法定公假)',
          value: 'official_leave_statutory',
          des: '1、員工因兵役召集及其他法令規定應給公假等，依實際需要天數給予公假。\n2、依政府公布之傳梁病防治法第38條第二項,配合防疫工作之人員,依主管機關之指示給予公假。\n3、申請最小單位為0.5小時。\n4、應於事前提出申請。',
        },
        {
          text: '公傷病假',
          value: 'occupational_injury_leave',
          des: '因職業災害而致殘廢、傷害或疾病者，其治療、休養期間，給予公傷病假。',
        },
        {
          text: '喪假-3天',
          value: 'funeral_leave_3',
          des: '兄弟姊妹、配偶之祖父母（含外祖父母）喪亡者。\n1、喪假得依習俗分次申請。\n2、應於死亡之日起百日內請畢為限。\n3、須檢附訃聞或死亡證明書及關係文件。\n※【發生日】填寫親人過世之日期。',
        },
        {
          text: '喪假-6天',
          value: 'funeral_leave_6',
          des: '祖父母（含外祖父母）、子女、配偶之父母、配偶之養父母或繼/父母喪亡者。\n1、喪假得依習俗分次申請。\n2、應於死亡之日起百日內請畢為限。\n3、須檢附訃聞或死亡證明書及關係文件。\n※【發生日】填寫親人過世之日期。',
        },
        {
          text: '喪假-8天',
          value: 'funeral_leave_8',
          des: '父母、養父母、繼父母、配偶喪亡者\n1、喪假得依習俗分次申請。\n2、應於死亡之日起百日內請畢為限。\n3、須檢附訃聞或死亡證明書及關係文件。\n※【發生日】填寫親人過世之日期。',
        },
        {
          text: '婚假',
          value: 'marriage_leave',
          des: '1、本人結婚。\n2、結婚登記日證明文件。\n3、 除因特殊事由，應自結婚之日起3個月內請畢，每次至少半日 (【上半天 09:00~12:00】或【下半天 13:30~18:00/13:00~17:30】)。\n※【發生日】填寫結婚登記日期。',
        },
        {
          text: '產假-5天',
          value: 'maternity_leave_5',
          des: '1、妊娠未滿2個月流產者，不扣除例假日並應一次請畢。須附合法醫療機構醫師證明書。\n2、最小申請單位5天。',
        },
        {
          text: '產假-7天',
          value: 'maternity_leave_7',
          des: '1、妊娠2個月以上未滿3個月流產者，不扣除例假日並應一次請畢。須附合法醫療機構醫師證明書。\n2、最小申請單位7天。',
        },
        {
          text: '產假-28天',
          value: 'maternity_leave_28',
          des: '1、妊娠3個月以上流產者，流產假不扣除例假日並應一次請畢。須附合法醫療機構醫師證明書。\n2、最小申請單位28天。',
        },
        {
          text: '產假-56天',
          value: 'maternity_leave_56',
          des: '1、因分娩者。\n2、須呈合法醫療機構醫師證明書。\n3、分娩假不扣除例假日，應一次請畢，不得保留。\n4、最小申請單位為56天。',
        },
        // {
        //   text: "陪產假",
        //   value: "paternity_leave",
        //   des: '員工於其配偶分娩時，給予陪產假。',
        // },
        // {
        //   text: "流產假",
        //   value: "miscarriage_leave",
        //   des: '妊娠 3 個月以上流產者，附合法醫療機構醫師證明書。',
        // },
        {
          text: '產檢假/陪產(檢)假',
          value: 'maternity_check_leave',
          des: '陪產檢：配偶妊娠期間，陪產檢假與陪產假合併在一起，共7天。\n陪產假：配偶分娩當日及前後合計15天期間休畢。\n產檢假：妊娠期間共7天\n上傳檢附證明(擇一)：\n1.產檢醫院掛號繳費收據\n2.產檢醫院門診證明\n3.媽媽手冊(封面+蓋章頁)\n4.出生證明(陪產假)',
        },
        {
          text: '疫苗接種假',
          value: 'vaccine_leave',
          des: '請檢具上傳疫苗接種紀錄卡。',
        },
        {
          text: '防疫隔離假',
          value: 'quarantine_leave',
          des: '',
        },
        {
          text: '防疫照顧假',
          value: 'epidemic_prevention_care_leave',
          des: '1、因應COVID-19疫情中如有照顧國中小以下學童之需求，家長其中1人得請「防疫照顧假」。\n2、最小請假單位0.5小時。\n3、請上傳學校（或班級）停課通知。\n4、防疫照顧假是否扣薪，依公告辦理。',
        },
        {
          text: '防疫返鄉假',
          value: 'quarantine_homecoming_leave',
          des: '',
        },
        {
          text: '兼課',
          value: 'teaching_leave',
          des: '1.每日最高請假上限7.5小時。\n2.自離開開辦公室起算，請依實際需求時間請假(含兼課時數+車程時間)。',
        },
        {
          text: '進修',
          value: 'study_leave',
          des: '1.每日最高請假上限7.5小時。\n2.自離開開辦公室起算，請依實際需求時間請假(含兼課時數+車程時間)。',
        },
      ],
      setPreDes(controller, value, stateData) {
        if (
          stateData.xc_employee?.id ==
          controller.$store.state.auth.currentUser.xc_employee?.id
        ) {
          const _preDesApiUrl = `/my/xc_leave_day/${controller.value}/remain_days_and_hours_days`;
          controller.$axios.get(_preDesApiUrl).then((res) => {
            if (res.data.need_count_remain) {
              controller.preDes = `剩餘 ${res.data.remain_days_and_hours_days}天 ${res.data.remain_days_and_hours_hours}小時`;
            } else {
              controller.preDes = '';
            }
          });
        } else {
          controller.preDes = '';
        }
      },
    },
    created_at: {
      type: 'date',
      label: '申請時間',
      readonly: true,
    },
    relate_files: {
      type: 'file_files',
      label: '相關檔案',
      storageTarget: 'xc_leave_day',
      pocketable: false,
      maxSize: 5 * 1024 * 1024,
    },
  },
  crudSetting: {
    create_my: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'xc_employee',
                'agent_xc_employee',
                'type',
                'start_at',
                'end_at',
                'is_aboard',
                'note',
                'relate_files',
              ],
            },
          ],
        },
      ],
      beforeSubmitAction: async (controller, postData) => {
        if (controller.$moment(postData.start_at).isBefore(postData.end_at)) {
          return true
        } else {
          controller.$store.dispatch('app/setAlert', {
            title: '送出失敗，結束時間不可以早於開始時間，請修改後再次送出',
          })
          return false
        }
      },
    },
  },
};

export default model;
