export default {
  types: [
    {
      text: "Text",
      value: "text",
      extendFields: ["placeholder", "rules"],
    },
    {
      text: "Number",
      value: "number",
    },
    {
      text: "Textarea",
      value: "textarea",
      extendFields: ["placeholder", "rules"],
    },
    {
      text: "Image",
      value: "image",
    },
    {
      text: "Video",
      value: "file_video",
    },
    {
      text: "File",
      value: "file",
    },
    {
      text: "List",
      value: "list",
      extendFields: ["fields", "textKey"],
    },
    {
      text: "Tags",
      value: "tags",
    },
    {
      text: "Editor",
      value: "editor",
    },
    {
      text: "JSON",
      value: "json",
    },
    {
      text: "Select",
      value: "select",
      extendFields: ["items"],
    },
    {
      text: "Boolean",
      value: "switch",
    },
    {
      text: "Color",
      value: "color",
    },
    {
      text: "Intro",
      value: "wasaintro",
    },
    {
      text: "MapJson",
      value: "mapJson",
    },
    {
      text: "Icon",
      value: "icon-picker",
    },
  ]
}