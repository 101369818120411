// import H_xc_employee from '@/__modules/xc_employee/helpers/xc_employee'
import $numeral from 'numeral'

const model = {
  options: {
    // id: true,
    timestamp: true,
  },
  modelName: 'xc_employee',
  label: '人員',
  fields: {
    name: {
      type: 'text',
      label: '中文姓名',
      autofocus: true,
      rules: 'required',
      col: 3,
    },
    name_eng: {
      type: 'text',
      label: '英文姓名(護照)',
      rules: 'required',
      col: 3,
    },
    no: {
      type: 'text',
      label: '員工編號',
      rules: 'required',
      col: 3,
    },
    email: {
      type: 'email',
      label: '個人電子郵件',
      rules: 'email',
      col: 3,
    },
    xc_employee_position: {
      type: 'belongsTo',
      label: '職位',
      modelName: 'xc_employee_position',
      col: 3,
    },
    xc_employee_department: {
      type: 'belongsTo',
      label: '部門',
      modelName: 'xc_employee_department',
      col: 3,
    },
    xc_employee_position_type: {
      type: 'belongsTo',
      label: '職位別',
      modelName: 'xc_employee_position_type',
      col: 3,
      setStateOnInput(state, $event) {
        if (!state) {
          return state
        }
        state.basic_salary = $event.basic_salary
        state.meal_expenses = $event.meal_expenses
        state.work_allowance = $event.work_allowance
        state.transportation_allowance = $event.transportation_allowance
        return state
      },
    },
    xc_employee_type: {
      type: 'belongsTo',
      label: '雇用別',
      modelName: 'xc_employee_type',
      col: 3,
    },
    status: {
      type: 'select',
      label: '職況',
      rules: 'required',
      items: [
        {
          text: '試用期',
          value: "working_new"
        },
        // {
        //   text: '續聘',
        //   value: "working_renew"
        // },
        {
          text: '專任',
          value: "working_full"
        },
        // {
        //   text: '試用',
        //   value: "working_trial"
        // },
        // {
        //   text: '改聘',
        //   value: "working_change"
        // },
        {
          text: '留職停薪',
          value: "leave"
        },
        {
          text: '留職留薪',
          value: "leave_with_pay"
        },
        // {
        //   text: '部門調職',
        //   value: "transfer"
        // },
        {
          text: '離職',
          value: "dismission"
        },
        {
          text: '停職',
          value: "suspension"
        },
        {
          text: '退休',
          value: "retire"
        },
        {
          text: '免職',
          value: "dismiss"
        },
        {
          text: '審查',
          value: "review"
        },
        {
          text: '死亡',
          value: "death"
        },
        {
          text: '其他',
          value: "other"
        },
        // {
        //   text: '升等',
        //   value: "promotion"
        // },
        // {
        //   text: '上線調整',
        //   value: "adjustment"
        // },
      ],
      col: 3
    },
    xc_employee_working_schedule_setting: {
      type: 'belongsTo',
      label: '工作時間設定',
      modelName: 'xc_employee_working_schedule_setting',
      col: 3,
    },
    company_phone: {
      type: 'text',
      label: '公司電話',
      col: 3,
    },
    company_phone_ext: {
      type: 'text',
      label: '分機',
      col: 3,
    },
    id_card_no: {
      type: 'text',
      label: '身份證字號',
      col: 3,
    },
    gender: {
      type: 'radio',
      label: '性別',
      col: 3,
      defaultValue: 'male',
      items: [
        {
          label: '男',
          value: 'male'
        },
        {
          label: '女',
          value: 'female'
        },
        {
          label: '其他',
          value: 'other'
        },
      ]
    },
    nationality: {
      type: 'radio',
      label: '國籍',
      defaultValue: 'local',
      col: 3,
      items: [
        {
          label: '本國人',
          value: 'local',
        },
        {
          label: '外國人',
          value: 'foreign',
        },
      ],
    },
    contact_tel: {
      type: 'text',
      label: '聯絡電話',
      col: 3,
    },
    mobile: {
      type: 'text',
      label: '手機',
      col: 3,
    },
    employee_start_at: {
      type: 'date',
      label: '到職日',
      col: 3,
      rules: 'required',
    },
    employee_end_at: {
      type: 'date',
      label: '離職日',
      col: 3,
    },
    contact_address: {
      // need fields: contact_address_city、contact_address_district、contact_address_zip
      type: 'address-group',
      label: '通訊地址',
      displayLabel: false,
      col: 6,
    },
    contact_address_city: {
      type: 'text',
    },
    contact_address_district: {
      type: 'text',
    },
    contact_address_zip: {
      type: 'text'
    },
    contact_address_detail: {
      type: 'text',
      label: '通訊詳細地址',
      col: 6
    },
    household_address: {
      // need fields: household_address_city、household_address_district、household_address_zip
      type: 'address-group',
      label: '戶籍地址',
      displayLabel: false,
      col: 6,
    },
    household_address_city: {
      type: 'text',
    },
    household_address_district: {
      type: 'text',
    },
    household_address_zip: {
      type: 'text',
    },
    household_address_detail: {
      type: 'text',
      label: '戶籍詳細地址',
      col: 6,
    },
    expertise: {
      type: 'textarea',
      label: '專長',
    },
    // autobiography: {
    //   type: 'textarea',
    //   label: '自傳',
    // },
    // autobiography_eng: {
    //   type: 'textarea',
    //   label: '英文自傳',
    // },
    emergency_contacts: {
      type: 'list',
      label: '緊急聯絡人',
      fields: {
        name: {
          type: 'text',
          label: '姓名',
        },
        relationship: {
          type: 'select',
          label: '關係',
          items: [
            {
              text: '父母',
              value: 'parent',
            },
            {
              text: '配偶',
              value: 'spouse',
            },
            {
              text: '子女',
              value: 'children',
            },
            {
              text: '其他',
              value: 'other',
            }
          ]
        },
        tel: {
          type: 'text',
          label: '聯絡電話',
        }
      }
    },
    family_members: {
      type: 'list',
      label: '家庭狀況',
      fields: {
        name: {
          type: 'text',
          label: '姓名',
        },
        relationship: {
          type: 'select',
          label: '關係',
          items: [
            {
              text: '父母',
              value: 'parent',
            },
            {
              text: '配偶',
              value: 'spouse',
            },
            {
              text: '子女',
              value: 'children',
            },
            {
              text: '其他',
              value: 'other',
            }
          ]
        },
        birth_year: {
          type: 'number',
          label: '出生年',
        }
      }
    },
    educations: {
      type: 'list',
      label: '教育背景',
      fields: {
        school: {
          type: 'text',
          label: '學校',
        },
        department: {
          type: 'text',
          label: '系所',
        },
        degree: {
          type: 'text',
          label: '學位',
        },
        start_at: {
          type: 'date',
          label: '開始時間',
          format: 'YYYY-MM',
          datePickerType: 'month',
          placeholder: '選擇月份'
        },
        end_at: {
          type: 'date',
          label: '結束時間',
          format: 'YYYY-MM',
          datePickerType: 'month',
          placeholder: '選擇月份'
        }
      }
    },
    work_experiences: {
      type: 'list',
      label: '工作經歷',
      fields: {
        company: {
          type: 'text',
          label: '公司/機構',
        },
        department: {
          type: 'text',
          label: '部門'
        },
        position: {
          type: 'text',
          label: '職位'
        },
        start_at: {
          type: 'date',
          label: '開始時間',
          format: 'YYYY-MM',
          datePickerType: 'month',
          placeholder: '選擇月份'
        },
        end_at: {
          type: 'date',
          label: '結束時間',
          format: 'YYYY-MM',
          datePickerType: 'month',
          placeholder: '選擇月份'
        }
      }
    },
    exam_qualifications: {
      type: 'list',
      label: '考試資格',
      fields: {
        name: {
          type: 'text',
          label: '名稱'
        },
        type: {
          type: 'text',
          label: '類型'
        },
        date: {
          type: 'date',
          label: '日期'
        }
      }
    },
    education_trainings: {
      type: 'list',
      label: '教育訓練',
      fields: {
        name: {
          type: 'text',
          label: '名稱'
        },
        start_at: {
          type: 'datetime',
          label: '開始時間'
        },
        end_at: {
          type: 'datetime',
          label: '結束時間'
        },
        organization: {
          type: 'text',
          label: '機構/單位'
        },
        hours: {
          type: 'number',
          label: '時數'
        },
        is_payed: {
          type: 'boolean',
          label: '是否付費'
        }
      }
    },
    publications: {
      type: 'list',
      label: '主要著作',
      fields: {
        name: {
          type: 'text',
          label: '名稱'
        },
        public_at: {
          type: 'date',
          label: '發表時間'
        },
        remark: {
          type: 'text',
          label: '備註'
        }
      }
    },
    articles: {
      type: 'list',
      label: '期刊論文',
      fields: {
        name: {
          type: 'text',
          label: '名稱'
        },
        public_at: {
          type: 'date',
          label: '發表時間'
        },
        remark: {
          type: 'text',
          label: '備註'
        }
      }
    },
    research_projects_internal: {
      type: 'list',
      label: '參與研究計畫(內部)',
      fields: {
        name: {
          type: 'text',
          label: '名稱'
        },
        start_at: {
          type: 'date',
          label: '開始時間'
        },
        end_at: {
          type: 'date',
          label: '結束時間'
        },
        role: {
          type: 'text',
          label: '角色'
        }
      }
    },
    research_projects_external: {
      type: 'list',
      label: '參與研究計畫(外部)',
      fields: {
        name: {
          type: 'text',
          label: '名稱'
        },
        start_at: {
          type: 'date',
          label: '開始時間'
        },
        end_at: {
          type: 'date',
          label: '結束時間'
        },
        role: {
          type: 'text',
          label: '角色'
        },
        organization: {
          type: 'text',
          label: '機構'
        }
      }
    },
    languages: {
      type: 'list',
      label: '語言能力',
      fields: {
        name: {
          type: 'text',
          label: '語言'
        },
        level: {
          type: 'text',
          label: '程度'
        }
      }
    },
    language_exams: {
      type: 'list',
      label: '語言檢成績',
      fields: {
        name: {
          type: 'text',
          label: '語言'
        },
        score: {
          type: 'text',
          label: '分數'
        },
        date: {
          type: 'date',
          label: '日期'
        }
      }
    },
    graduation_diplomas: {
      type: 'file_files',
      label: '畢業證書',
      storageTarget: 'xc_employee'
    },
    graduation_transcripts: {
      type: 'file_files',
      label: '畢業成績單',
      storageTarget: 'xc_employee'
    },
    health_exams: {
      type: 'file_files',
      label: '健康檢查資料',
      storageTarget: 'xc_employee'
    },
    // admin: {
    //   type: 'belongsTo',
    //   label: '連結後台使用者',
    //   textKey: 'name',
    //   modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
    //   stateDisplayCheck(item) {
    //     if (item.create_admin_on_store) {
    //       return false
    //     } else {
    //       return true
    //     }
    //   },
    // },
    // create_admin_on_store: {
    //   type: 'switch',
    //   defaultValue: 1,
    //   label: '同步建立後台帳號',
    // },
    // create_admin_default_password: {
    //   type: 'text',
    //   label: '建立後台帳號的預設密碼',
    //   rules: 'required',
    //   stateDisplayCheck(item) {
    //     if (item.create_admin_on_store) {
    //       return true
    //     } else {
    //       return false
    //     }
    //   },
    // },
    basic_salary: {
      type: 'currency',
      label: '本俸',
      col: 3,
    },
    meal_expenses: {
      type: 'currency',
      label: '伙食費',
      col: 3,
    },
    work_allowance: {
      type: 'currency',
      label: '工作加給',
      col: 3,
      getWarnText: function (value) {
        if (value.xc_employee_position_type) {
          let _checkValue = value.xc_employee_position_type.work_allowance
          let _currentValue = value.work_allowance
          if (_checkValue !== _currentValue) {
            return `${value.xc_employee_position_type.name}工作加給 ${$numeral(_checkValue).format('$0,0')} 與設定不符`
          } else {
            return null
          }
        } else {
          return null
        }
      },
    },
    transportation_allowance: {
      type: 'currency',
      label: '交通津貼',
      col: 3,
    },
    supervisor_allowance: {
      type: 'currency',
      label: '主管加給',
      col: 3,
    },
    fixed_allowance: {
      type: 'currency',
      label: '固定津貼',
      col: 3,
    },
    approved_salary: {
      type: 'currency',
      label: `核定薪資(含交津)`,
      // showInUpdate: true,
      // info: true,
      // getValue: function (value) {
      //   return $numeral(H_xc_employee.getApprovedSalaryFromModel(value)).format('$0,0');
      // },
    },
    approved_salary_without_transportation: {
      type: 'currency',
      label: `核定薪資(不含交津)`,
      // showInUpdate: true,
      // info: true,
      // getValue: function (value) {
      //   return $numeral(H_xc_employee.getApprovedSalaryWithoutTransportationFromModel(value)).format('$0,0');
      // },
    },
    regular_salary: {
      type: 'currency',
      label: '經常性薪資',
    },
    tw_labor_insurance_ref_amount: {
      type: 'currency',
      label: '勞保投保金額',
    },
    tw_labor_insurance_employee: {
      type: 'currency',
      label: '勞保自付額',
    },
    tw_labor_insurance_employer: {
      type: 'currency',
      label: '勞保公司負擔',
    },
    tw_labor_pension_ref_amount: {
      type: 'currency',
      label: '勞退月提繳分級金額',
    },
    tw_labor_pension_employer: {
      type: 'currency',
      label: '勞退公司負擔',
    },
    tw_occupational_accident_insurance_ref_amount: {
      type: 'currency',
      label: '職災保險投保金額',
    },
    tw_occupational_accident_insurance_employer: {
      type: 'currency',
      label: '職災保險公司負擔',
    },
    permanent_resident_obtain_at: {
      type: 'date',
      label: '永久居留權取得日期',
    },
  },
  crudSetting: {
    index: {
      customTableActions: [
        {
          text: '帳號',
          tooltip: '建立後台帳號',
          displayCheck: (item, controller) => {
            if (
              !item.has_admin &&
              controller &&
              controller.$o_o.$h.auth.hasScope(['xc_employee-admin'])
            ) {
              return true
            } else {
              return false
            }
          },
          getUrl: (item) => {
            return `/xc_employee/${item.id}/setadmin`
          }
        },
        {
          text: '動',
          tooltip: '異動',
          displayCheck: (item, controller) => {
            if (
              controller &&
              controller.$o_o.$h.auth.hasScope(['xc_employee-admin', 'xc_employee_hire_under-admin'])
            ) {
              return true
            } else {
              return false
            }
          },
          getUrl: (item) => {
            return `/xc_employee_hire_change/create?xc_employee=${item.id}`
          }
        },
      ],
      creatableScopes: ['xc_employee-admin'],
      updatableScopes: ['xc_employee-admin'],
      deletableScopes: ['xc_employee-admin'],
      titleBarCustomBtns: [
        {
          label: '新聘',
          to: '/xc_employee_hire_new/create',
          displayCheck: (controller) => {
            return controller.$o_o.$h.auth.hasScope(['xc_employee-admin', 'xc_employee_hire_under-admin'])
          }
        },
      ],
      showFields: [
        "no",
        "name",
        "status",
      ],
      inRowBtnRead: true,
      pageMode: true,
    },
    read: {
      titleKey: 'name',
      getFields: (formValue) => {
        let _fields = [
          "name",
          "name_eng",
          "no",
          "email",
          "xc_employee_position",
          "xc_employee_department",
          "xc_employee_position_type",
          "xc_employee_type",
          "status",
          "xc_employee_working_schedule_setting",
          "company_phone",
          "company_phone_ext",
          "id_card_no",
          "gender",
          "nationality",
          "permanent_resident_obtain_at",
          "contact_tel",
          "mobile",
          "employee_start_at",
          "employee_end_at",
          "contact_address",
          "contact_address_detail",
          "household_address",
          "household_address_detail",
          "expertise",
          // "autobiography",
          // "autobiography_eng",
          "emergency_contacts",
          "family_members",
          "educations",
          "work_experiences",
          "exam_qualifications",
          "education_trainings",
          "publications",
          "articles",
          "research_projects_internal",
          "research_projects_external",
          "languages",
          "language_exams",
          "graduation_diplomas",
          "graduation_transcripts",
          "health_exams",
          // "basic_salary",
          // "meal_expenses",
          // "work_allowance",
          // "transportation_allowance",
          // "approved_salary",
          // "approved_salary_without_transportation",
          // "regular_salary",
          // "additional_health_insurance_people_counts",
          // "tw_health_insurance_ref_amount",
          // "tw_health_insurance_employee",
          // "tw_health_insurance_employer",
          // "tw_labor_insurance_ref_amount",
          // "tw_labor_insurance_employee",
          // "tw_labor_insurance_employer",
          // "tw_labor_pension_ref_amount",
          // "tw_labor_pension_employer",
          // "tw_occupational_accident_insurance_ref_amount",
          // "tw_occupational_accident_insurance_employer",
        ]
        if (!formValue?.permanent_resident_obtain_at) {
          _fields = _fields.filter(field =>
            field !== 'permanent_resident_obtain_at'
          )
        }
        return _fields
      },
      updateScopes: ['xc_employee-admin'],
      deleteScopes: ['xc_employee-admin'],
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              getFields: (formValue) => {
                return model.getFieldsFromFormValue(formValue, 'create')
              },
            },
          ],
        },
      ],
    },
    update: {
      titleKey: 'name',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              getFields: (formValue) => {
                return model.getFieldsFromFormValue(formValue, 'update')
              },
            },
          ],
        },
      ],
    }
  },
  getFieldsFromFormValue(formValue, mode) {
    let _fields = []
    if (mode == 'create') {
      _fields = [
        "name",
        "name_eng",
        "no",
        "email",
        "xc_employee_position",
        "xc_employee_position_type",
        "xc_employee_department",
        "xc_employee_type",
        "xc_employee_working_schedule_setting",
        "id_card_no",
        "gender",
        "nationality",
        "permanent_resident_obtain_at",
        "employee_start_at",
        "employee_end_at",
        "status",
        "company_phone",
        "company_phone_ext",
        "native_place",
        "contact_address",
        "contact_address_detail",
        "household_address",
        "household_address_detail",
        "mobile",
        "contact_tel",
        "expertise",
        "research_range",
        "research_field",
        // "autobiography",
        // "autobiography_eng",
        "emergency_contacts",
        "family_members",
        "educations",
        "work_experiences",
        "exam_qualifications",
        "education_trainings",
        "publications",
        "articles",
        "research_projects_internal",
        "research_projects_external",
        "languages",
        "language_exams",
        "graduation_diplomas",
        "graduation_transcripts",
        "health_exams",
        "basic_salary",
        "meal_expenses",
        "work_allowance",
        "transportation_allowance",
        "approved_salary",
        "approved_salary_without_transportation",
        // "create_admin_on_store",
        "create_admin_default_password",
        "admin",
        // "additional_health_insurance_people_counts",
      ]
    } else if (mode == 'update') {
      _fields = [
        "name",
        "name_eng",
        "no",
        "email",
        "xc_employee_position",
        "xc_employee_position_type",
        "xc_employee_department",
        "xc_employee_type",
        "xc_employee_working_schedule_setting",
        "id_card_no",
        "gender",
        "nationality",
        "permanent_resident_obtain_at",
        "employee_start_at",
        "employee_end_at",
        "status",
        "company_phone",
        "company_phone_ext",
        "native_place",
        "contact_address",
        "contact_address_detail",
        "household_address",
        "household_address_detail",
        "mobile",
        "contact_tel",
        "expertise",
        "research_range",
        "research_field",
        // "autobiography",
        // "autobiography_eng",
        "emergency_contacts",
        "family_members",
        "educations",
        "work_experiences",
        "exam_qualifications",
        "education_trainings",
        "publications",
        "articles",
        "research_projects_internal",
        "research_projects_external",
        "languages",
        "language_exams",
        "graduation_diplomas",
        "graduation_transcripts",
        "health_exams",
        "admin",
        // "additional_health_insurance_people_counts",
      ]
    }
    _fields = _fields.filter(field => {
      if (formValue?.nationality === 'foreign') return true
      return field !== 'permanent_resident_obtain_at'
    })
    return _fields
  },
}

export default model