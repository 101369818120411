export default {
  $_getMyBoardComponentFromModules(ctx) {
    let _myBoardComponent = []
    for (const moduleKey in ctx.$config.wsmodule) {
      let res = ctx.$o_o.$h.module.extendModuleHelperAction(
        'my_board',
        `${moduleKey}.js`,
        '$_returnMyBoardComponent',
        {
          ctx: ctx
        }
      )
      if (res) {
        _myBoardComponent = [
          ..._myBoardComponent,
          ...res
        ]
      }
      let res2 = ctx.$o_o.$h.module.extendModuleHelperAction(
        `${moduleKey}`,
        `${moduleKey}.js`,
        '$_returnMyBoardComponent',
        {
          ctx: ctx
        }
      )
      if (res2) {
        _myBoardComponent.push(...res2)
      }
    }
    // Components分靠左靠右
    let _leftComponents = []
    let _rightComponents = []
    _myBoardComponent.forEach(item => {
      if (item.keepLocation === 'left') {
        _leftComponents.push(item)
      } else if (item.keepLocation === 'right') {
        _rightComponents.push(item)
      }
    })
    _leftComponents = this.$_sortedItems(_leftComponents)
    _rightComponents = this.$_sortedItems(_rightComponents)
    return { _leftComponents, _rightComponents }
  },
  $_sortedItems(items) {
    // 排序
    const sortedItems = items.sort((a, b) => {
      if (a.sq === undefined) return 1
      if (b.sq === undefined) return -1
      return a.sq - b.sq
    })
    return sortedItems
  }
}