<template>
  <WsMain class="pb-80">
    <WsState
      type="belongsTo"
      label="Milestone Template Group"
      modelName="xc_milestone_template_group"
      :value="state.xc_milestone_template_group"
      :extendParams="{'index_resource':'2' }"
      @input="$_onInputMilestoneTemplateGroup($event)"
    />
    <WsState
      v-bind="fields.general.name"
      v-model="state.name"
    />
    <WsState
      v-bind="fields.general.xc_project"
      v-model="state.xc_project"
    />
    <WsCard
      class="mt-20"
      v-for="(xc_milestone,xc_milestoneIndex) in state.xc_milestones"
      :key="xc_milestoneIndex"
    >
      <WsFlex>
        <WsStateForm
          :value="xc_milestone"
          :fields="_fields"
          @input="$_onInputMilestone($event, xc_milestoneIndex)"
        ></WsStateForm>
        <WsIconBtn
          @click="$_onRemoveMilestone(xc_milestoneIndex)"
          name="icon-md-delete"
        ></WsIconBtn>
      </WsFlex>
    </WsCard>
    <WsBtn
      class="mt-20"
      @click="$_onMilestoneAdd()"
    >新增Task</WsBtn>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          class="ml-8"
          @click="$_onSubmit()"
        >{{ $t('submit') }}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        general: {
          name: {
            type: "text",
            label: "主名稱",
            hint: "有設定主名稱時，會以 主名稱_ 為milestone開頭，milestone若無建立名稱，會以順位數字作為milestone名稱。",
          },
          xc_project:
            this.$store.state.stone_model.xc_milestone.fields.xc_project,
          // xc_milestone:
          //   this.$store.state.stone_model.xc_task.fields.xc_milestone,
        },
      },
      state: {
        xc_milestones: [],
      },
    };
  },
  computed: {
    _fields() {
      let _fields = {
        name: {
          ...this.$store.state.stone_model.xc_milestone.fields.name,
          rules: "",
        },
        start_at: this.$store.state.stone_model.xc_milestone.fields.start_at,
        end_at: this.$store.state.stone_model.xc_milestone.fields.end_at,
        content: this.$store.state.stone_model.xc_milestone.fields.content,
      };
      return _fields;
    },
  },
  methods: {
    $_onMilestoneAdd() {
      const _milestone = {};
      // if (this.state.xc_milestones.length > 0) {
      // }
      this.state.xc_milestones.push(_milestone);
    },
    $_onRemoveMilestone(xc_milestoneIndex) {
      const _state = { ...this.state };
      _state.xc_milestones.splice(xc_milestoneIndex, 1);
      this.state = _state;
    },
    $_onInputMilestone($event, xc_milestoneIndex) {
      const _state = { ...this.state };
      _state.xc_milestones[xc_milestoneIndex] = $event;
      this.state = _state;
    },
    $_onInputMilestoneTemplateGroup($event) {
      this.state.xc_milestone_template_group = $event;
      if (this.state.xc_milestones.length) {
        return;
      }
      const _xc_milestones = [];
      this.state.xc_milestone_template_group.xc_milestone_templates.forEach(
        (xc_milestone_template) => {
          _xc_milestones.push({
            name: xc_milestone_template.name,
            content: xc_milestone_template.content,
          });
        }
      );
      this.state.xc_milestones = _xc_milestones;
    },
    async $_onSubmit() {
      const _xc_milestones = [];
      this.state.xc_milestones.forEach((xc_milestone) => {
        _xc_milestones.push({
          ...xc_milestone,
          xc_project: this.state.xc_project,
        });
      });
      const _state = {
        ...this.state,
        xc_milestones: _xc_milestones,
      };
      this.$store.dispatch("app/startPageLoading");
      try {
        await this.$axios.post("/xc_milestone/batch", _state);
        this.$router.push("/xc_milestone");
      } catch (error) {
        alert(this.$t(this.$o_o.$h.http.getErrorMessageFromRes(error)));
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
};
</script>

<style>
</style>