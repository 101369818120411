// import config from '@/__config';

const scopes = [
  {
    text: '後台使用者管理者',
    value: 'admin-admin'
  },
]

// if (config.wsmodule.admin.admin_group) {
//   scopes.push({
//     text: '跨管理員管理群組瀏覽',
//     value: 'admin_group_cross-admin',
//   })
// }

export default scopes