<template>
  <div>
    <WsActionApi
      class="width-full"
      :fetchUrl="_fetchUrl"
      @fetch="$_onFetch($event)"
      :params="_params"
      globalLoading
    >
      <WsExcel
        ref="WsExcel"
        :fields="_sheetFeilds"
        :value="_value"
        :readonly="true"
        :exportFilename="_exportFilename"
      ></WsExcel>
    </WsActionApi>
    <WsFixedIconBtns :btns="btns"></WsFixedIconBtns>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modeldatas: [],
      btns: [
        {
          icon: "icon-md-print",
          text: "匯出",
          size: 60,
          onClick: () => {
            this.$_print();
          },
        },
      ],
    };
  },
  computed: {
    _exportFilename() {
      if (this.getExportFilenamae) {
        return this.getExportFilenamae(this.$route.query, this);
      } else if (this.exportFileName) {
        return this.exportFileName;
      } else {
        return this.modelName;
      }
    },
    _params() {
      let _params = {
        ...this.params,
        getall: 1,
      };
      return _params;
    },
    _value() {
      if (this.getValue) {
        return this.getValue(this.modeldatas, this);
      } else {
        return this.modeldatas;
      }
    },
    _fetchUrl() {
      let _paramString = "";
      for (const key in this.$route.query) {
        if (_paramString === "") {
          _paramString += `?${key}=${this.$route.query[key]}`;
        } else {
          _paramString += `&${key}=${this.$route.query[key]}`;
        }
      }
      if (this.$route.query.start && this.$route.query.end) {
        return `/${this.modelName}/index/date${_paramString}`;
      } else if (this.fetchUrl) {
        return `/${this.fetchUrl}${_paramString}`;
      } else {
        return `/${this.modelName}${_paramString}`;
      }
    },
    _sheetFeilds() {
      if (this.sheetFeilds) {
        return this.sheetFeilds;
      } else {
        return this.$store.state.stone_model[this.modelName].fields;
      }
    },
  },
  methods: {
    $_print() {
      this.$refs.WsExcel.exportExcel();
    },
    $_onFetch($event) {
      if ($event.meta) {
        this.modeldatas = $event.data;
      } else {
        this.modeldatas = $event;
      }
    },
  },
  props: {
    sheetFeilds: {},
    getValue: {},
    exportFileName: {},
    getExportFilenamae: {},
    modelName: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
    fetchUrl: String,
  },
};
</script>

<style>
</style>