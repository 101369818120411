<template>
  <div
    ref="WsInfoJson"
    class="ws-info-json"
  >
  </div>
</template>

<script>
import JsonEditor from "jsoneditor";
import "jsoneditor/dist/jsoneditor.min.css";
export default {
  data() {
    return {
      editor: null,
    };
  },
  props: {
    value: {},
  },
  watch: {
    value: {
      handler() {
        this.editor.set(this.value);
      },
      immediate: true,
    },
  },
  methods: {
    $_onChange() {
      try {
        const _value = this.editor.get();
        this.$emit("input", _value);
      } catch (error) {
        this.$emit("valid-is-false");
        return error;
      }
    },
    $_init() {
      this.editor = new JsonEditor(this.$refs.WsInfoJson, {
        mode: "view",
        mainMenuBar: false, // 移除上方選單
      });
      if (this.value) {
        this.editor.set(this.value);
      }
    },
  },
  mounted() {
    this.$_init();
  },
  components: {},
};
</script>