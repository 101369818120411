const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: 'wsx_horcrux',
  label: 'WSX 運作項目',
  fields: {
    name: {
      type: 'text',
      label: '名稱',
    },
    code_source: {
      type: 'radio',
      label: '程式碼來源',
      items: [
        {
          value: 'cms_api',
          label: 'CMS API'
        },
        {
          value: 'client_api',
          label: 'Client API'
        },
        {
          value: 'cms',
          label: 'CMS'
        },
        {
          value: 'web',
          label: 'Web'
        },
      ]
    },
    environment: {
      type: 'radio',
      label: '環境',
      items: [
        {
          value: 'uat',
          label: 'UAT'
        },
        {
          value: 'prd',
          label: 'PRD'
        },
      ]
    },
    app_url: {
      type: 'link',
      label: '網址',
    },
    note: {
      type: 'text',
      label: '備註',
    },
    git_url: {
      type: 'link',
      label: 'Git 網址',
    },
    client_token: {
      type: 'text',
      label: 'Client Token'
    },
    center_token: {
      type: 'text',
      label: 'Center Token'
    },
  },
  crudSetting: {
    index: {
      showFields: [
        "name",
        "code_source",
        "environment",
        "app_url",
        "note",
        "git_url",
      ],
      inRowBtnRead: true,
      pageMode: true,
    },
    read: {
      titleKey: 'name',
      cmsLog: true,
      leftFields: [
        "name",
        "code_source",
        "environment",
        "app_url",
        "note",
        "git_url",
        "client_token",
        "center_token",
      ],
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "name",
                "code_source",
                "environment",
                "app_url",
                "note",
                "git_url",
                "client_token",
                "center_token",
              ],
            },
          ],
        },
      ],
    },
    update: {
      titleKey: 'name',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "name",
                "code_source",
                "environment",
                "app_url",
                "note",
                "git_url",
                "client_token",
                "center_token",
              ],
            },
          ],
        },
      ],
    },
  }
}

export default model