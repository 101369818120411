<template>
  <WsMain>
    <WsModelMyIndex
      ref="WsModelMyIndex"
      :modelName="$store.state.stone_model.xc_leave_day.modelName"
      label="我的請假單"
      :fields="_fields"
      :inRowBtnRead="true"
      :updatable="false"
      :deletable="false"
      :dialogCreate="false"
      :pageMode="true"
      :showFields="showFields"
      :customTableActions="customTableActions"
      :dialogRead="true"
      :readable="true"
      createUrl="/my/leave_day/create"
      @item-cancel="$_onCancel($event)"
      @item-withdraw="$_onWithdraw($event)"
    >
      <template #createDialogContentTop>
        <WsStateLabel label="填表人"></WsStateLabel>
        <WsText class="mt-8">{{ _createEmployeeName }}</WsText>
      </template>
    </WsModelMyIndex>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      showFields: [
        "type",
        "xc_employee",
        "agent_xc_employee",
        "created_at",
        "start_at",
        "end_at",
        "days",
        "hours",
        "is_aboard",
        "status",
      ],
      customTableActions: [
        {
          text: "收回",
          onClick: (controller, modeldata, id, updateModeldata) => {
            controller.$store.dispatch("app/setAlert", {
              title: "確定收回？",
              description: "",
              action: async () => {
                try {
                  controller.$store.dispatch("app/startPageLoading");
                  await controller.$axios.post(
                    `my/xc_leave_day/${id}/withdraw`
                  );
                  controller.$store.dispatch("app/addSnack", "收回成功");
                  updateModeldata({
                    ...modeldata,
                    status: "withdrawn",
                  });
                } catch (error) {
                  console.log("error", error);
                  alert(
                    controller.$t(
                      controller.$o_o.$h.http.getErrorMessageFromRes(error)
                    )
                  );
                } finally {
                  controller.$store.dispatch("app/stopPageLoading");
                }
              },
            });
          },
          displayCheck(item) {
            return item.status == "processing";
          },
        },
      ],
    };
  },
  methods: {
    async $_onCancel($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_leave_day.my_cancel($event.item.id);
        this.$refs.WsModelMyIndex.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "canceled",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onWithdraw($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_leave_day.my_withdraw($event.item.id);
        this.$refs.WsModelMyIndex.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "withdrawn",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _createEmployeeName() {
      if (this.$store.state.auth.currentUser.xc_employee) {
        return this.$store.state.auth.currentUser.xc_employee.name;
      } else {
        this.$store.state.auth.currentUser.name;
      }
      return "";
    },
    _fields() {
      const _fields = this.$o_o.$h.model.getFieldsByKeys(
        this.$store.state.stone_model.xc_leave_day.fields,
        [
          "xc_employee",
          "agent_xc_employee",
          "type",
          "created_at",
          "start_at",
          "end_at",
          "status",
          "days",
          "hours",
          "is_aboard",
          "note",
          "relate_files",
        ]
      );

      if (this.$store.state.auth.currentUser.xc_employee) {
        _fields.xc_employee.defaultValue =
          this.$store.state.auth.currentUser.xc_employee;
      }

      return _fields;
    },
  },
};
</script>

<style>
</style>