import H_Router from '@/__stone/helpers/router'

const routes = [
  ...H_Router.getCrudTemplateRoutes({
    routes: [
      'index',
      'read',
      'create',
      // 'update',
    ],
    modelName: 'xc_work_overtime_settlement',
    middleware: 'auth',
    meta: {
      requiredScopes: ['xc_work_overtime-admin'],
    }
  }),
  {
    path: '/my/xc_work_overtime',
    name: 'my_xc_work_overtime',
    component: () => import('@/__modules/xc_work_overtime/views/MyXcWorkOvertime/Index.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  ...H_Router.getCrudTemplateRoutes({
    routes: [
      'index',
      'create_my',
    ],
    modelName: 'xc_work_overtime',
    middleware: 'auth',
  }),
  // {
  //   path: '/xc_work_overtime',
  //   name: 'xc_work_overtime',
  //   component: () => import('@/__modules/xc_work_overtime/views/XcWorkOvertime/Index.vue'),
  //   meta: {
  //     middleware: 'auth',
  //     // requiredScopes: ['xc_work_overtime-admin'],
  //   }
  // },
  {
    path: '/xc_work_overtime_pay_search',
    name: 'xc_work_overtime_pay_search',
    component: () => import('@/__modules/xc_work_overtime/views/XcWorkOvertime/PaySearch.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['xc_work_overtime-admin'],
    }
  },
  {
    path: '/print/xc_work_overtime_pay',
    name: 'print_xc_work_overtime_pay',
    component: () => import('@/__modules/xc_work_overtime/views/XcWorkOvertime/PayPrint.vue'),
    meta: {
      template: 'print',
      middleware: 'auth',
      requiredScopes: [
        'xc_work_overtime-admin',
      ],
    }
  },
  {
    path: '/xc_work_overtime_search',
    name: 'xc_work_overtime_search',
    component: () => import('@/__modules/xc_work_overtime/views/XcWorkOvertime/Search.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['xc_work_overtime-admin'],
    }
  },
  {
    path: '/print/xc_work_overtime',
    name: 'print_xc_work_overtime',
    component: () => import('@/__modules/xc_work_overtime/views/XcWorkOvertime/Print.vue'),
    meta: {
      template: 'print',
      middleware: 'auth',
      requiredScopes: [
        'xc_work_overtime-admin',
      ],
    }
  },
]
export default routes