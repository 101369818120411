export default {
  xc_milestone: {
    icon: 'icon-md-date-available',
    title: 'Milestone',
    link: '/xc_milestone',
    scopes: ['xc_milestone-admin'],
  },
  xc_milestone_template: {
    icon: 'icon-md-date-available',
    title: 'Milestone Template',
    link: '/xc_milestone_template',
    scopes: ['xc_milestone-admin'],
  },
  xc_milestone_template_group: {
    icon: 'icon-md-chrome-reader-mode',
    title: 'Milestone Template Group',
    link: '/xc_milestone_template_group',
    scopes: ['xc_milestone-admin'],
  },
}