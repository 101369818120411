import H_color from '@/__stone/helpers/color'
import H_time from '@/__stone/helpers/time'
import model from '@/__modules/xc_leave_day/models/xc_leave_day.js'

const cmsColors = H_color.getCmsColors()

export default {
  toCalendarEvent(
    xc_leave_day,
    additionalOnDays,
    additionalOffDays,
  ) {
    const _event = {}
    if (!xc_leave_day) {
      return
    }
    let skipWeekDays = [0, 6]
    _event.title = "[ 請假 ] " + xc_leave_day.xc_employee.name
    _event.color = H_color.hexToRgb(xc_leave_day.xc_employee.color ? xc_leave_day.xc_employee.color : cmsColors.gray, .3)
    _event.extendedProps = {
      ...xc_leave_day,
      modelName: 'xc_leave_day'
    }
    _event.start = xc_leave_day.start_date
    _event.end = H_time.getEndDateFromStartDate(
      xc_leave_day.start_date,
      xc_leave_day.days,
      additionalOnDays,
      additionalOffDays,
      skipWeekDays
    )
    _event.allDay = true
    _event.editable = false
    return _event
  },
  $_getStatus(status) {
    let items = model.fields.status.items
    let result = items.find(item => item.value === status)
    return (result ? result.text : '-')
  },
}