<template>
  <WsMain>
    <WsReadSection
      ref="WsReadSection"
      v-bind="$store.state.stone_model.xc_milestone"
      :leftFields="_leftFields"
      @input="$_onInput($event)"
    >
    </WsReadSection>
    <div
      v-if="modeldata?.testing_cases.length"
      class="mt-40"
    >
      <WsDes>測試步驟</WsDes>
      <TestingCasesSection
        :testing_cases="modeldata.testing_cases"
        :testing_records="modeldata.testing_records"
      ></TestingCasesSection>
    </div>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      modeldata: null,
      leftFields: [
        "name",
        "xc_project",
        "start_at",
        "end_at",
        "creator",
        "content",
      ],
    };
  },
  computed: {
    _leftFields() {
      let _leftFields = [
        "name",
        "xc_project",
        "start_at",
        "end_at",
        "creator",
        "content",
      ];

      return this.$o_o.$h.module.extendModuleHelperAction(
        "xc_milestone",
        "xc_milestone.js",
        "getXcMilestoneReadLeftFields",
        {
          leftFields: _leftFields,
        }
      );
    },
  },
  methods: {
    $_onInput($event) {
      this.modeldata = $event;
    },
  },
};
</script>

<style>
</style>