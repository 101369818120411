<template>
  <div>
    <WsMain>
      <ValidationObserver ref="form">
        <WsStateForm
          v-model="state"
          :fields="fields"
        />
      </ValidationObserver>
      <WsBtn
        class="mt-24"
        @click="$_onSubmit()"
      >{{ $t('submit') }}</WsBtn>
    </WsMain>
    <WsLoading v-if="loading" />
    <WsExcel
      v-if="personnel_cost_calc"
      :fields="_fields"
      :value="_value"
      :readonly="true"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      personnel_cost_calc: null,
      xc_project: null,
      loading: false,
      state: {},
      fields: {
        xc_project: {
          type: 'belongsTo',
          label: '計畫',
          textKey: 'name',
          modelName: 'xc_project',
          rules: 'required',
        },
        date: {
          type: 'date',
          label: '查看日期',
        },
      },
    }
  },
  methods: {
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate()
      if (!isValidate) return
      try {
        this.loading = true
        this.personnel_cost_calc = null
        this.$router.replace({
          query: {
            xc_project: this.state.xc_project.id,
            date: this.state.date,
          }
        })
        if (this.xc_project?.id !== this.state.xc_project.id) {
          const res = await this.$axios.get(`/xc_project/${this.state.xc_project.id}`)
          this.xc_project = res.data.data
        }
        const res = await this.$axios.get(`xc_project/${this.state.xc_project.id}/personnel_cost_calc`, {
          params: {
            date: this.state.date
          },
        })
        this.personnel_cost_calc = res.data
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    $_getLink(content, record) {
      if (!this.$o_o.$h.str.isUUID(record.$id)) {
        return
      }
      this.$router.push(
        `/xc_employee/calc/cost?xc_employee=${
          record.$id
        }&start_at=${this.$moment(this.xc_project.start_at).format(
          'YYYY-MM-DD'
        )}&end_at=${this.$moment(this.xc_project.end_at).format('YYYY-MM-DD')}`
      )
    },
  },
  computed: {
    _value() {
      if (!this.personnel_cost_calc) {
        return []
      }
      const _value = []
      for (const member_cost_detail of this.personnel_cost_calc
        .member_cost_details) {
        _value.push({
          $id: member_cost_detail.xc_employee_id,
          name: member_cost_detail.name,
          employee_cost: member_cost_detail.employee_cost,
          performance_bonus: member_cost_detail.performance_bonus,
          employee_cost_total: member_cost_detail.employee_cost_total,
          employeed_month: member_cost_detail.employeed_month,
          monthly_cost_to_salary_ratio:
            member_cost_detail.monthly_cost_to_salary_ratio,
          member_month_days_amount: member_cost_detail.member_month_days_amount,
          xc_project_cost: member_cost_detail.xc_project_cost,
          contract_member_salary: member_cost_detail.contract_member_salary,
          tw_health_insurance_amount_total:
            member_cost_detail.tw_health_insurance_amount_total,
          tw_health_insurance_amount_xc_project:
            member_cost_detail.tw_health_insurance_amount_xc_project,
        })
      }
      _value.push({
        name: '合計',
        employee_cost: this.personnel_cost_calc.total_employee_cost,
        performance_bonus: this.personnel_cost_calc.total_performance_bonus,
        employeed_month: this.personnel_cost_calc.total_employeed_month,
        employee_cost_total: this.personnel_cost_calc.total_employee_cost_total,
        monthly_cost_to_salary_ratio:
          this.personnel_cost_calc.total_monthly_cost_to_salary_ratio,
        member_month_days_amount:
          this.personnel_cost_calc.total_member_month_days_amount,
        xc_project_cost: this.personnel_cost_calc.total_xc_project_cost,
        contract_member_salary: this.personnel_cost_calc.contract_member_salary,
        contract_member_salary_diff:
          this.personnel_cost_calc.contract_member_salary_diff,
        tw_health_insurance_amount:
          this.personnel_cost_calc.total_tw_health_insurance_amount,
        tw_health_insurance_amount_total:
          this.personnel_cost_calc.total_tw_health_insurance_amount_total,
        tw_health_insurance_amount_xc_project:
          this.personnel_cost_calc.total_tw_health_insurance_amount_xc_project,
      })
      return _value
    },
    _fields() {
      const _fields = {
        name: {
          label: '姓名',
          type: 'string',
          editable: false,
        },
        employee_cost: {
          label: '院方人事成本',
          type: 'string',
          editable: false,
          width: 100,
          link: this.$_getLink,
        },
        performance_bonus: {
          label: '績效',
          type: 'string',
          editable: false,
        },
        employee_cost_total: {
          label: '院方人事成本含績效',
          type: 'string',
          editable: false,
          width: 180,
        },
        employeed_month: {
          label: '院方月份',
          type: 'string',
          editable: false,
        },
        monthly_cost_to_salary_ratio: {
          label: '每月成本薪資率',
          type: 'string',
          editable: false,
          width: 150,
        },
        member_month_days_amount: {
          label: '計畫人月',
          type: 'string',
          editable: false,
        },
        xc_project_cost: {
          label: '計畫人事成本',
          type: 'string',
          editable: false,
          width: 150,
        },
        contract_member_salary: {
          label: '合約報支人事費',
          type: 'string',
          editable: false,
          width: 150,
        },
        contract_member_salary_diff: {
          label: '合約報支人事費差額',
          type: 'string',
          editable: false,
          width: 150,
        },
        tw_health_insurance_amount_total: {
          label: '二代健保',
          type: 'string',
          editable: false,
        },
        tw_health_insurance_amount_xc_project: {
          label: '二代健保-計畫人事成本',
          type: 'string',
          editable: false,
          width: 180,
        },
      }
      return _fields
    },
  },
}
</script>
