<template>
  <div class="ws-excel">
    <WsFlex
      v-if="_displayControls"
      :gap="12"
      class="mb-12"
    >
      <WsBtn
        v-if="exportable"
        @click="exportExcel()"
      >匯出</WsBtn>
      <WsBtn
        v-if="importable"
        @click="importExcel()"
      >匯入</WsBtn>
      <WsBtn
        v-if="clearable"
        @click="clear()"
      >清除</WsBtn>
    </WsFlex>
    <vue-excel-editor
      ref="VueExcelEditor"
      class="width-full"
      :value="value"
      @update="$_onUpdate"
      :new-if-bottom="true"
      :free-select="true"
      @import="$_onImport"
      :readonly-style="{
          'background-color': 'var(--white1d)',
        }"
    >
      <vue-excel-column
        v-for="column in _excelColumns"
        :key="column.field"
        v-bind="column"
        :readonly="readonly"
      />
    </vue-excel-editor>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    _displayControls() {
      return this.exportable || this.importable || this.clearable;
    },
    _showFields() {
      if (this.showFields) {
        return this.showFields;
      } else {
        return Object.keys(this.fields);
      }
    },
    _excelColumns() {
      const _excelColumns = [];
      for (const fieldKey of this._showFields) {
        const _field = this.fields[fieldKey];
        _excelColumns.push({
          field: fieldKey,
          label: _field.label,
          type: this.$_getTypeFromField(_field),
          width: this.$_getWidthFromField(_field),
          editable: _field.editable !== undefined ? _field.editable : true,
          readonly: _field.editable !== undefined ? !_field.editable : false,
          toText: _field.toText,
          className: _field.className,
          link: _field.link,
          "text-align": _field.textAlign,
        });
      }
      return _excelColumns;
    },
  },
  methods: {
    $_onImport() {},
    clear() {
      this.$emit("input", []);
    },
    importExcel() {
      this.$refs.VueExcelEditor.importTable();
    },
    exportExcel() {
      const format = "xlsx";
      const exportSelectedOnly = false;
      this.$refs.VueExcelEditor.exportTable(
        format,
        exportSelectedOnly,
        this.exportFilename
      );
    },
    $_onUpdate($event) {
      const _value = this.value ? [...this.value] : [];
      $event.forEach((eventItem) => {
        const rowIndex = _value.findIndex((row) => row.$id === eventItem.$id);
        if (rowIndex !== -1) {
          const field = eventItem.field.name;
          _value[rowIndex][field] = eventItem.newVal;
        }
      });
      this.$emit("input", _value);
    },
    $_getValueFromField(value) {
      // if (field.type === "number") {
      //   return value ? value : 0;
      // } else {
      return value;
      // }
    },
    $_getWidthFromField(field) {
      if (field.width) {
        return `${field.width}px`;
      } else {
        return "80px";
      }
    },
    $_getTypeFromField(field) {
      switch (field.type) {
        case "string":
          return "string";
        case "number":
          return "number";
        default:
          return "string";
      }
    },
  },
  props: {
    fields: {},
    showFields: {},
    value: {},
    newIfBottom: Boolean,
    exportFilename: String,
    exportable: Boolean,
    importable: Boolean,
    clearable: Boolean,
    readonly: Boolean,
  },
  watch: {
    value: {
      handler: function () {
        this.$refs.VueExcelEditor?.clearAllSelected();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.ws-excel {
  width: 100%;
  .component-content {
    .table-col-header {
      color: var(--gray5d);
    }
    .readonly {
      color: var(--gray5d);
      background-color: var(--white4d);
    }
    .input-box {
      color: var(--black2l);
    }
  }
  .systable tbody td {
    color: var(--black2l);
  }
}
</style>