<template>
  <WsMain>
    <div v-if="multiple">
      <div v-if="urls && urls.length">
        <div
          v-for="(urlsItem, urlsItemIndex) in urls"
          :key="urlsItemIndex"
          class="ws-file-state-image"
        >
          <img
            v-lazy="urlsItem"
            alt=""
            class="mb-10"
            :style="`max-width:200px;max-height:200px`"
          />
          <WsIconBtn
            @click="$_onClear(urlsItemIndex)"
            name="icon-md-delete"
            class="remove-btn-style"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <WsImg
        v-if="url"
        :url="url"
        alt=""
        @click="$_onUploadClick()"
        :wireBackground="true"
      />
    </div>
    <div>
      <WsBtn @click="$_onUploadClick()">{{ $t('upload_image') }}</WsBtn>
    </div>
    <input
      v-show="false"
      @change="$_onChange($event)"
      type="file"
      ref="fileInput"
      accept="image/*"
      :multiple="multiple"
    >
  </WsMain>
</template>

<script>
// import servicePocketImage from "@/__stone/service/api/v1/pocket_image";
export default {
  data: () => ({
    file: null,
    url: null,
    files: [],
    urls: [],
  }),

  methods: {
    $_onClear(urlIndex) {
      this.urls.splice(urlIndex, 1)
    },
    async $_onChange($event) {
      if (this.multiple) {
        this.files = $event.target.files
        const res = await Promise.all(Array.prototype.map.call($event.target.files, this.$o_o.$h.file.getBase64FromFile))
        this.urls = [
          ...this.urls,
          ...res
        ]
      } else {
        this.file = $event.target.files[0];
        this.url = await this.$o_o.$h.file.getBase64FromFile(this.file);
      }
    },
    $_onUploadClick() {
      this.$refs.fileInput.click();
    },
    submit() {
      return new Promise((resolve, reject) => {
        if (this.multiple) {
          const tasks = []
          for (let i = 0; i < this.files.length; i++) {
            tasks.push(this.$o_o.$s._m.file_system_image
              .upload(
                this.files[i],
                this.storageTarget,
                this.signed,
              ))
          }
          Promise.all(tasks)
            .then((res) => {
              this.$emit("submit", res);
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          this.$o_o.$s._m.file_system_image
            .upload(
              this.file,
              this.storageTarget,
              this.signed,
            )
            .then((res) => {
              this.$emit("submit", res);
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },
  },

  props: {
    signed: {
      type: Boolean,
      default: false,
    },
    storageTarget: {},
    multiple: {},
  },
};
</script>

<style></style>