export default {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "xc_milestone_template",
  label: "Milestone Template",
  fields: {
    name: {
      type: 'text',
      label: '名稱',
      labelInLocale: true,
      rules: 'required',
      col: 3,
    },
    content: {
      type: 'textarea',
      label: '內容',
    },
    working_days: {
      type: 'number',
      label: '工作天數',
      col: 3,
    },
  },
  crudSetting: {
    index: {
      showFields: ['name'],
      creatable: true,
      updatable: true,
      deletable: true,
      pageMode: true,
    },
    create: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ['name', 'working_days', 'content'],
            },
          ],
        },
      ],
    },
    update: {
      submitToIndex: true,
      titleKey: 'name',
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ['name', 'working_days', 'content'],
            },
          ],
        },
      ],
    },
    read: {
      leftFields: ['name', 'working_days', 'content'],
      titleKey: 'name',
    },
  }
}