<template>
  <WsMain v-if="modelData">
    <WsBreadcrumbs
      v-if="hasBreadcrumbs&&!this.getUrl"
      :menu="_breadcrumbsItems"
    ></WsBreadcrumbs>
    <slot name="beforeContent"></slot>
    <WsFlex
      class="ws-content-section pb-80"
      flexDirection="column"
    >
      <WsTabs
        v-if="_tabsDisplay"
        v-model="tabValue"
        :items="tabs"
        class="mb-10"
        scrollToAnchor
        autoInit
      />
      <ValidationObserver
        ref="form"
        class="ws-content-section-observer"
      >
        <form
          ref="updateForm"
          @submit.prevent
        >
          <div class="ws-content-section pb-80">
            <slot
              name="primaryContent"
              :value="_value"
              :fields="fields"
            >
              <div
                v-if="primary"
                class="ws-content-section__primary"
              >
                <WsStateContentBlock
                  v-for="(item,itemKey) in primary"
                  :key="itemKey"
                  :modelData="modelData"
                  :type="item.type"
                  :label="item.label"
                  :title="item.title"
                  :remark="item.remark"
                  :floors="item.floors"
                  :modedFields="fields"
                  :fields="item.fields"
                  :field="item.field"
                  :modelName="item.modelName"
                  :modeVersions="item.modeVersions"
                  :modeParent="item.modeParent"
                  :customButtons="item.customButtons"
                  :fieldsRead="item.fieldsRead"
                  :errorMessages="errorMessages"
                  :value="_value"
                  @input="$_onStateContentBlockInput($event)"
                  :parentModelName="$_getParentModelName(item)"
                  @error-popup-active="$_onErrorPopupActive($event)"
                  @form-input="$_formInput"
                ></WsStateContentBlock>
              </div>
            </slot>
            <slot name="secondaryContent">
              <div
                v-if="_secondary.length"
                class="ws-content-section__secondary"
              >
                <WsStateContentBlock
                  v-for="(item,itemKey) in _secondary"
                  :key="itemKey"
                  :unifyCol="12"
                  :modelData="modelData"
                  :type="item.type"
                  :label="item.label"
                  :title="item.title"
                  :remark="item.remark"
                  :floors="item.floors"
                  :modedFields="fields"
                  :fields="item.fields"
                  :field="item.field"
                  :modelName="item.modelName"
                  :modeVersions="item.modeVersions"
                  :modeParent="item.modeParent"
                  :customButtons="item.customButtons"
                  :fieldsRead="item.fieldsRead"
                  :errorMessages="errorMessages"
                  :value="_value"
                  @input="$_onStateContentBlockInput($event)"
                  :parentModelName="$_getParentModelName(item)"
                  @error-popup-active="$_onErrorPopupActive($event)"
                ></WsStateContentBlock>
              </div>
            </slot>
          </div>
        </form>
      </ValidationObserver>
    </WsFlex>
    <slot></slot>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          @click="$router.go(-1)"
          outlined
        >{{$t('cancel')}}</WsBtn>
        <WsBtn
          class="ml-8"
          :loading="loading.submit"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
import S_APP_General from "@/__stone/service/app/general";

export default {
  data: () => ({
    isMounted: false,
    modelData: null,
    loading: {
      submit: false,
    },
    state: {},
    errorMessages: {},
    tabValue: null,
  }),
  methods: {
    $_onStateContentBlockInput($event) {
      this.$store.dispatch("app/startNeedSave");
      if (this.emitInput) {
        const _value = {
          ...this.value,
          ...$event,
        };
        this.$emit("input", _value);
      } else {
        const _value = {
          ...this.state,
          ...$event,
        };
        this.state = _value;
      }
    },
    loadingStart() {
      this.loading.submit = true;
    },
    loadingStop() {
      this.loading.submit = false;
    },
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      if (this.emitSubmit) {
        if (this.emitInput) {
          this.$emit("submit", this.value);
        } else {
          this.$emit("submit", this.state);
        }
        return;
      }
      this.loading.submit = true;
      try {
        let _value;
        if (this.emitInput) {
          _value = this.value;
        } else {
          _value = this.state;
        }
        let postData = this.$o_o.$h.model.getFormatedStates(
          this.fields,
          _value,
          this.postEncode
        );
        if (this.extendPostData) {
          postData = {
            ...postData,
            ...this.extendPostData,
          };
        }
        const res = await this.$axios.patch(this._patchUrl, postData);
        for (const key in this.fields) {
          if (this.fields[key].sortApiName) {
            await this.patchSortData(key, _value, res.data.data?.id);
          }
        }
        this.$store.dispatch("app/stopNeedSave");
        this.loading.submit = false;
        this.$_onComplete();
      } catch (error) {
        this.$emit("error");
        this.errorMessages = this.$o_o.$h.http.getErrorMessageFromRes(error);
        this.loading.submit = false;
      }
    },
    async patchSortData(key, value, id) {
      try {
        const _apiName = this.fields[key].sortApiName
          ? this.fields[key].sortApiName
          : key;
        let orderPostData = {};
        orderPostData.order = value[key].map((item) => {
          return { id: item.id };
        });
        const _orderPatchUrl = `/${this.modelName}/${id}/${_apiName}/order`;
        await this.$axios.patch(_orderPatchUrl, orderPostData);
      } catch (error) {
        console.error(error);
      }
    },
    $_onComplete() {
      this.$emit("complete");
      if (this.completeRedirect) {
        if (this.getCompleteRedirectUrl) {
          this.$router.push(this.getCompleteRedirectUrl(this._id));
        } else if (this.$route.query && this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect);
        } else if (this.returnModelName) {
          this.$router.push(`/${this.returnModelName}/${this._id}`);
        } else if (this.submitToIndex) {
          this.$router.push(`/${this._urlModelName}`);
        } else {
          this.$router.push(`/${this._urlModelName}/${this._id}`);
        }
      } else {
        this.$store.dispatch("app/addSnack", "Update Success.");
      }
    },
    $_getParentModelName(primaryItem) {
      if (primaryItem.modeVersions) {
        return `${this.modelName}_version`;
      } else {
        return this.modelName;
      }
    },
    async $_fetchData() {
      this.$store.dispatch("app/startPageLoading");
      try {
        const res = await this.$axios.get(this._getUrl, {
            params: {
              ...this.extendParams
            }
          }
        );
        this.modelData = res.data.data;
        if (this.emitInput) {
          this.$emit("input", {
            ...this.$o_o.$h.state.getValueFromFields(
              this.fields,
              this.modelData
            ),
          });
        } else {
          this.state = {
            ...this.$o_o.$h.state.getValueFromFields(
              this.fields,
              this.modelData
            ),
          };
        }
        this.$emit("modelDataInit", this.modelData);
        setTimeout(() => {
          this.isMounted = true;
        }, 500);
      } catch (error) {
        alert(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onErrorPopupActive($event) {
      if ($event === false) {
        this.errorMessages = "";
      }
    },
    $_keydownHandler($event) {
      if ($event.metaKey && $event.key == "Enter") {
        this.$_onSubmit($event);
      }
      if ($event.metaKey && $event.code == "Escape") {
        this.$router.go(-1);
      }
    },
    $_formInput($event) {
      if (this.emitFormInput) {
        this.$emit("form-input", $event);
      } else {
        this.state = {
          ...this.state,
          ...$event,
        };
      }
    },
  },
  computed: {
    _id() {
      if (this.id) {
        return this.id;
      } else {
        return this._paramsId;
      }
    },
    _paramsId() {
      return this.$route.params.id;
    },
    _patchUrl() {
      if (this.patchUrl) {
        return this.patchUrl;
      } else {
        return this._getUrl;
      }
    },
    _getUrl() {
      if (this.getUrl) {
        return this.getUrl;
      } else {
        return `/${this.modelName}/${this._id}`;
      }
    },
    _secondary() {
      const _secondary = this.secondary ? [...this.secondary] : [];
      if (
        this.$o_o.$h.auth.hasScope(["site_region_cross-admin"]) &&
        this.fields.site_region
      ) {
        if (!_secondary.length || _secondary[0].type != "stateCard") {
          _secondary.unshift({
            type: "stateCard",
            floors: [],
          });
          if (!_secondary[0].floors.length) {
            _secondary[0].floors.push({
              fields: [],
            });
          }
          if (!_secondary[0].floors[0].fields) {
            _secondary[0].floors[0].fields = [];
          }
        }
        _secondary[0].floors[0].fields.push("site_region");
      }
      return _secondary;
    },
    _value() {
      if (this.emitInput) {
        return this.value;
      } else {
        return this.state;
      }
    },
    _title() {
      if (!this.modelData) {
        return this.label;
      } else {
        return S_APP_General.getValueByFieldKey(this.titleKey, this.modelData);
      }
    },
    _breadcrumbsItems() {
      const _breadcrumbsItems = [];
      if (this.breadcrumbsItems) {
        return this.breadcrumbsItems;
      }
      _breadcrumbsItems.push({
        text: this.$t(this.label),
        to: `/${this._urlModelName}`,
        disabled: false,
      });
      _breadcrumbsItems.push({
        text: this._title,
        to: `/${this._urlModelName}/${this._id}`,
        disabled: false,
      });
      if (this.titleKey && this.modelData && this.modelData[this.titleKey]) {
        _breadcrumbsItems.push({
          text: `${this.$t("edit")} ${this.modelData[this.titleKey]}`,
          disabled: true,
        });
      } else {
        _breadcrumbsItems.push({
          text: this.$t("edit"),
          disabled: true,
        });
      }
      return _breadcrumbsItems;
    },
    _backRouteText() {
      if (this.backRouteText) {
        return "返回";
      } else {
        return `${this.label}列表`;
      }
    },
    _backRoute() {
      if (this.backRoute) {
        return this.backRoute;
      } else {
        return `/${this.modelName}`;
      }
    },
    _versionModelName() {
      return `${this.modelName}_version`;
    },
    _urlModelName() {
      return this.urlModelName ? this.urlModelName : this.modelName;
    },
    _tabsDisplay() {
      return this.tabs?.length > 0 ? true : false;
    },
  },
  props: {
    patchUrl: {
      type: String,
    },
    getUrl: {
      type: String,
    },
    extendPostData: {
      type: Object,
    },
    hasBreadcrumbs: {
      type: Boolean,
      default: true,
    },
    postEncode: {
      type: Boolean,
      default: true,
    },
    emitInput: {
      type: Boolean,
      default: false,
    },
    emitSubmit: {
      type: Boolean,
      default: false,
    },
    titleKey: {
      type: String,
      default: "text",
    },
    backRouteText: {
      type: String,
      default: "返回",
    },
    backRoute: {
      type: String,
      default: null,
    },
    primary: {
      type: Array,
    },
    secondary: {
      type: Array,
    },
    modelName: String,
    label: String,
    fields: Object,
    id: {
      type: [String, Number],
    },
    value: {
      type: Object,
    },
    returnModelName: {
      type: String,
    },
    completeRedirect: {
      type: Boolean,
      default: true,
    },
    getCompleteRedirectUrl: {},
    urlModelName: String,
    submitToIndex: Boolean,
    breadcrumbsItems: Array,
    emitFormInput: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Array,
      default() {
        return [];
      },
    },
    extendParams: Object,
  },
  mounted() {
    this.$_fetchData();
  },
  created() {
    window.addEventListener("keydown", this.$_keydownHandler);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.$_keydownHandler);
  },
};
</script>