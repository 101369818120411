const model = {
  modelName: 'xc_leave_day',
  label: '請假統計匯出',
  fields: {
    xc_employee_department: {
      label: '部門',
    },
    xc_employee_no: {
      label: '員編',
    },
    xc_employee_name: {
      label: '人員姓名',
    },
    type: {
      label: '假別',
    },
    avaiable_start_at: {
      label: '可休起日',
    },
    available_end_at: {
      label: '可休迄日',
    },
    available: {
      label: '可用休假餘額',
    },
    used: {
      label: '已用休假餘額',
    },
    remain: {
      label: '剩餘休假餘額',
    },
  },
  crudSetting: {
    sheetprint: {
      exportFileName: '請假統計匯出',
      fetchUrl: 'xc_employee/overview/xc_leave_day',
      sheetFeilds: {
        xc_employee_department: {
          label: '部門',
          width: 100,
        },
        xc_employee_no: {
          label: '員編',
          width: 120,
        },
        xc_employee_name: {
          label: '人員姓名',
          width: 100,
        },
        type: {
          label: '假別',
          width: 100,
        },
        avaiable_start_at: {
          label: '可休起日',
          width: 100,
        },
        available_end_at: {
          label: '可休迄日',
          width: 100,
        },
        available: {
          label: '可用休假餘額',
          width: 100,
        },
        used: {
          label: '已用休假餘額',
          width: 100,
        },
        remain: {
          label: '剩餘休假餘額',
          width: 100,
        },
      },
      getValue: (modeldatas) => {
        if (!modeldatas) return
        let _items = []
        modeldatas.forEach(item => {
          for (const key in item?.overview) {
            _items.push({
              ...item,
              xc_employee_department: item.xc_employee.xc_employee_department?.name,
              xc_employee_no: item.xc_employee.no,
              xc_employee_name: item.xc_employee.name,
              type: item.overview[key].name,
              avaiable_start_at: item.overview[key].available_start_at,
              available_end_at: item.overview[key].available_end_at,
              available: `${item.overview[key].total_days_and_hours_days}天 ${item.overview[key].total_days_and_hours_hours}小時`,
              used: `${item.overview[key].used_days_and_hours_days}天 ${item.overview[key].used_days_and_hours_hours}小時`,
              remain: `${item.overview[key].remain_days_and_hours_days}天 ${item.overview[key].remain_days_and_hours_hours}小時`,
            })
          }
        })
        return _items
      },
    },
  },
}

export default model