const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: 'wsx_error_log',
  label: 'WSX Error Log',
  fields: {
    message: {
      type: 'text',
      label: 'Message',
    },
    exception: {
      type: 'text',
      label: 'Exception',
    },
    file: {
      type: 'text',
      label: 'File',
    },
    line: {
      type: 'text',
      label: 'Line',
    },
    trace: {
      type: 'text',
      label: 'Trace',
    },
    url: {
      type: 'link',
      label: 'URL',
    },
    method: {
      type: 'text',
      label: 'Method',
    },
    request_data: {
      type: 'text',
      label: 'Request Data',
    },
    user_id: {
      type: 'text',
      label: 'User ID',
    },
    code_resource: {
      type: 'text',
      label: 'Code Resource',
    },
    wsx_horcrux: {
      type: 'belongsTo',
      label: 'WSX Horcrux',
      textKey: 'name',
      modelName: 'wsx_horcrux',
    }
  },
  crudSetting: {
    index: {
      showFields: [
        'id',
        'created_at'
      ],
      creatable: false,
      editable: false,
      deletable: false,
      inRowBtnRead: true,
      pageMode: true,
    },
    read: {
      titleKey: 'name',
      cmsLog: true,
      leftFields: [
        "message",
        "exception",
        "file",
        "line",
        "trace",
        "url",
        "method",
        "request_data",
        "user_id",
        "code_resource",
      ],
    },
  }
}

export default model