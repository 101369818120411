

export default {
  getApprovedSalaryFromModel(model) {
    let _value = 0
    if (model.basic_salary) {
      _value += parseInt(model.basic_salary)
    }
    if (model.meal_expenses) {
      _value += parseInt(model.meal_expenses)
    }
    if (model.work_allowance) {
      _value += parseInt(model.work_allowance)
    }
    if (model.transportation_allowance) {
      _value += parseInt(model.transportation_allowance)
    }
    // if (model.supervisor_allowance) {
    //   _value += parseInt(model.supervisor_allowance)
    // }
    // if (model.fixed_allowance) {
    //   _value += parseInt(model.fixed_allowance)
    // }
    return _value
  },
  getApprovedSalaryWithoutTransportationFromModel(model) {
    let _value = 0
    if (model.basic_salary) {
      _value += parseInt(model.basic_salary)
    }
    if (model.meal_expenses) {
      _value += parseInt(model.meal_expenses)
    }
    if (model.work_allowance) {
      _value += parseInt(model.work_allowance)
    }
    // if (model.supervisor_allowance) {
    //   _value += parseInt(model.supervisor_allowance)
    // }
    // if (model.fixed_allowance) {
    //   _value += parseInt(model.fixed_allowance)
    // }
    return _value
  }
}