import H_Router from '@/__stone/helpers/router'

const routes = [
  ...H_Router.getCrudTemplateRoutes({
    routes: [
      'index',
      'read',
      'update',
      'create',
    ],
    modelName: 'xc_milestone_template',
    middleware: 'auth',
    meta: {
      requiredScopes: ['xc_milestone-admin'],
    },
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index', 'read', 'create', 'update'],
    modelName: 'xc_milestone_template_group',
    middleware: 'auth',
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: [
      'index',
      'update',
      'create',
    ],
    modelName: 'xc_milestone',
    middleware: 'auth',
    meta: {
      requiredScopes: ['xc_milestone-admin'],
    },
  }),
  ...H_Router.getResourceRoutes({
    routes: ['read'],
    modelName: 'xc_milestone',
    viewsPath: '__modules/xc_milestone/views',
    meta: {
      middleware: 'auth',
    },
  }),
  {
    path: '/xc_milestone/create/batch',
    name: 'xc_milestone_create_batch',
    component: () => import('@/__modules/xc_milestone/views/XcMilestone/Batch.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['xc_milestone-admin'],
    }
  },
]
export default routes