<template>
  <div>
    <WsTable
      :items="states"
      :headers="headers"
    >
      <template v-slot:td="{item,headersItem,itemIndex}">
        <WsText v-if="headersItem.value=='xc_employee.name'">
          {{item.xc_employee.name}}
        </WsText>
        <WsText v-if="headersItem.value=='xc_employee.no'">
          {{item.xc_employee.no}}
        </WsText>
        <WsText v-if="headersItem.value=='approved_salary'">
          {{$_getAprrovedSalary(item)}}
        </WsText>
        <div v-if="(headersItem.value=='basic_salary')">
          <WsState
            v-bind="$store.state.stone_model.xc_employee_salary_adjustment.fields.basic_salary"
            :label="undefined"
            :value="states[itemIndex].basic_salary"
            @input="$_onInput($event, itemIndex, 'basic_salary')"
          ></WsState>
        </div>
        <div v-if="headersItem.value=='meal_expenses'">
          <WsState
            v-bind="$store.state.stone_model.xc_employee_salary_adjustment.fields.meal_expenses"
            :label="undefined"
            :value="states[itemIndex].meal_expenses"
            @input="$_onInput($event, itemIndex, 'meal_expenses')"
          ></WsState>
        </div>
        <div v-if="headersItem.value=='work_allowance'">
          <WsState
            v-bind="$store.state.stone_model.xc_employee_salary_adjustment.fields.work_allowance"
            :label="undefined"
            :value="states[itemIndex].work_allowance"
            @input="$_onInput($event, itemIndex, 'work_allowance')"
          ></WsState>
        </div>
        <div v-if="headersItem.value=='transportation_allowance'">
          <WsState
            v-bind="$store.state.stone_model.xc_employee_salary_adjustment.fields.transportation_allowance"
            :label="undefined"
            :value="states[itemIndex].transportation_allowance"
            @input="$_onInput($event, itemIndex, 'transportation_allowance')"
          ></WsState>
        </div>
        <div v-if="headersItem.value=='supervisor_allowance'">
          <WsState
            v-bind="$store.state.stone_model.xc_employee_salary_adjustment.fields.supervisor_allowance"
            :label="undefined"
            :value="states[itemIndex].supervisor_allowance"
            @input="$_onInput($event, itemIndex, 'supervisor_allowance')"
          ></WsState>
        </div>
        <div v-if="headersItem.value=='fixed_allowance'">
          <WsState
            v-bind="$store.state.stone_model.xc_employee_salary_adjustment.fields.fixed_allowance"
            :label="undefined"
            :value="states[itemIndex].fixed_allowance"
            @input="$_onInput($event, itemIndex, 'fixed_allowance')"
          ></WsState>
        </div>
      </template>
    </WsTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timeouts: {},
      states: {},
      headers: [
        {
          text: "員工姓名",
          value: "xc_employee.name",
          width: 100,
        },
        {
          text: "員工編號",
          value: "xc_employee.no",
          width: 100,
        },
        {
          text: "核定薪資",
          value: "approved_salary",
          width: 100,
        },
        {
          text: "本俸",
          value: "basic_salary",
          width: 100,
        },
        {
          text: "伙食費",
          value: "meal_expenses",
          width: 100,
        },
        {
          text: "工作加給",
          value: "work_allowance",
          width: 100,
        },
        {
          text: "交通津貼",
          value: "transportation_allowance",
          width: 100,
        },
        {
          text: "主管加給",
          value: "supervisor_allowance",
          width: 100,
        },
        {
          text: "固定津貼",
          value: "fixed_allowance",
          width: 100,
        },
      ],
      actions: [
        {
          icon: "icon-md-check",
          emit: "confirm",
          displayCheck: (item) => {
            return !item.confirm_at;
          },
        },
      ],
    };
  },
  methods: {
    $_patch(id, data) {
      this.$axios.patch(`/xc_employee_salary_adjustment/${id}`, data);
    },
    $_onInput($event, itemIndex, key) {
      this.states[itemIndex][key] = $event;
      clearTimeout(this.timeouts[itemIndex]);
      this.timeouts[itemIndex] = setTimeout(() => {
        this.$_patch(this.states[itemIndex].id, {
          [key]: $event,
        });
      }, 300);
    },
    $_setState() {
      this.states = [...this.xc_employee_salary_adjustments];
    },
    $_getAprrovedSalary(item) {
      return this.$o_o.$h._m.xc_employee.getApprovedSalaryFromModel(item);
      // let _approved_salary = 0;
      // if (item.basic_salary) {
      //   _approved_salary += parseInt(item.basic_salary);
      // }
      // if (item.meal_expenses) {
      //   _approved_salary += parseInt(item.meal_expenses);
      // }
      // if (item.work_allowance) {
      //   _approved_salary += parseInt(item.work_allowance);
      // }
      // if (item.transportation_allowance) {
      //   _approved_salary += parseInt(item.transportation_allowance);
      // }
      // return _approved_salary;
    },
  },
  mounted() {
    this.$_setState();
  },
  props: {
    xc_employee_salary_adjustments: {},
  },
};
</script>

<style>
</style>