export default {
  $_returnMyBoardComponent(data) {
    const ctx = data.ctx
    const _boards = [
      {
        keepLocation: 'left',
        componentName: 'XcWorkOvertimeMyOverview',
        sq: 2
      },
      {
        keepLocation: 'right',
        componentName: 'MyXcWorkOvertimeBoard',
        sq: 7
      },
    ]
    if (ctx.$o_o.$h.auth.hasScope(['xc_process_event-admin'])) {
      _boards.push({
        keepLocation: 'right',
        componentName: 'XcWorkOvertimeApprovalBoard',
        sq: 4
      })
    }
    return _boards
  }
}
