<template>
  <WsModelIndex
    ref="WsModelIndex"
    label="工資管理"
    modelName="xc_employee"
    getUrl="/xc_employee/index/salary"
    :fields="_fields"
    :readable="true"
    :creatable="false"
    :updatable="true"
    :dialogRead="true"
    :dialogUpdate="true"
    :showFields="showFields"
    :getReadFetchUrl="$_getReadFetchUrl"
    :updateFields="_updateFields"
  ></WsModelIndex>
</template>

<script>
export default {
  data() {
    return {
      showFields: [
        "no",
        "name",
        "approved_salary",
        "approved_salary_without_transportation",
        "regular_salary",
      ],
    };
  },
  computed: {
    _updateFields() {
      return this.$o_o.$h.model.getFieldsByKeys(
        this.$store.state.stone_model.xc_employee.fields,
        [
          "basic_salary",
          "meal_expenses",
          "work_allowance",
          "transportation_allowance",
          "supervisor_allowance",
          "fixed_allowance",
          "regular_salary",
        ]
      );
    },
    _fields() {
      const _fields = {
        no: {
          ...this.$store.state.stone_model.xc_employee.fields.no,
          read_only: true,
        },
        name: {
          ...this.$store.state.stone_model.xc_employee.fields.name,
          read_only: true,
        },
        basic_salary:
          this.$store.state.stone_model.xc_employee.fields.basic_salary,
        meal_expenses:
          this.$store.state.stone_model.xc_employee.fields.meal_expenses,
        work_allowance:
          this.$store.state.stone_model.xc_employee.fields.work_allowance,
        transportation_allowance:
          this.$store.state.stone_model.xc_employee.fields
            .transportation_allowance,
        supervisor_allowance:
          this.$store.state.stone_model.xc_employee.fields.supervisor_allowance,
        fixed_allowance:
          this.$store.state.stone_model.xc_employee.fields.fixed_allowance,
        approved_salary:
          this.$store.state.stone_model.xc_employee.fields.approved_salary,
        approved_salary_without_transportation:
          this.$store.state.stone_model.xc_employee.fields
            .approved_salary_without_transportation,
        regular_salary:
          this.$store.state.stone_model.xc_employee.fields.regular_salary,
      };
      return _fields;
    },
  },
  methods: {
    $_getReadFetchUrl(item) {
      return `xc_employee/${item.id}/salary`;
    },
  },
};
</script>

<style>
</style>