<template>
  <WsFlex class="flex-full pa-16">
    <WsCmsCalendar
      ref="WsCmsCalendar"
      class="mr-12"
      height="100%"
      :droppable="false"
      :editable="false"
      :params="_params"
      :modelSettings="modelSettings"
    ></WsCmsCalendar>
    <WsFlex
      flexDirection="column"
      style="height: calc(100vh - 82px);width: 300px;"
    >
      <WsStateForm
        :fields="filter.fields"
        v-model="filter.state"
      ></WsStateForm>
      <XcTaskCardList
        class="height-full"
        ref="XcTaskCardList"
        :params="_taskParams"
        fetchUrl="/xc_task/index/my"
      ></XcTaskCardList>
    </WsFlex>
  </WsFlex>
</template>

<script>
export default {
  data() {
    return {
      filter: {
        state: {},
        fields: {
          xc_project: {
            type: "belongsTo",
            modelName: "xc_project",
            placeholder: "專案",
          },
        },
      },
      modelSettings: {
        xc_task: {
          fetchUrl: "xc_task/index/my/date",
          onChange: (xc_task, $event) => {
            if (!$event.event.allDay) {
              if (xc_task.hour) {
                this.$o_o.$s._m.xc_task.start_at(
                  xc_task.id,
                  $event.event.start
                );
              }
            } else if ($event.event.allDay) {
              this.$o_o.$s._m.xc_task.due_date(xc_task.id, $event.event.start);
            }
          },
          updateModeldataOnEventDrop: (xc_task, $event) => {
            if (!$event.event.allDay) {
              if (!xc_task.hour) {
                this.$store.dispatch(
                  "app/addSnack",
                  `排入時刻之任務需有預計執行時間`
                );
                return xc_task;
              } else {
                const _xc_task = {
                  ...xc_task,
                  start_at: $event.event.start,
                  due_date: this.$moment($event.event.start).format(
                    "YYYY-MM-DD"
                  ),
                };
                return _xc_task;
              }
            } else if ($event.event.allDay) {
              const _xc_task = {
                ...xc_task,
                due_date: this.$moment($event.event.start).format("YYYY-MM-DD"),
              };
              return _xc_task;
            }
          },
          readDialog: {
            showFields: [
              "name",
              "due_date",
              "start_at",
              "reviewed_at",
              "status",
              "hour",
              "finish_hour",
              "time_review_at",
              "xc_project",
              "creator",
              "taker",
              "content",
            ],
          },
          pageReadRedirect: true,
        },
        xc_meeting: {
          readDialog: {
            showFields: [
              "xc_project",
              "start_at",
              "end_at",
              "name",
              "remark",
              "members",
            ],
          },
        },
        xc_leave_day: {
          readDialog: {
            showFields: ["admin", "start_date", "days"],
          },
          index: {},
        },
      },
    };
  },
  computed: {
    _params() {
      return {
        taker: this._currentUser.id,
        members: this._currentUser.id,
        xc_project: this.filter.state.xc_project
          ? this.filter.state.xc_project.id
          : null,
      };
    },
    _taskParams() {
      return {
        status: "4",
        start_at: "null",
        due_date: "null",
        taker: this._currentUser.id,
      };
    },
    _currentUser() {
      return this.$store.state.auth.currentUser;
    },
  },
};
</script>

<style></style>