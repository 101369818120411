import H_Auth from '@/__stone/helpers/auth'

export default {
  hasScope($scope_arr, $admin_scope) {
    if (H_Auth.hasScope(['xc_project-admin'])) {
      return true
    }
    return $scope_arr.includes($admin_scope)
  },
  hasPermissions($scope_arr, $admin_scope) {
    return $scope_arr.includes($admin_scope)
  },
}