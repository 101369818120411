<template>
  <WsMain>
    <WsModelFetchShow
      ref="WsModelFetchShow"
      modelName="xc_employee_hire"
      :id="_id"
      @fetched="$_getModeldata"
      showLoading
    >
      <template v-slot="{ modeldata }">
        <WsInfoForm
          :fields="_infoFields"
          :value="modeldata"
        ></WsInfoForm>
        <WsWarnText
          v-if="modeldata && modeldata.xc_employee_position_type"
          :value="$_getWarnText(modeldata.xc_employee_position_type)"
        />
      </template>
    </WsModelFetchShow>
    <ValidationObserver
      v-if="!loading"
      ref="form"
      class="ws-content-section-observer"
    >
      <form @submit.prevent>
        <WsStateForm
          :fields="_stateFields"
          v-model="state"
        />
      </form>
    </ValidationObserver>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          class="ml-8"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      state: {},
      loading: true,
    };
  },
  methods: {
    $_getWarnText(xc_employee_position_type) {
      if (!xc_employee_position_type) {
        return;
      }
      if (
        this.state.work_allowance &&
        xc_employee_position_type.work_allowance &&
        this.state.work_allowance != xc_employee_position_type.work_allowance
      ) {
        return `${xc_employee_position_type.name}工作加給 ${this.$numeral(
          xc_employee_position_type.work_allowance
        ).format("$0,0")} 與設定不符`;
      } else {
        return;
      }
    },
    async $_onSubmit() {
      try {
        const isValidate = await this.$refs.form.validate();
        if (!isValidate) {
          return;
        }
        this.$store.dispatch("app/startPageLoading");
        await this.$axios.post(
          `/xc_employee_hire/${this._id}/checkin`,
          this.state
        );
        this.$router.push(`/xc_employee_hire`);
      } catch (error) {
        console.error(error);
        this.$store.dispatch("app/addSnack", "送出失敗，請稍後再試");
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_getModeldata($event) {
      this.state.no = $event.no
      this.loading = false
    },
  },
  computed: {
    _id() {
      return this.$route.params.id;
    },
    _infoFields() {
      let _fields = {...this.$store.state.stone_model.xc_employee_hire.fields}
      delete _fields.contact_address_city
      delete _fields.contact_address_district
      delete _fields.contact_address_zip
      delete _fields.household_address_city
      delete _fields.household_address_district
      delete _fields.household_address_zip
      return _fields
    },
    _stateFields() {
      let _stateFields = {
        basic_salary: {
          ...this.$store.state.stone_model.xc_employee.fields.basic_salary,
          rules: 'required',
        },
        meal_expenses: {
          ...this.$store.state.stone_model.xc_employee.fields.meal_expenses,
          rules: 'required',
        },
        work_allowance: {
          ...this.$store.state.stone_model.xc_employee.fields.work_allowance,
          rules: 'required',
        },
        transportation_allowance: {
          ...this.$store.state.stone_model.xc_employee.fields.transportation_allowance,
          rules: 'required',
        },
        supervisor_allowance: this.$store.state.stone_model.xc_employee.fields.supervisor_allowance,
        fixed_allowance: this.$store.state.stone_model.xc_employee.fields.fixed_allowance,
        employee_start_at: this.$store.state.stone_model.xc_employee.fields.employee_start_at,
        no: {
          type: 'text',
          label: '員工編號',
          readonly: true,
          col: 3,
        },
        //
      };
      return _stateFields
    },
  },
};
</script>

<style>
</style>