<template>
  <div>
    <WsMain>
      <ValidationObserver
        ref="form"
        class="ws-content-section-observer"
      >
        <form
          ref="updateForm"
          @submit.prevent
        >
          <WsStateForm
            v-model="state"
            :fields="fields"
          />
        </form>
      </ValidationObserver>
      <WsBtn
        class="mt-24"
        @click="$_onSubmit()"
      >{{ $t('submit') }}</WsBtn>
    </WsMain>
    <!-- <WsBottomNav>
      <template v-slot:rightActions>
      </template>
    </WsBottomNav> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: {},
      fields: {
        time_field: {
          type: "select",
          label: "時間篩選依據",
          defaultValue: "created_at",
          items: [
            { text: "依申請時間", value: "created_at" },
            { text: "依加班時間", value: "start_date" },
          ],
        },
        start: {
          type: "date",
          label: "篩選起始日期",
          rules: "required",
        },
        end: {
          type: "date",
          label: "篩選結束日期",
          rules: "required",
        },
        // xc_employee: {
        //   type: "belongsTo",
        //   label: "員工",
        //   modelName: "xc_employee",
        //   rules: "required",
        // },
        xc_employee_departments: {
          type: "belongsToMany",
          label: "部門",
          modelName: "xc_employee_department",
          rules: "required",
        },
      },
    };
  },
  methods: {
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      this.$router.push(this._to);
    },
  },
  computed: {
    _to() {
      let _to = "/print/xc_work_overtime?";
      _to += `time_field=${this.state.time_field}&`;
      _to += `start=${this.state.start}&end=${this.state.end}`;
      if (this.state.xc_employee_departments) {
        _to += `&xc_employee_departments=${this.state.xc_employee_departments
          .map((item) => item.id)
          .join(",")}`;
      }
      return _to;
    },
  },
};
</script>

<style>
</style>