
const model = {
  options: {
    timestamp: true,
  },
  modelName: 'tw_health_insurance_extra_dependent',
  urlModelName: 'xc_employee_insurance',
  label: '加保眷屬名單',
  fields: {
    name: {
      type: 'text',
      label: '眷屬姓名',
      rules: 'required',
      autofocus: true,
    },
    xc_employee_name: {
      type: 'custom',
      label: '依附眷屬(員工姓名)',
      getValue: function (data) {
        return data?.xc_employee?.name || '1l;elf-'
      },
    },
    xc_employee_name_readonly: {
      type: 'text',
      label: '依附眷屬(員工姓名)',
      info: true,
    },
    xc_employee: {
      type: 'text',
      label: '依附眷屬(員工姓名)',
      info: true,
    },
    sq: {
      type: 'text',
      label: '計費順位',
    },
    ref_amount: {
      type: 'currency',
      label: '投保金額',
      info: true,
    },
    amount: {
      type: 'currency',
      label: '健保自付額（僅本人）',
      info: true,
    },
    is_custom_amount: {
      type: 'switch',
      label: '是否自訂自付額',
      defaultValue: false,
    },
    custom_amount: {
      type: 'currency',
      label: '自訂自付額',
      rules: 'required',
    },
    note: {
      type: 'textarea',
      label: '備註'
    },
  },
  crudSetting: {
    create: {
      titleKey: 'id',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              getFields: (formValue) => {
                return model.getFieldsFromFormValue(formValue)
              },
            },
          ],
        },
      ],
    },
    update: {
      titleKey: 'id',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              getFields: (formValue) => {
                return model.getFieldsFromFormValue(formValue)
              },
            },
          ],
        },
      ],
    }
  },
  getFieldsFromFormValue(formValue) {
    let _fields = [
      'name',
      'xc_employee_name_readonly',
      'ref_amount',
      'amount',
      'is_custom_amount',
      'custom_amount',
      'note',
    ]
    if (formValue?.is_custom_amount == false) {
      _fields = _fields.filter(field =>
        field !== 'custom_amount'
      )
    }
    return _fields
  }
}

export default model