export default {
  $_returnMyBoardComponent(data) {
    const ctx = data.ctx
    const _boards = [
      {
        keepLocation: 'right',
        componentName: 'XcEmployeeHireWaitingNextActionBoard',
        sq: 2
      },
    ]
    if (ctx.$o_o.$h.auth.hasScope(['xc_process_event-admin'])) {
      _boards.push(...[
        {
          keepLocation: 'right',
          componentName: 'XcEmployeeHireNewApprovalBoard',
          sq: 1
        },
        {
          keepLocation: 'right',
          componentName: 'XcEmployeeHireRenewApprovalBoard',
          sq: 2
        },
      ])
    }
    return _boards
  },
}
