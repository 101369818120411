<template>
  <div
    id="app"
    class="app"
    v-cloak
  >
    <WsGlobalAppStore></WsGlobalAppStore>
    <LayoutBlank v-if="_template == 'blank'" />
    <LayoutPrint v-if="_template == 'print'" />
    <LayoutAuth v-else-if="_template == 'auth'" />
    <LayoutApp v-else-if="_template == 'app' && $store.state.auth.currentUser" />
  </div>
</template>

<script>
const appName = process.env.VUE_APP_APP_NAME;
export default {
  metaInfo() {
    return {
      title: appName,
      titleTemplate: `%s | ${appName}`,
    };
  },

  computed: {
    _template() {
      if (this.$route.meta && this.$route.meta.template) {
        return this.$route.meta.template;
      } else {
        return "app";
      }
    },
  },

  watch: {
    "$route.name": {
      handler() {
        if (this.$store.state.app.window.width < 767) {
          this.$store.dispatch("app/menuClose");
        }
      },
    },
    _template: {
      handler() {
        if (this._template == "print") {
          document.body.classList.remove(
            `theme-${this.$store.state.app.theme}`
          );
          document.body.classList.remove(`${this.$store.state.app.theme}`);
        } else {
          document.body.classList.add(`theme-${this.$store.state.app.theme}`);
          document.body.classList.add(`${this.$store.state.app.theme}`);
        }
      },
    },
    "$store.state.app.theme": {
      handler(newVal, oldVal) {
        document.body.classList.remove(`theme-${oldVal}`);
        document.body.classList.add(`theme-${newVal}`);
      },
    },
  },

  mounted() {
    document.body.classList.add(`theme-${this.$store.state.app.theme}`);
    document.body.classList.add(`${this.$store.state.app.theme}`);
  },
};
</script>

<style lang="scss">
@import "@/__stone/scss/app";
@import "static/icomoon/style.css";
// @import "@/scss/app";
</style>