<template>
  <div class="ws-file-state-images">
    <WsStateSortDialog
      v-if="sortable && value&&value.length"
      :value="value"
      @input="$emit('input',$event)"
    ></WsStateSortDialog>
    <div
      v-if="value && value.length"
      class="ws-flex-wrap"
    >
      <div
        v-for="(item, index) in value"
        :key="index"
        class="ws-file-state-image"
      >
        <img
          v-if="$_checkFileType(item, 'image')"
          v-lazy="item"
          class="mb-10 ws-img wire-bg"
          :style="`max-width:150px;max-height:150px`"
        />
        <WsVideo
          v-else-if="$_checkFileType(item, 'video')"
          :src="item"
        />
        <WsIconBtn
          @click="$_onClear(index)"
          class="remove-btn"
          backgroundColor="#000"
          name="icon-md-delete"
        />
        <WsText
          class="ws-file-state-image__name"
          v-tooltip.top="$o_o.$h.file.getFileNameByUrl(item)"
        >{{ $o_o.$h.file.getFileNameByUrl(item) }}</WsText>
      </div>
    </div>
    <FileSystemFilePickerImage
      :signed="signed"
      :storageTarget="storageTarget"
      ref="FileSystemFilePickerImage"
      @submit="$_onSubmit($event)"
      multiple
    />
    <FileSystemFilePickerVideo
      :signed="signed"
      :storageTarget="storageTarget"
      ref="FileSystemFilePickerVideo"
      @submit="$_onSubmit($event)"
      multiple
    />
    <WsFlex
      gap=10
      class="mt-10"
    >
      <WsBtn @click="$_onPickImageDialogOpen()">{{ $t('pick_image') }}</WsBtn>
      <WsBtn @click="$_onPickVideoDialogOpen()">選擇影片</WsBtn>
    </WsFlex>
  </div>
</template>

<script>
export default {
  methods: {
    $_onSubmit($event) {
      const _value = this.value ? [...this.value] : [];
      this.$emit("input", [..._value, ...$event]);
    },
    $_onClear(valueIndex) {
      const _value = [...this.value];
      _value.splice(valueIndex, 1);
      this.$emit("input", _value);
    },
    $_onPickImageDialogOpen() {
      this.$refs.FileSystemFilePickerImage.open();
    },
    $_onPickVideoDialogOpen() {
      this.$refs.FileSystemFilePickerVideo.open();
    },
    $_checkFileType(url, targetType) {
      if (this.$o_o.$h.file.getFileType(url) == targetType) {
        return true;
      } else {
        return false;
      }
    },
  },
  props: {
    value: {},
    signed: {
      type: Boolean,
      default: false,
    },
    previewMaxWidth: {
      type: String,
      default: "100%",
    },
    previewMaxHeight: {
      type: String,
      default: "100%",
    },
    storageTarget: {},
    sortable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
