import config from '@/__config'

const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: 'xc_business_event',
  label: '業務事件',
  fields: {
    source: {
      type: 'radio',
      label: '來源',
      items: [
        { value: 'line', label: 'LINE' },
        { value: 'officialsite', label: '官網' },
        { value: 'sales', label: '業務人員' },
      ]
    },
    event: {
      type: 'select',
      label: '事件',
      items: [
        { value: 'new_request', text: '新詢問' },
        { value: 'chat', text: '對話' },
      ]
    },
    content: {
      type: 'textarea',
      label: '內容',
    },
    note: {
      type: 'textarea',
      label: '備註',
    },
    admin: {
      type: 'belongsTo',
      label: "後台使用者",
      textKey: "name",
      modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
    },
  },
  crudSetting: {
    index: {
      showFields: [
        'created_at',
        'source',
        'event',
      ],
      inRowBtnRead: true,
      creatable: true,
      updatable: true,
      deletable: true,
    },
  }
}

export default model