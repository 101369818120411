const model = {
  fields: {
    xc_project: {
      type: 'belongsTo',
      label: '專案',
      textKey: "name",
      modelName: "xc_project",
    }
  },
  crudSetting: {
    index: {
      showFields: [
        'xc_project'
      ],
      filterSelects: {
        xc_project: {
          label: '專案',
          type: 'belongsTo',
          textKey: "name",
          modelName: "xc_project",
        },
      },
    }
  }
}
export default model