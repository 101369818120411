<template>
  <div>
    <WsActionApi
      class="width-full"
      :fetchUrl="_fetchUrl"
      v-slot="{ res, isFetching }"
    >
      <WsLoading v-if="isFetching"></WsLoading>
      <WsExcel
        v-else
        ref="WsExcel"
        :fields="fields"
        :value="$_getFormatedData(res.data)"
        :readonly="true"
        :exportFilename="_exportFilename"
      ></WsExcel>
    </WsActionApi>
    <WsFixedIconBtns :btns="btns"></WsFixedIconBtns>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btns: [
        {
          icon: "icon-md-print",
          text: "匯出",
          size: 60,
          onClick: () => {
            this.$_print();
          },
        },
      ],
      fields: {
        year_month: {
          label: "年/月",
        },
        xc_employee_no: {
          label: "員編",
        },
        xc_employee_department: {
          label: "部門",
        },
        xc_employee_name: {
          label: "姓名",
        },
        employee_start_at: {
          label: "到職日",
          width: "100px",
        },
        status: {
          label: "狀態",
        },
        approved_salary: {
          label: "核定月薪",
        },
        basic_salary: {
          label: "本俸",
        },
        meal_expenses: {
          label: "伙食費",
        },
        work_allowance: {
          label: "工作加給",
        },
        fixed_allowance: {
          label: "固定津貼",
        },
        transportation_allowance: {
          label: "交通加給",
        },
        supervisor_allowance: {
          label: "主管加給",
        },
        retirement_employee_contribution: {
          label: "退休金-勞工自願提撥",
          width: 150,
        },
        retirement_government_contribution: {
          label: "退休金-公提勞退專戶",
          width: 150,
        },
        severance_reserve_fund: {
          label: "退職準備金",
          width: 100,
        },
        tw_labor_insurance: {
          label: "代扣勞保費",
          width: 100,
        },
        tw_health_insurance: {
          label: "代扣健保費",
          width: 100,
        },
        personal_leave_deduction: {
          label: "事假扣款",
        },
        personal_leave_deduction_return: {
          label: "事假扣款補回",
          width: 120,
        },
        sick_leave_deduction: {
          label: "病假扣款",
        },
        sick_leave_deduction_return: {
          label: "病假扣款補回",
          width: 120,
        },
        annual_leave_convert: {
          label: "特休逾期未休折算",
          width: 130,
        },
        total_salary: {
          label: "實發金額",
        },
        remark: {
          label: "備註",
        },
      },
    };
  },
  methods: {
    $_print() {
      this.$refs.WsExcel.exportExcel();
    },
    $_getSlectValueText(value, items) {
      const item = items.find((item) => item.value === value);
      return item ? item.text : "";
    },
    $_getFormatedData(data) {
      return data.map((item) => {
        return {
          ...item,
          year_month: this.$moment(item.start_at).format("YYYY-MM"),
          xc_employee_no: item.xc_employee.no,
          xc_employee_name: item.xc_employee.name,
          xc_employee_department: item.xc_employee.xc_employee_department?.name,
          employee_start_at: this.$moment(
            item.xc_employee.employee_start_at
          ).format("YYYY-MM-DD"),
          status: this.$_getSlectValueText(
            item.xc_employee.status,
            this.$store.state.stone_model.xc_employee.fields.status.items
          ),
        };
      });
    },
  },
  computed: {
    _exportFilename() {
      return `薪資單-${this.$route.query.year}-${this.$route.query.month}`;
    },
    _fetchUrl() {
      return `/my/xc_employee_salary_slip/index/date?year=${this.$route.query.year}&month=${this.$route.query.month}`;
    },
  },
};
</script>

<style>
</style>