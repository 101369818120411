export default {
  $_returnMyBoardComponent(data) {
    const ctx = data.ctx
    const _boards = []
    if (ctx.$o_o.$h.auth.hasScope(['xc_process_event-admin'])) {
      _boards.push(...[
        {
          keepLocation: 'right',
          componentName: 'XcLeaveDayApprovalBoard',
          sq: 3
        },
      ])
    }
    return _boards
  }
}