const model = {
  options: {
    timestamp: true,
  },
  modelName: 'xc_employee',
  label: '健保投保查詢',
  fields: {
    no: {
      type: 'text',
      label: '員工編號',
    },
    xc_employee_department: {
      type: 'belongsTo',
      label: '部門別',
      modelName: 'xc_employee_department',
    },
    name: {
      type: 'text',
      label: '中文姓名',
    },
    tw_health_insurance_ref_amount: {
      type: 'currency',
      label: '健保投保金額',
    },
    tw_health_insurance_employee_self: {
      type: 'currency',
      label: '健保自付額（僅本人）',
    },
    tw_health_insurance_employee: {
      type: 'currency',
      label: '健保自付總額',
    },
    tw_health_insurance_employer: {
      type: 'currency',
      label: '健保公司負擔',
    },
    additional_health_insurance_people_counts: {
      type: 'number',
      label: '加保眷屬人數',
    },
    tw_health_insurance_employee_custom: {
      type: 'currency',
      label: '自訂健保自付額',
    },
    tw_health_insurance_remark: {
      type: 'textarea',
      label: '備註',
    },
  },
  crudSetting: {
    index: {
      showFields: [
        'no',
        'xc_employee_department',
        'name',
        'tw_health_insurance_ref_amount',
        'tw_health_insurance_employee_self',
        'tw_health_insurance_employee',
        'tw_health_insurance_employer',
        'additional_health_insurance_people_counts',
        'tw_health_insurance_employee_custom',
        'tw_health_insurance_remark',
      ],
      inRowBtnRead: true,
      pageMode: true,
      creatable: false,
      extendParams: {
        index_resource: 'Insurance'
      },
      extendOrderItems: [
        {
          value: 'tw_health_insurance_ref_amount_asc',
          text: '依投保級距低至高',
          order_by: 'tw_health_insurance_ref_amount',
          order_way: 'asc',
        },
        {
          value: 'tw_health_insurance_ref_amount_desc',
          text: '依投保級距高至低',
          order_by: 'tw_health_insurance_ref_amount',
          order_way: 'desc',
        },
      ],
      filterSelects: {
        tw_health_insurance_employee_custom: {
          label: '是否有自訂自付額',
          type: 'select',
          items: [
            {
              text: '不限（全部）',
              value: null,
            },
            {
              text: '有自訂',
              value: 'not_null',
            },
          ],
        },
      },
    },
    read: {
      titleKey: 'name',
      leftFields: [
        'no',
        'xc_employee_department',
        'name',
        'tw_health_insurance_ref_amount',
        'tw_health_insurance_employee_self',
        'tw_health_insurance_employee',
        'tw_health_insurance_employer',
        'additional_health_insurance_people_counts',
        'tw_health_insurance_employee_custom',
        'tw_health_insurance_remark',
      ],
      childCruds: [
        {
          modelName: 'tw_health_insurance_extra_dependent',
          pageMode: true,
          creatable: true,
          dialogCreate: false,
          inRowBtnRead: false,
          showFields: [
            'name',
            'xc_employee_name',
            'sq',
            'ref_amount',
            'amount',
            'custom_amount',
            'note',
          ],
          createUrl: (id) => {
            return `/tw_health_insurance_extra_dependent/create?xc_employee=${id}`
          },
          scopes: [
            'tw_health_insurance-admin'
          ],
        }
      ],
      extendParams: {
        show_resource: 'Insurance'
      },
    },
    update: {
      titleKey: 'name',
      extendParams: {
        show_resource: 'Insurance'
      },
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'tw_health_insurance_employee_custom',
                'tw_health_insurance_remark',
              ],
            },
          ],
        },
      ],
    }
  }
}

export default model