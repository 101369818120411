const scopes = [
  {
    text: '計畫 瀏覽者',
    value: 'xc_project-read',
  },
  {
    text: '計畫 編輯者',
    value: 'xc_project-edit',
  },
  {
    text: '計畫 管理者',
    value: 'xc_project-admin',
    tags: ['計畫']
  },
]

export default scopes