import H_Router from '@/__stone/helpers/router'

const routes = [
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    modelName: 'xc_business_event',
    middleware: 'auth',
    meta: {},
  }),
];
export default routes