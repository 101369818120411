import config from '@/__config';

export default {
  options: {
    id: true,
    timestamp: true,
    site_region: true,
  },
  modelName: config.wsmodule.admin.admin_blur ? 'cmser_role' : 'admin_role',
  label: '後台使用者角色',
  fields: {
    name: {
      type: 'text',
      label: '名稱',
      autofocus: true,
      rules: 'required',
      maxlength: 30,
    },
    is_default: {
      type: 'switch',
      label: '預設角色',
      activeText: '是',
      inactiveText: '否',
      readonly: true,
    },
    scopes: {
      type: 'scope',
      label: 'Scopes',
      searchable: true,
      selectAll: true,
    },
    updated_admin: {
      type: 'belongsTo',
      label: '最後更新人',
      textKey: 'name',
      readonly: true,
    },
  },
};
