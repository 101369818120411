<template>
  <WsFlex
    class="pa-20 flex-full"
    flexDirection="column"
  >
    <WsBreadcrumbs class="mb-20" :menu="_breadcrumbs" ></WsBreadcrumbs>
    <WsMenuView
      class="flex-full"
      :menu="_menu"
    >
      <router-view
        v-if="modeldata"
        :xc_project="modeldata"
        :key="$route.fullPath"
      ></router-view>
    </WsMenuView>
  </WsFlex>
</template>

<script>
export default {
  data() {
    return {
      modeldata: null,
    };
  },
  methods: {
    async $_fetchModeldata() {
      const res = await this.$axios.get(`/xc_project/${this._id}`);
      this.modeldata = res.data.data;
    },
  },
  mounted() {
    this.$_fetchModeldata();
  },
  computed: {
    _breadcrumbs() {
      const _breadcrumbs = [];
      _breadcrumbs.push({
        text: "計畫",
        to: "/xc_project",
      });
      if (this.modeldata) {
        _breadcrumbs.push({
          text: this.modeldata ? this.modeldata.name : "",
          to: `/xc_project/${this._id}`,
        });
      }
      _breadcrumbs.push({
        text: this.$t(`${this.$route.name}`),
        disabled: true,
      });
      return _breadcrumbs;
    },
    _adminScope() {
      if (!this.modeldata) {
        return;
      }
      return this.modeldata.admin_scope;
    },
    _menu() {
      let _menu = [
        {
          title: "資訊",
          link: `/xc_project/${this._id}/info`,
        },
        {
          title: "成員",
          link: `/xc_project/${this._id}/member`,
        },
        {
          title: "計畫人月",
          link: `/xc_project/${this._id}/member_month_day`,
        },
      ];
      _menu = this.$o_o.$h.module.extendModuleHelperAction(
        "xc_project",
        "xc_project.js",
        "getXcProjectMenu",
        {
          menu: _menu,
          id: this._id,
          ctx: this,
        },
        _menu
      );
      return _menu;
    },
    _id() {
      return this.$route.params.id;
    },
    _params() {
      return {
        xc_project: this._id,
      };
    },
  },
};
</script>

<style>
</style>