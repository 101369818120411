<template>
  <div>
    <WsTable
      :items="states"
      :headers="headers"
      :actionWidth="140"
      @confirm="$_confirm($event)"
      @confirm_cancel="$_confirmCancel($event)"
    >
      <template v-slot:td="{item,headersItem,itemIndex}">
        <WsText v-if="headersItem.value=='xc_employee.name'">
          {{item.xc_employee.name}}
        </WsText>
        <WsText v-if="headersItem.value=='xc_employee.no'">
          {{item.xc_employee.no}}
        </WsText>
        <div
          v-for="(showField, showFieldIndex) in showFields"
          :key="showFieldIndex"
        >
          <WsInfo
            v-if="headersItem.value==showField"
            v-bind="$store.state.stone_model.xc_employee_salary_slip.fields[showField]"
            :label="undefined"
            :value="states[itemIndex][showField]"
            :disabled="!!states[itemIndex][_checkfield]"
          ></WsInfo>
        </div>
      </template>
    </WsTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showFields: [
        "hire_change_type",
        "approved_salary",
        "basic_salary",
        "meal_expenses",
        "work_allowance",
        "transportation_allowance",
        "allowance",
        "supervisor_allowance",
        "fixed_allowance",
        "lunar_new_year_bonus",
        "performance_bonus",
        "welfare_fund",
        "retirement_employee_contribution",
        "retirement_government_contribution",
        "severance_reserve_fund",
        "remark",
        "total_salary",
        "tw_labor_insurance",
        "tw_health_insurance",
        "back_pay_for_adjustment",
        "income_tax",
        "salary_adjustment_on_retirement_payment",
      ],
      timeouts: {},
      states: {},
      headers: [
        {
          text: "近期異動",
          value: "hire_change_type",
          width: 100,
        },
        {
          text: "員工姓名",
          value: "xc_employee.name",
          width: 100,
        },
        {
          text: "員工編號",
          value: "xc_employee.no",
          width: 100,
        },
        {
          text: "核定薪資",
          value: "approved_salary",
          width: 100,
        },
        {
          text: "本俸",
          value: "basic_salary",
          width: 100,
        },
        {
          text: "伙食費",
          value: "meal_expenses",
          width: 100,
        },
        {
          text: "工作加給",
          value: "work_allowance",
          width: 100,
        },
        {
          text: "交通津貼",
          value: "transportation_allowance",
          width: 100,
        },
        {
          text: "津貼",
          value: "allowance",
          width: 100,
        },
        {
          text: "主管加給",
          value: "supervisor_allowance",
          width: 100,
        },
        {
          text: "固定津貼",
          value: "fixed_allowance",
          width: 100,
        },
        {
          text: "退休金-勞工自願提撥",
          value: "retirement_employee_contribution",
          width: 100,
        },
        {
          text: "退休金-公提勞退專戶",
          value: "retirement_government_contribution",
          width: 100,
        },
        {
          text: "退職準備金",
          value: "severance_reserve_fund",
          width: 100,
        },
        {
          text: "代扣勞保費",
          value: "tw_labor_insurance",
          width: 100,
        },
        {
          text: "代扣健保費",
          value: "tw_health_insurance",
          width: 100,
        },
        {
          text: "事假扣款",
          value: "personal_leave_deduction",
          width: 100,
        },
        {
          text: "事假扣款補回",
          value: "personal_leave_deduction_return",
          width: 100,
        },
        {
          text: "病假扣款",
          value: "sick_leave_deduction",
          width: 100,
        },
        {
          text: "病假扣款補回",
          value: "sick_leave_deduction_return",
          width: 100,
        },
        {
          text: "特休逾期未休折算",
          value: "annual_leave_convert",
          width: 100,
        },
        {
          text: "調薪差額補發",
          value: "back_pay_for_adjustment",
          width: 100,
        },
        {
          text: "所得稅代扣",
          value: "income_tax",
          width: 100,
        },
        {
          text: "調薪補提退職金",
          value: "salary_adjustment_on_retirement_payment",
          width: 100,
        },
        {
          text: "實發薪資金額",
          value: "total_salary",
          width: 100,
        },
        {
          text: "備註",
          value: "remark",
          width: 100,
        },
      ],
    };
  },
  methods: {
    checkSubmit() {
      let _check = true;
      this.states.forEach((item) => {
        if (this.review) {
          if (!item.review_confirm_at) {
            _check = false;
          }
        } else {
          if (!item.confirm_at) {
            _check = false;
          }
        }
      });
      return _check;
    },
    $_confirm($event) {
      if (this.review) {
        this.$axios.post(
          `/xc_employee_salary_slip/${$event.item.id}/review_confirm`
        );
        this.states[$event.itemIndex].review_confirm_at = new Date();
      } else {
        this.$axios.post(`/xc_employee_salary_slip/${$event.item.id}/confirm`);
        this.states[$event.itemIndex].confirm_at = new Date();
      }
    },
    $_confirmCancel($event) {
      if (this.review) {
        this.$axios.post(
          `/xc_employee_salary_slip/${$event.item.id}/review_confirm_cancel`
        );
        this.states[$event.itemIndex].review_confirm_at = null;
      } else {
        this.$axios.post(
          `/xc_employee_salary_slip/${$event.item.id}/confirm_cancel`
        );
        this.states[$event.itemIndex].confirm_at = null;
      }
    },
    $_patch(id, data) {
      this.$axios.patch(`/xc_employee_salary_slip/${id}`, data);
    },
    $_onInput($event, itemIndex, key) {
      this.states[itemIndex][key] = $event;
      clearTimeout(this.timeouts[itemIndex]);
      this.timeouts[itemIndex] = setTimeout(() => {
        this.$_patch(this.states[itemIndex].id, {
          [key]: $event,
        });
      }, 300);
    },
    $_setState() {
      this.states = [...this.xc_employee_salary_slips];
    },
  },
  mounted() {
    this.$_setState();
  },
  computed: {
    _actions() {
      if (this.review) {
        return [
          {
            icon: "icon-md-check",
            emit: "confirm",
            displayCheck: (item) => {
              return !item.review_confirm_at;
            },
          },
          {
            icon: "icon-md-close",
            emit: "confirm_cancel",
            displayCheck: (item) => {
              return !!item.review_confirm_at;
            },
          },
        ];
      } else {
        return [
          {
            icon: "icon-md-check",
            emit: "confirm",
            displayCheck: (item) => {
              return !item.confirm_at;
            },
          },
          {
            icon: "icon-md-close",
            emit: "confirm_cancel",
            displayCheck: (item) => {
              return !!item.confirm_at;
            },
          },
        ];
      }
    },
    _checkfield() {
      if (this.review) {
        return `review_confirm_at`;
      } else {
        return `confirm_at`;
      }
    },
  },
  props: {
    review: {
      type: Boolean,
      default: false,
    },
    xc_employee_salary_slips: {},
  },
};
</script>

<style>
</style>