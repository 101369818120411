import config from '@/__config'

const model = {
  options: {
    // id: true,
    timestamp: true,
  },
  modelName: "xc_work_overtime",
  label: "加班單",
  fields: {
    // id: {
    //   type: "text",
    //   label: 'ID',
    //   readonly: true
    // },
    start_at: {
      type: 'datetime',
      label: '加班開始時間',
      minuteStep: 30,
      format: 'YYYY-MM-DD HH:mm',
      editable: false,
      getDefaultValue: async (controller) => {
        const _res = await controller.$axios.get('/my/xc_employee')
        if (!_res) {
          return
        }
        const _my_employee = _res.data.data
        if (!_my_employee?.xc_employee_working_schedule_setting?.scheduled_working_hours) {
          return;
        }

        const _lastTimeRange = _my_employee?.xc_employee_working_schedule_setting?.scheduled_working_hours[_my_employee?.xc_employee_working_schedule_setting?.scheduled_working_hours.length - 1]
        const _lastTime = _lastTimeRange[_lastTimeRange.length - 1]

        const _targetTime = controller.$moment(_lastTime)
        const _datetime = controller.$moment().set({
          hour: _targetTime.hour(),
          minute: _targetTime.minute(),
          second: 0,
        })
        return _datetime.format('YYYY-MM-DD HH:mm')
      },
      col: 3,
    },
    end_at: {
      type: 'datetime',
      label: '加班結束時間',
      minuteStep: 30,
      format: 'YYYY-MM-DD HH:mm',
      editable: false,
      col: 3,
      getDefaultValue: async (controller) => {
        const _res = await controller.$axios.get('/my/xc_employee')
        if (!_res) {
          return
        }
        const _my_employee = _res.data.data
        if (!_my_employee?.xc_employee_working_schedule_setting?.scheduled_working_hours) {
          return;
        }

        const _lastTimeRange = _my_employee?.xc_employee_working_schedule_setting?.scheduled_working_hours[_my_employee?.xc_employee_working_schedule_setting?.scheduled_working_hours.length - 1]
        const _lastTime = _lastTimeRange[_lastTimeRange.length - 1]

        const _targetTime = controller.$moment(_lastTime).add(30, 'minutes')
        const _datetime = controller.$moment().set({
          hour: _targetTime.hour(),
          minute: _targetTime.minute(),
          second: 0,
        })
        return _datetime.format('YYYY-MM-DD HH:mm')
      },
      setPreDes(controller) {
        if (
          controller.stateData &&
          controller.stateData.start_at &&
          controller.stateData.end_at
        ) {
          const _diff = controller.$moment(controller.stateData.end_at).diff(controller.$moment(controller.stateData.start_at))
          const hoursDifference = _diff / (1000 * 60 * 60);
          if (hoursDifference) {
            controller.preDes = `加班時數：${hoursDifference}小時`
            return
          }
        }
        controller.preDes = ''
      },
    },
    hour: {
      type: "number",
      label: "加班時數",
      readonly: true,
    },
    remark: {
      type: 'textarea',
      label: '加班事由',
      rules: 'required',
    },
    status: {
      type: 'select',
      label: '狀態',
      readonly: true,
      defauleValue: 1,
      items: [
        {
          text: '簽核中',
          value: 'processing'
        },
        {
          text: '已核准',
          value: 'approved'
        },
        {
          text: '不同意',
          value: 'disapproved'
        },
        {
          text: '結算中',
          value: 'settling'
        },
        {
          text: '已支付',
          value: 'payed'
        },
        {
          text: '收回',
          value: 'withdrawn'
        },
        {
          text: '取消',
          value: 'canceled'
        },
        {
          text: '已補休完畢',
          value: 'compensatory_leaved'
        },
      ]
    },
    exchange_for_compensatory_leave: {
      type: 'radio',
      label: '是否換補休',
      defaultValue: 0,
      hint: '補休期限不能晚於特休年度之末日',
      items: [
        {
          label: '否',
          value: 0,
        },
        {
          label: '是',
          value: 1,
        },
      ],
      col: 3,
      setPreDes(controller) {
        const _preDesApiUrl = `/my/xc_leave_day/annual_leave_from_work_overtime/remain_days_and_hours_days`
        controller.$axios.get(_preDesApiUrl).then(res => {
          if (res.data.need_count_remain) {
            controller.preDes = `剩餘 ${res.data.remain_days_and_hours_days}天 ${res.data.remain_days_and_hours_hours}小時`
          } else {
            controller.preDes = ''
          }
        })
      },
    },
    xc_employee: {
      type: "belongsTo",
      label: "申請人",
      textKey: "name",
      modelName: 'xc_employee'
    },
    // admin: {
    //   type: "belongsTo",
    //   label: "CMS使用者",
    //   textKey: "name",
    // modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
    //   readonly: true,
    // },
    // status_updated_at: {
    //   type: "datetime",
    //   label: "狀態更新時間",
    //   readonly: true,
    // },
    work_overtime_type: {
      type: 'select',
      label: '加班類型',
      readonly: true,
      items: [
        {
          text: '跨日加班',
          value: 'cross'
        },
        {
          text: '平日加班',
          value: 'weekday'
        },
        {
          text: '休息日加班',
          value: 'restday'
        },
        {
          text: '國定假日加班',
          value: 'official_holiday'
        }
      ]
    },
    pay_amount: {
      type: 'number',
      label: '系統加班費小計',
      readonly: true,
    },
    adjusted_pay_amount: {
      type: 'number',
      label: '自訂加班費小計',
    },
  },
  crudSetting: {
    index: {
      showFields: [
        "xc_employee",
        "created_at",
        "start_at",
        "hour",
        "status",
        "work_overtime_type",
      ],
      pageMode: false,
      creatable: false,
      updatable: false,
      showFieldsUpdate: [
        "status",
      ],
      customTableActions: [
        {
          text: "取消",
          onClick: async (controller, modeldata, id, updateModeldata) => {
            controller.$store.dispatch("app/setAlert", {
              title: "確定取消？",
              description: "",
              action: async () => {
                try {
                  controller.$store.dispatch("app/startPageLoading");
                  await controller.$axios.post(`/xc_work_overtime/${id}/cancel`);
                  controller.$store.dispatch("app/addSnack", "取消成功");
                  updateModeldata({
                    ...modeldata,
                    status: 'canceled',
                  })
                } catch (error) {
                  console.log('error', error);
                  alert(controller.$t(controller.$o_o.$h.http.getErrorMessageFromRes(error)));
                } finally {
                  controller.$store.dispatch("app/stopPageLoading");
                }
              },
            });
          },
          displayCheck: (item) => {
            return item.status == "processing" || item.status == "approved" || item.status == "disapproved";
          }
        },
        {
          text: 'OK',
          onClick: async (controller, modeldata, id, updateModeldata) => {
            try {
              controller.$store.dispatch("app/startPageLoading");
              await controller.$axios.post(`/xc_work_overtime/${id}/approve`);
              updateModeldata({
                ...modeldata,
                status: "approved",
              });
            } catch (error) {
              console.error(error);
            } finally {
              controller.$store.dispatch("app/stopPageLoading");
            }
          },
          displayCheck: (item) => {
            if (config.wsmodule.xc_process) {
              return false
            }
            return item.status == "processing" || item.status == "disapproved";
          },
        },
        {
          text: 'No',
          onClick: async (controller, modeldata, id, updateModeldata) => {
            try {
              controller.$store.dispatch("app/startPageLoading");
              await controller.$axios.post(`/xc_work_overtime/${id}/disapprove`);
              updateModeldata({
                ...modeldata,
                status: "disapproved",
              });
            } catch (error) {
              console.error(error);
            } finally {
              controller.$store.dispatch("app/stopPageLoading");
            }
          },
          displayCheck: (item) => {
            if (config.wsmodule.xc_process) {
              return false
            }
            return item.status == "processing" || item.status == "approved";
          },
        },
      ],
      filterTabs: [
        {
          text: "簽核中",
          value: "processing",
          params: {
            status: "processing",
          },
        },
        {
          text: "已核准",
          value: "approved",
          params: {
            status: "approved",
          },
        },
        {
          text: "不同意",
          value: "disapproved",
          params: {
            status: "disapproved",
          },
        },
        {
          text: "收回",
          value: "withdrawn",
          params: {
            status: "withdrawn",
          },
        },
        {
          text: "取消",
          value: "canceled",
          params: {
            status: "canceled",
          },
        },
        {
          text: "結算中",
          value: "settling",
          params: {
            status: "settling",
          },
        },
        {
          text: "已支付",
          value: "payed",
          params: {
            status: "payed",
          },
        },
        {
          text: "已補休完畢",
          value: "compensatory_leaved",
          params: {
            status: "compensatory_leaved",
          },
        },
      ],
    },
    index_my: {
      showFields: [
        // "created_at",
        "start_at",
        "end_at",
        "hour",
        "status",
        "work_overtime_type",
      ],
      pageMode: false,
      updatable: false,
      dialogRead: true,
    },
    create_my: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'start_at',
                'end_at',
                // 'hour',
                'exchange_for_compensatory_leave',
                'remark',
              ],
            },
          ],
        },
      ],
      beforeSubmitAction: async (controller, postData) => {
        if (controller.$moment(postData.start_at).isBefore(postData.end_at)) {
          return true
        } else {
          controller.$store.dispatch('app/setAlert', {
            title: '送出失敗，結束時間不可以早於開始時間，請修改後再次送出',
          })
          return false
        }
      },
    },
    read: {

    }
  }
}

export default model