const model = {
  fields: {
    wsx_token: {
      type: 'text',
      label: 'WSX Token',
    },
    prd_cms_api_url: {
      type: 'text',
      label: 'Production CMS API URL',
    },
    prd_client_api_url: {
      type: 'text',
      label: 'Production Client API URL',
    },
    prd_cms_url: {
      type: 'text',
      label: 'Production CMS URL',
    },
    prd_web_url: {
      type: 'text',
      label: 'Production Web URL'
    },
    uat_cms_api_url: {
      type: 'text',
      label: 'UAT CMS API URL',
    },
    uat_client_api_url: {
      type: 'text',
      label: 'UAT Client API URL',
    },
    uat_cms_url: {
      type: 'text',
      label: 'UAT CMS URL',
    },
    uat_web_url: {
      type: 'text',
      label: 'UAT Web URL'
    },
  },
  crudSetting: {
    read: {
      leftFields: [
        "wsx_token",
        "prd_cms_api_url",
        "prd_client_api_url",
        "prd_cms_url",
        "prd_web_url",
        "uat_cms_api_url",
        "uat_client_api_url",
        "uat_cms_url",
        "uat_web_url",
      ],
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "wsx_token",
                "prd_cms_api_url",
                "prd_client_api_url",
                "prd_cms_url",
                "prd_web_url",
                "uat_cms_api_url",
                "uat_client_api_url",
                "uat_cms_url",
                "uat_web_url",
              ],
            },
          ],
        },
      ],
    },
    update: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "wsx_token",
                "prd_cms_api_url",
                "prd_client_api_url",
                "prd_cms_url",
                "prd_web_url",
                "uat_cms_api_url",
                "uat_client_api_url",
                "uat_cms_url",
                "uat_web_url",
              ],
            },
          ],
        },
      ],
    },
    index: {
      showFields: [
      ]
    }
  }
}
export default model