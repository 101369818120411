export default {
  admin: {
    icon: 'icon-md-groups',
    title:  '後台使用者管理',
    titleInLocale: true,
    menu: [
      {
        title:  '後台使用者紀錄',
        link: '/cms_log',
        scopes: ['cms_log-admin'],
      },
    ],
  },
};
