<template>
  <div>
    <WsCreate
      v-if="isMounted"
      :modelName="model.modelName"
      :label="model.label"
      :fields="model.fields"
      :primary="model.crudSetting.create.primary"
      :value="value"
      emitInput
      @input="$_input"
      :returnUrl="_returnUrl"
      :breadcrumbsItems="_breadcrumbsItems"
    />
    <WsLoading v-else />
  </div>
</template>

<script>
export default {
  data() {
    return {
      model: this.$store.state.stone_model.tw_health_insurance_extra_dependent,
      xcEmployeeData: {},
      value: {},
      isMounted: false,
    }
  },
  computed: {
    _breadcrumbsItems() {
      return [
        {
          text: '健保投保查詢',
          to: '/xc_employee_insurance',
          disabled: false,
        },
        {
          text: this.value.xc_employee_name_readonly,
          to: `/xc_employee_insurance/${this.value.xc_employee}`,
          disabled: false,
        },
        {
          text: `${this.$t('new')} ${this.model.label}`,
          disabled: true,
        },
      ]
    },
    _returnUrl() {
      return `/xc_employee_insurance/${this._xcEmployeeId}`
    },
    _xcEmployeeId() {
      return this.$route.query.xc_employee
    },
  },
  methods: {
    async $_getXcEmployeeData() {
      try {
        const res = await this.$axios.get(`/xc_employee/${this._xcEmployeeId}`, {
          params: {
            show_resource: 'Insurance',
          }
        })
        this.xcEmployeeData = res.data.data
        this.$_setData()
      } catch (err) {
        alert(err)
      }
    },
    $_setData() {
      this.value = {
        xc_employee: this.xcEmployeeData.id,
        xc_employee_name_readonly: this.xcEmployeeData.name,
        ref_amount: this.xcEmployeeData.tw_health_insurance_ref_amount,
        amount: this.xcEmployeeData.tw_health_insurance_employee_self
      }
      this.isMounted = true
    },
    $_input ($event) {
      this.value = $event
    },
  },
  mounted() {
    this.$_getXcEmployeeData()
  },

}
</script>