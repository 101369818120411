<template>
  <div class="ws-model-index__paginate">
    <slot name="top"></slot>
    <WsFlex
      class="ws-model-index__paginate__container pa-12 sm-pa-6"
      justifyContent="space-between"
      alignItems="center"
    >
      <WsFlex>
        <p v-if="!total">No Result</p>
        <p v-else>{{from}}-{{to}} of {{total}}</p>
      </WsFlex>
      <WsFlex
        class="flex-0"
        gap="4px"
      >
        <WsIconBtn
          @click="$_pageTo(1)"
          :size="42"
          name="icon-md-first-page"
        />
        <WsIconBtn
          @click="$_pagePrev()"
          :size="42"
          name="icon-md-chevron-left"
        />
        <WsFlex
          class="flex-0 px-16 d-xs-none"
          alignItems="center"
        >
          <p class="text-keep">第</p>
          <WsStateInput
            type="number"
            class="flex-0 w-2 mx-8"
            @keyup.enter="$_pageTo($event.target.value)"
            :value="value"
            small
            center
          ></WsStateInput>
          <p class="text-keep">頁 - 共 {{last_page}} 頁</p>
        </WsFlex>
        <WsIconBtn
          @click="$_pageNext()"
          :size="42"
          name="icon-md-chevron-right"
        />
        <WsIconBtn
          @click="$_pageTo(last_page)"
          :size="42"
          name="icon-md-last-page"
        />
        <slot name="customBtns"></slot>
      </WsFlex>
    </WsFlex>
  </div>
</template>

<script>
export default {
  methods: {
    $_pagePrev() {
      if (this.value <= 1) {
        return;
      } else {
        this.$emit("input", Number(this.value) - 1);
      }
    },
    $_pageNext() {
      if (!this.value) {
        this.$emit("input", 2);
      } else if (this.value >= this.last_page) {
        return;
      } else {
        this.$emit("input", Number(this.value) + 1);
      }
    },
    $_pageTo($event) {
      let _value = $event;
      if (_value > this.last_page) {
        _value = this.last_page;
      }
      if (_value < 1) {
        _value = 1;
      }
      this.$emit("input", _value);
    },
  },
  props: {
    value: {},
    from: {},
    to: {},
    total: {},
    last_page: {
      default: 1,
    },
  },
};
</script>

<style lang="scss">
.ws-model-index__paginate {
  position: sticky;
  bottom: 0;
  z-index: 100;
  background: var(--white);
}
</style>