<template>
  <div>
    <WsActionApi
      class="width-full"
      :fetchUrl="_fetchUrl"
      @fetch="$_onFetch($event)"
    >
      <!-- {{ res }} -->
      <WsExcel
        ref="WsExcel"
        :fields="_fields"
        :value="_value"
        :readonly="true"
        :exportFilename="_exportFilename"
      ></WsExcel>
    </WsActionApi>
    <WsFixedIconBtns :btns="btns"></WsFixedIconBtns>
  </div>
</template>

<script>
export default {
  data() {
    return {
      xc_work_overtimes: [],
      btns: [
        {
          icon: "icon-md-print",
          text: "匯出",
          size: 60,
          onClick: () => {
            this.$_print();
          },
        },
      ],
    };
  },
  methods: {
    $_onFetch($event) {
      this.xc_work_overtimes = $event.data;
    },
    $_print() {
      this.$refs.WsExcel.exportExcel();
    },
    $_getSlectValueText(value, items) {
      const item = items.find((item) => item.value === value);
      return item ? item.text : "";
    },
    $_getFormatedData(data) {
      return data.map((item) => {
        return {
          xc_employee_department: item.xc_employee.xc_employee_department?.name,
          xc_employee_name: item.xc_employee.name,
          xc_employee_type: item.xc_employee.xc_employee_type?.name,
          created_at: this.$moment(item.created_at).format("YYYY-MM-DD"),
        };
      });
    },
  },
  computed: {
    _exportFilename() {
      const _start_f = this.$moment(this._start).format("YYYYMMDD");
      const _end_f = this.$moment(this._end).format("YYYYMMDD");
      return `加班單-${this._name}-${_start_f}-${_end_f}`;
    },
    _start() {
      return this.$route.query.start;
    },
    _end() {
      return this.$route.query.end;
    },
    _name() {
      return this.$route.query.name;
    },
    _value() {
      const _value = [];
      for (const xc_work_overtime of this.xc_work_overtimes) {
        let work_overtime_type = "";
        if (xc_work_overtime.work_overtime_type == "weekday") {
          work_overtime_type = "平日加班";
        } else if (xc_work_overtime.work_overtime_type == "restday") {
          work_overtime_type = "休息日加班";
        } else if (xc_work_overtime.work_overtime_type == "official_holiday") {
          work_overtime_type = "國定假日加班";
        }
        _value.push({
          xc_employee_no: xc_work_overtime.xc_employee.no,
          xc_employee_department:
            xc_work_overtime.xc_employee.xc_employee_department?.name,
          xc_employee_name: xc_work_overtime.xc_employee.name,
          created_at: this.$moment(xc_work_overtime.created_at).format(
            "YYYY-MM-DD"
          ),
          start_at: this.$moment(xc_work_overtime.start_at).format(
            "YYYY-MM-DD HH:mm"
          ),
          end_at: this.$moment(xc_work_overtime.end_at).format(
            "YYYY-MM-DD HH:mm"
          ),
          hour: xc_work_overtime.hour,
          remark: xc_work_overtime.remark,
          exchange_for_compensatory_leave:
            xc_work_overtime.exchange_for_compensatory_leave ? "是" : "否",
          work_overtime_type: work_overtime_type,
          status: this.$_getSlectValueText(
            xc_work_overtime.status,
            this.$store.state.stone_model.xc_work_overtime.fields.status.items
          ),
          xc_project: xc_work_overtime.xc_project?.name,
        });
      }
      return _value;
    },
    _fields() {
      return {
        xc_employee_no: {
          label: "員編",
        },
        xc_employee_department: {
          label: "部門",
          type: "string",
        },
        // create_xc_employee_name: {
        //   label: "填表人",
        // },
        xc_employee_name: {
          label: "申請人",
        },
        created_at: {
          label: "申請日期",
          type: "string",
        },
        start_at: {
          label: "加班開始時間",
          type: "string",
          width: 150,
        },
        end_at: {
          label: "加班結束時間",
          type: "string",
          width: 150,
        },
        hour: {
          label: "加班時數",
          type: "string",
        },
        remark: {
          label: "加班事由",
          type: "string",
        },
        exchange_for_compensatory_leave: {
          label: "是否換補休",
          type: "string",
        },
        work_overtime_type: {
          label: "加班類型",
          type: "string",
        },
        status: {
          label: "表單狀態",
          type: "string",
        },
        xc_project: {
          label: "計畫",
          type: "string",
        },
      };
    },
    _xc_work_overtime_settlement() {
      return this.$route.query.xc_work_overtime_settlement;
    },
    _fetchUrl() {
      let _paramString = "";
      for (const key in this.$route.query) {
        if (_paramString === "") {
          _paramString += `?${key}=${this.$route.query[key]}`;
        } else {
          _paramString += `&${key}=${this.$route.query[key]}`;
        }
      }
      if (this.$route.query.start && this.$route.query.end) {
        return `/xc_work_overtime/index/date${_paramString}`;
      } else {
        return `/xc_work_overtime${_paramString}`;
      }
    },
  },
};
</script>

<style>
</style>