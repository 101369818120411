<template>
  <WsMain>
    <WsCrud
      label=""
      :modelName="$store.state.stone_model.xc_project.modelName"
      :fields="$store.state.stone_model.xc_project.fields"
      :showFields="$store.state.stone_model.xc_project.crudSetting.index.showFields"
      :filterTabs="_filterTabs"
      :creatable="_creatable"
      :updatable="false"
      :deletable="false"
      :inRowBtnRead="true"
      :rowClickRead="false"
      :batchcreatable="true"
      :pageMode="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      filterTabs: [
        {
          text: "進行中",
          value: "ing",
          params: {
            status: "ing",
          },
        },
        {
          text: "結案",
          value: "complete",
          params: {
            status: "complete",
          },
        },
        {
          text: "全部",
          value: "all",
          params: {},
        },
      ],
    };
  },
  methods: {},
  computed: {
    _filterTabs() {
      const _filterTabs = [];
      _filterTabs.push({
        text: "全部",
        value: "all",
        params: {},
      });
      const _items =
        this.$store.state.stone_model.xc_project.fields.status.items;
      for (const item of _items) {
        _filterTabs.push({
          text: item.label,
          value: item.value,
          params: {
            status: item.value,
          },
        });
      }
      return _filterTabs;
    },
    _creatable() {
      return this.$o_o.$h.auth.hasScope([
        "xc_project-edit",
        "xc_project-admin",
      ]);
    },
  },
};
</script>

<style>
</style>