<template>
  <div>
    <WsStateForm
      v-model="state"
      :fields="fields"
    ></WsStateForm>
    <WsActionApi
      :fetchUrl="_fetchUrl"
      :params="_params"
      @fetch="$_onFetch($event)"
      :alertError="false"
    >
    </WsActionApi>
    <WsInfoJson :value="modules"></WsInfoJson>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modules: null,
      state: {
        resource: "prd_cms_api",
      },
      fields: {
        resource: {
          type: "select",
          label: "來源",
          items: [
            { value: "prd_cms_api", text: "PRD CMS API" },
            { value: "prd_client_api", text: "PRD Client API" },
            { value: "prd_cms", text: "PRD CMS" },
            { value: "prd_web", text: "PRD Web" },
            { value: "uat_cms_api", text: "UAT CMS API" },
            { value: "uat_client_api", text: "UAT Client API" },
            { value: "uat_cms", text: "UAT CMS" },
            { value: "uat_web", text: "UAT Web" },
          ],
        },
      },
    };
  },
  methods: {
    $_onFetch($event) {
      console.log("$_onFetch", $event);
      this.modules = $event;
    },
  },
  computed: {
    _fetchUrl() {
      return `/xc_project/${this.xc_project.id}/wsx`;
    },
    _params() {
      return {
        resource: this.state.resource,
      };
    },
  },
  props: {
    xc_project: {},
  },
};
</script>

<style>
</style>