<template>
  <SlickItem :index="index">
    <WsCard>
      <WsFlex
        alignItems="center"
        justifyContent="space-between"
      >
        <WsFlex alignItems="center">
          <WsIcon
            class="cursor-move"
            v-handle
            size="24"
            name="icon-ws-outline-drag-horizontal"
          ></WsIcon>
          <WsText class="px-8">{{classItem.name}}</WsText>
          <div
            v-if="ableMoveUp"
            class="ml-8 transform-rotate"
          >
            <WsIconBtn
              tooltip="挪至上一層"
              name="icon-md-subdirectory-arrow-right"
              @click="$emit('moveup')"
            ></WsIconBtn>
          </div>
          <WsIconBtn
            v-if="_ableMoveDown"
            class="ml-8"
            tooltip="挪至下一層"
            name="icon-md-subdirectory-arrow-right"
            @click="$emit('movedown')"
          ></WsIconBtn>
        </WsFlex>
        <WsFlex alignItems="center">
          <div v-if="hasIsActive">
            <WsStateSwitch
              class="mr-8"
              :value="isActive"
              @input="$_onUpdateIsActive($event)"
            ></WsStateSwitch>
          </div>
          <div
            v-for="(customTableAction, customTableActionIndex) in customTableActions"
            :key="customTableActionIndex"
            class="mr-8"
          >
            <WsIconBtn
              @click.stop="$emit('custom-table-action', { emit: customTableAction.emit, data: { classItem } })"
              :name="$_getCustomActionIcon(customTableAction, classItem)"
              :to="$_getCustomActionUrl(customTableAction, classItem)"
              :tooltip="$_getCustomActionTooltip(customTableAction, classItem)"
              :disabled="$_getCustomActionDisabled(customTableAction, classItem)"
            />
          </div>
          <WsIconBtn
            class="mr-8"
            tooltip="Edit"
            @click.stop="$emit('edit')"
            name="icon-md-edit"
          />
          <WsIconBtn
            tooltip="Delete"
            @click.stop="$_onDelete()"
            name="icon-md-delete"
          />
        </WsFlex>
      </WsFlex>
    </WsCard>
    <WsCmsCrudClassifyItemGroup
      v-if="_childClassItems&&_childHasLayer"
      class="mt-8 pl-40"
      :hasIsActive="hasIsActive"
      :modelName="modelName"
      :label="label"
      :classItems="_childClassItems"
      :parentClassItem="classItem"
      :updateFields="updateFields"
      :childLayerCount="childLayerCount"
      :extendPostData="extendPostData"
      :customTableActions="customTableActions"
      @update:classItems="$_onUpdateClassItems($event)"
      @moveupclassitem="$_onMoveUpClasssItem($event)"
      @custom-table-action="$emit('custom-table-action', $event)"
    ></WsCmsCrudClassifyItemGroup>
  </SlickItem>
</template>

<script>
export default {
  methods: {
    $_onMoveUpClasssItem($event) {
      this.$emit("moveupclassitem", $event);
    },
    $_onUpdateClassItems($event) {
      this.$emit("update", {
        [this._childKeyName]: $event,
      });
    },
    $_onUpdateIsActive($event) {
      this.$emit("update", {
        is_active: $event,
      });
    },
    $_getCustomActionUrl(customTableAction, item) {
      if (customTableAction.getUrl) {
        return customTableAction.getUrl(item);
      } else {
        return null;
      }
    },
    $_getCustomActionTooltip(customTableAction, item) {
      if (customTableAction.getTooltip) {
        return customTableAction.getTooltip(item);
      } else {
        return customTableAction.tooltip;
      }
    },
    $_getCustomActionDisabled(customTableAction, item) {
      if (customTableAction.getDisabled) {
        return customTableAction.getDisabled(item);
      } else {
        return customTableAction.disabled;
      }
    },
    $_getCustomActionIcon(customTableAction, item) {
      if (customTableAction.getIcon) {
        return customTableAction.getIcon(item);
      } else {
        return customTableAction.icon;
      }
    },
     $_onDelete() {
      this.$store.dispatch("app/setAlert", {
        title: "您確定要刪除此分類嗎？此操作無法復原。",
        description: "",
        action: this.$_onSubmitDelete,
      });
    },
     $_onSubmitDelete() {
      try {
        this.$store.dispatch("app/startPageLoading");
        this.$emit("delete");
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _ableMoveDown() {
      if (this.ableMoveDown && this._childHasLayer) {
        return true;
      } else {
        return false;
      }
    },
    _childHasLayer() {
      if (this.childLayerCount != undefined) {
        return this.childLayerCount > 0;
      } else {
        return true;
      }
    },
    _childLayerCount() {
      if (this.childLayerCount != undefined) {
        return this.childLayerCount;
      } else {
        return this.childLayerCount;
      }
    },
    _childKeyName() {
      return this.childKeyName || `${this.modelName}s`;
    },
    _childClassItems() {
      return this.classItem[this._childKeyName];
    },
  },
  props: {
    index: {},
    hasIsActive: {},
    isActive: {},
    ableMoveUp: {},
    ableMoveDown: {},
    modelName: {},
    classItem: {},
    label: {},
    updateFields: {},
    childLayerCount: {},
    childKeyName: String,
    extendPostData: {},
    customTableActions: Array,
  },
};
</script>
