export default {
  my_calc: {
    icon: 'icon-md-trending-up',
    title: '我的 分析',
    link: '/mycalc',
  },
  my_calendar: {
    icon: 'icon-fa-calendar-custom',
    title: '我的 行事曆',
    link: '/mycalendar',
  },
  my_board: {
    icon: 'icon-md-dashboard',
    title: '我的 看板',
    link: '/my_board',
  },
}