<template>
  <WsUpdate
    :modelName="model.modelName"
    :label="model.label"
    :fields="model.fields"
    :primary="model.crudSetting.create.primary"
    :id="_id"
    :value="value"
    emitInput
    emitComplete
    @modelDataInit="$_modelDataInit"
    @input="$_input"
    @complete="$_complete"
    titleKey="id"
    :breadcrumbsItems="_breadcrumbsItems"
  />
</template>

<script>
export default {
  data() {
    return {
      model: this.$store.state.stone_model.tw_health_insurance_extra_dependent,
      xcEmployeeData: {},
      modelData: {},
      value: {},
    }
  },
  computed: {
    _id() {
      return this.$route.params.id
    },
    _xcEmployeeId() {
      return this.$route.query.xc_employee
    },
    _breadcrumbsItems() {
      return [
        {
          text: '健保投保查詢',
          to: '/xc_employee_insurance',
          disabled: false,
        },
        {
          text: this.value.xc_employee_name_readonly,
          to: `/xc_employee_insurance/${this.modelData.xc_employee?.id}`,
          disabled: false,
        },
        {
          text: `${this.$t('edit')} ${this.modelData.id}`,
          disabled: true,
        },
      ]
    }
  },
  methods: {
    $_complete() {
      this.$router.go(-1)
    },
    $_modelDataInit(modelData) {
      this.modelData = modelData
      let _is_custom_amount = modelData.custom_amount !== null
      this.value = {
        xc_employee: this.modelData.xc_employee.id,
        xc_employee_name_readonly: this.modelData.xc_employee.name,
        is_custom_amount: _is_custom_amount,
        ...this.value,
      }
    },
    $_input ($event) {
      this.value = $event
      if (this.value.is_custom_amount == false) {
        this.value.custom_amount = null
      }
    },
  },
}
</script>