import H_Router from '@/__stone/helpers/router'

const routes = [
  ...H_Router.getCrudTemplateRoutes({
    routes: [
      'index',
      'read',
      'create',
      'update',
    ],
    modelName: 'wsx_horcrux',
    middleware: 'auth',
    meta: {},
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: [
      'index',
      'read',
    ],
    modelName: 'wsx_error_log',
    middleware: 'auth',
    meta: {},
  }),
];

console.log('11');
export default routes